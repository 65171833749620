import { Router } from '@angular/router';
import { ConfigurationService } from './../../../core/services/configuration.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../../core/services/remote-control.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cm-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.scss'],
})
export class ThemesComponent implements OnInit {
  choices: any[];
  currentChoice: any;
  translateStrings: any;

  constructor(
    private configuration: ConfigurationService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const tokens = [
      'PROFILE_THEMES_LIGHT',
      'PROFILE_THEMES_DARK',
      'PROFILE_THEMES_HIGH_CONTRAST',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));
    this.choices = [
      {
        id: 1,
        tag: 'light',
        label: this.translateStrings.PROFILE_THEMES_LIGHT,
      },
      { id: 2, tag: 'dark', label: this.translateStrings.PROFILE_THEMES_DARK },
      {
        id: 3,
        tag: 'high',
        label: this.translateStrings.PROFILE_THEMES_HIGH_CONTRAST,
      },
    ];

    this.currentChoice = this.choices.find(
      (choice) => choice.tag == this.configuration.settings.theme
    );
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToProfile();
    }
    return false;
  }

  onChoiceSelected(choice) {
    this.configuration.settings.theme = choice.tag;
    this.configuration.save();
    this.currentChoice = choice;
  }

  goToProfile() {
    this.router.navigate(['dashboard/profile']);
  }
}
