import {
  RemoteControlService,
  REMOTE_KEY_SETS,
} from './../../../core/services/remote-control.service';
import { LoggerService } from './../../../core/services/logger.service';
import { MessageRecipient } from './../../../core/models/message-recipient.model';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  HostListener,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/user.reducer';
import * as UserActions from 'src/app/state/user.actions';

@Component({
  selector: 'cm-message-indicator',
  templateUrl: './message-indicator.component.html',
  styleUrls: ['./message-indicator.component.scss'],
  animations: [
    trigger('focusedTrigger', [
      state(
        'true',
        style({
          transform: 'scale(1.2)',
          transformOrigin: '50% 50%',
        })
      ),
      state(
        'false',
        style({
          transform: 'scale(0)',
          transformOrigin: '50% 50%',
        })
      ),
      transition('* <=> *', animate('300ms ease-in')),
    ]),
  ],
})
export class MessageIndicatorComponent implements OnInit, OnChanges {
  @Input() unreadQueue: MessageRecipient[];

  indicatorVisible: boolean = false;

  constructor(
    private logger: LoggerService,
    private remoteControl: RemoteControlService,
    private el: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private store$: Store<UserState>
  ) {}

  ngOnInit() {
    this.setFocus();
  }

  setFocus() {
    this.el.nativeElement.focus();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === 13) {
      // enter/ok
      this.nextUnread();
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logger.info('onChanges called!');

    if (
      changes.hasOwnProperty('unreadQueue') &&
      changes['unreadQueue'].currentValue &&
      changes['unreadQueue'].currentValue.length > 0
    ) {
      this.logger.info(changes['unreadQueue'].currentValue.length);
      if (this.isUrgentUnreadInQueue()) {
        // Urgent message sets waiting, don't display indicator
        // just show next message set
        this.indicatorVisible = false;
        this.nextUnread();
        // Grab just listener keys
        this.remoteControl.setActiveBitmask(
          this.remoteControl.getKeysetWithSupervisorKeys()
        );
      } else {
        // Message sets waiting but not urgent,
        // Show indicator
        this.indicatorVisible = true;
        // Grab the ok button, along with base keys
        this.remoteControl.setActiveBitmask(
          this.remoteControl.getKeysetWithSupervisorKeys(REMOTE_KEY_SETS.ok)
        );
        this.setFocus();
      }
    } else {
      this.indicatorVisible = false;
      // Grab just listener keys
      this.remoteControl.setActiveBitmask(
        this.remoteControl.getKeysetWithSupervisorKeys()
      );
    }
  }

  showViewer() {
    this.nextUnread();
  }

  nextUnread() {
    if (this.unreadQueue.length > 0) {
      // Messages in queue, determine viewer and route
      // Display urgent first if any present
      const urgent = this.unreadQueue.filter((mr: MessageRecipient) =>
        this.isMessageSetUrgent(mr)
      );

      if (urgent.length > 0) {
        // Route to first urgent
        this.routeToViewer(urgent[0]);
      } else {
        // No urgent, go to first unread
        this.routeToViewer(this.unreadQueue[0]);
      }
    } else {
      // No more message sets, go to listener
      this.store$.dispatch(UserActions.navigateToHome());
    }
  }

  routeToViewer(messageRecipient: MessageRecipient) {
    this.router.navigate([
      'viewers/default',
      messageRecipient.id,
      messageRecipient.message_set.messages[0].id,
    ]);
  }

  isMessageSetUrgent(messageRecipient: MessageRecipient) {
    /*
     * Given a messageRecipient object, this function checks if the messageSet should be considered
     * urgent or not.  It does this by looking at messageSet.force_urgent, if this is set
     * to true, then the display preference in the resident's profile is overridden.  If it
     * is set to false, then the resident's display preference is obeyed.
     *
     */
    if (
      messageRecipient.message_set.force_urgent ||
      messageRecipient.message_set.system_message_type !== ''
    ) {
      return true;
    } else {
      return !messageRecipient.recipient.deliver_to_inbox;
    }
  }

  isUrgentUnreadInQueue() {
    const urgent = this.unreadQueue.filter((mr: MessageRecipient) =>
      this.isMessageSetUrgent(mr)
    );
    return urgent.length > 0;
  }
}
