import { createReducer, on } from '@ngrx/store';
import { BaseUser } from '../core/models/base-user.model';
import { SupporterResidentAssociation } from '../core/models/supporter-resident-association.model';
import * as RootActions from './root.actions';
import * as PresenceActions from './presence.actions';
import { AssistanceProvider } from '../core/models/assistance-provider.model';

export const presenceFeatureKey = 'presence';

export interface PresenceContainer {
  users: BaseUser[];
  channelName: string;
}

export interface PresenceState {
  associations: SupporterResidentAssociation[];
  assistanceProviders: AssistanceProvider[];
  global: PresenceContainer;
  customer: PresenceContainer;
  user: ChannelSubscription;
  stb: ChannelSubscription;
}

export interface ChannelSubscription {
  channelName: string;
}

const globlPresence: PresenceContainer = {
  users: [],
  channelName: '',
};

const customerPresence: PresenceContainer = {
  users: [],
  channelName: '',
};

const initialState: PresenceState = {
  associations: [],
  assistanceProviders: [],
  global: globlPresence,
  customer: customerPresence,
  user: undefined,
  stb: undefined,
};

export const presenceReducer = createReducer<PresenceState>(
  initialState,
  on(
    RootActions.subscribeGlobalChannelSuccess,
    (state: PresenceState, action) => {
      return {
        ...state,
        global: { ...state.global, channelName: action.channelName },
      };
    }
  ),
  on(RootActions.setGlobalUsers, (state: PresenceState, props) => {
    return { ...state, global: { ...state.global, users: props.users } };
  }),
  on(RootActions.globalUserOnline, (state: PresenceState, user: BaseUser) => {
    let users = [...state.global.users];
    let idx = state.global.users.findIndex((u) => u.id === user.id);
    if (idx !== -1) {
      users[idx] = user;
    } else {
      users.push(user);
    }
    return {
      ...state,
      global: { ...state.global, users },
    };
  }),
  on(RootActions.globalUserOffline, (state: PresenceState, user: BaseUser) => {
    return {
      ...state,
      global: {
        ...state.global,
        users: state.global.users.filter((u) => u.id !== user.id),
      },
    };
  }),
  on(
    RootActions.subscribeCustomerChannelSuccess,
    (state: PresenceState, action) => {
      return {
        ...state,
        customer: { ...state.customer, channelName: action.channelName },
      };
    }
  ),
  on(
    RootActions.getOnlineCustomerUsersSuccess,
    (state: PresenceState, props) => {
      return {
        ...state,
        customer: {
          ...state.customer,
          users: [...state.customer.users, ...props.users],
        },
      };
    }
  ),
  on(
    RootActions.customerUserPresenceUpdated,
    (state: PresenceState, action) => {
      let users = [...state.customer.users];
      const idx = users.findIndex((user) => user.id === action.user.id);
      idx === -1
        ? (users = [...users, action.user])
        : (users[idx] = action.user);
      return { ...state, customer: { ...state.customer, users } };
    }
  ),
  on(
    RootActions.customerUserPresenceUpdated,
    (state: PresenceState, action) => {
      const associations = [...state.associations];
      const idx = associations.findIndex(
        (association) => association.supporter.id === action.user.id
      );
      if (idx !== -1) {
        const association = { ...associations[idx] };
        association.supporter = action.user;
        associations[idx] = association;
      }
      return { ...state, associations };
    }
  ),
  on(
    RootActions.subscribeUserChannelSuccess,
    (state: PresenceState, action) => {
      return {
        ...state,
        user: { ...state.user, channelName: action.channelName },
      };
    }
  ),
  on(RootActions.subscribeStbChannelSuccess, (state: PresenceState, action) => {
    return {
      ...state,
      stb: { ...state.stb, channelName: action.channelName },
    };
  }),
  on(
    RootActions.getSupporterResidentAssociationsSuccess,
    (state: PresenceState, action) => {
      return {
        ...state,
        associations: action.associations,
      };
    }
  ),
  on(
    RootActions.supporterResidentAssociationUpdated,
    (state: PresenceState, action) => {
      let associations = [...state.associations];
      let idx = associations.findIndex(
        (association) => association.id === action.association.id
      );
      idx === -1
        ? (associations = [...associations, action.association])
        : (associations[idx] = action.association);
      return {
        ...state,
        associations,
      };
    }
  ),
  on(
    RootActions.supporterResidentAssociationDeleted,
    (state: PresenceState, action) => {
      const associations = state.associations.filter(
        (association) => association.id !== action.association.id
      );
      return { ...state, associations };
    }
  ),
  on(
    PresenceActions.getAssistanceProvidersSuccess,
    (state: PresenceState, action) => ({
      ...state,
      assistanceProviders: action.assistanceProviders,
    })
  )
);
