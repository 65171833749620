import { SupervisorService } from '../../core/services/supervisor.service';
import { MenuItem } from '../../core/models/menu-item.model';
import { Router } from '@angular/router';
import { LoggerService } from '../../core/services/logger.service';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/user.reducer';
import { Customer } from 'src/app/core/models/customer.model';
import { Observable, Subscription } from 'rxjs';
import { getCurrentCustomer } from 'src/app/state/user.selectors';
import * as UserActions from 'src/app/state/user.actions';

@Component({
  selector: 'cm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  translateStrings: any;
  currentItems: MenuItem[];
  currentCustomer$: Observable<Customer>;
  currentCustomer: Customer;
  currentCustomerSubscription: Subscription;

  constructor(
    private logger: LoggerService,
    private router: Router,
    private supervisor: SupervisorService,
    public translate: TranslateService,
    private store$: Store<UserState>
  ) {}

  ngOnInit(): void {
    const tokens = [
      'MENU_MESSAGE_HISTORY_TITLE',
      'MENU_MEDIA_LIBRARY_TITLE',
      'MENU_CONFERENCING_TITLE',
      'MENU_PROFILE_TITLE',
      'MENU_TOOLS_TITLE',
      'MENU_ASSISTANCE_TITLE',
      'MENU_CHURCH_TITLE',
      'MENU_EXIT_TITLE',
    ];
    this.translate.get(tokens).subscribe((result) => {
      this.translateStrings = result;
      this.currentItems = [
        {
          id: 1,
          tag: 'message_history',
          icon: 'cm:comment-bubble',
          label: this.translateStrings.MENU_MESSAGE_HISTORY_TITLE,
        },
        {
          id: 2,
          tag: 'media_library',
          icon: 'cm:images',
          label: this.translateStrings.MENU_MEDIA_LIBRARY_TITLE,
        },
        // {
        //   id: 3,
        //   tag: 'conferencing',
        //   icon: 'cm:phone-call',
        //   label: this.translateStrings.MENU_CONFERENCING_TITLE,
        // },
        {
          id: 4,
          tag: 'profile',
          icon: 'cm:no-avatar',
          label: this.translateStrings.MENU_PROFILE_TITLE,
        },
        {
          id: 5,
          tag: 'utilities',
          icon: 'cm:utilities',
          label: this.translateStrings.MENU_TOOLS_TITLE,
        },
        {
          id: 6,
          tag: 'assistance',
          icon: 'cm:alert-attention',
          label: this.translateStrings.MENU_ASSISTANCE_TITLE,
        },
        {
          id: 8,
          tag: 'exit',
          icon: 'cm:cross-circle',
          label: this.translateStrings.MENU_EXIT_TITLE,
        },
      ];

      // Insert Church Icon if Customer is RO
      // if (this.currentCustomer.name === 'RO') {
      //   this.currentItems.splice(6, 0, {
      //     id: 7,
      //     tag: 'church',
      //     icon: 'cm:sun',
      //     label: this.translateStrings.MENU_CHURCH_TITLE,
      //   });
      // }
    });

    this.currentCustomer$ = this.store$.select(getCurrentCustomer);
    this.currentCustomerSubscription = this.currentCustomer$.subscribe(
      (customer: Customer) => (this.currentCustomer = customer)
    );
  }

  ngOnDestroy(): void {
    this.currentCustomerSubscription.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToListener();
    }
  }

  onItemSelected(item): void {
    switch (item.tag) {
      case 'message_history':
        this.router.navigate(['dashboard/message-history']);
        break;
      case 'media_library':
        this.router.navigate(['dashboard/media-library']);
        break;
      case 'conferencing':
        this.router.navigate(['dashboard/request-call']);
        break;
      case 'profile':
        this.router.navigate(['dashboard/profile']);
        break;
      case 'utilities':
        this.router.navigate(['dashboard/utilities']);
        break;
      case 'assistance':
        this.router.navigate(['assistance/request-assistance']);
        break;
      case 'church':
        this.router.navigate(['church/home']);
        break;
      case 'exit':
        this.goToListener();
        break;
    }
  }

  goToListener(): void {
    this.supervisor.showSettings = false;
    this.store$.dispatch(UserActions.navigateToHome());
  }
}
