import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';

import { AssistanceRoutingModule } from './assistance-routing.module';
import { AssistanceRequestDialogComponent } from './assistance-request-dialog/assistance-request-dialog.component';
import { AssistanceRequestConfirmationDialogComponent } from './assistance-request-confirmation-dialog/assistance-request-confirmation-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        AssistanceRoutingModule
    ],
    declarations: [AssistanceRequestDialogComponent, AssistanceRequestConfirmationDialogComponent]
})
export class AssistanceModule { }
