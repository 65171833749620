<!--<cm-overlay [width]="1600" [height]="900" header="Media Library">
    <div class="media-item-container" *ngIf="!showImageViewer">
        <div infinite-scroll (scrolled)="mediaLibrary.getNextPage()" [infiniteScrollDistance]="0" [infiniteScrollDisabled]='mediaLibrary.state.busy' [infiniteScrollContainer]="'.media-item-container'">
            <cm-grid [images]="mediaLibrary.images" (imageSelected)="goToImage($event)"></cm-grid>
        </div>
    </div>
    <cm-image-viewer [images]="mediaLibrary.images" [currentImage]="currentImage" (backPressed)="onImageViewerBack($event)" *ngIf="showImageViewer"></cm-image-viewer>
</cm-overlay>-->
<div class="container">
<div
  class="images"
  *ngIf="!showImageViewer && images !== undefined && images.length > 0"
  infinite-scroll
  [infiniteScrollDistance]="1"
  [infiniteScrollThrottle]="100"
  [scrollWindow]="true"
  (scrolled)="mediaLibrary.getNextPage()"
>
  <div
    class="photo"
    *ngFor="let image of images; let isFirst = first; let isLast = last"
    [class.active]="isFirst"
    [ngStyle]="{
      'background-image': 'url(' + image.original_url + ')',
      'width.px': image.width,
      'height.px': image.height
    }"
    [attr.data-photo-id]="image.id"
  ></div>
</div>

<div *ngIf="!showImageViewer && (images !== undefined && images.length === 0)" class="no-images">
  <div>Media Library is Empty</div>
  <div>Press Back Button to Exit</div>
</div>

<cm-image-viewer
  *ngIf="showImageViewer"
  [images]="images"
  [currentImage]="currentImage"
  (backPressed)="onBackPressed($event)"
></cm-image-viewer>
</div>

<!--<cm-video-viewer-->
<!--  [video]="message.videos[0]"-->
<!--  (closeVideoViewer)="onCloseVideoViewer($event)"-->
<!--&gt;</cm-video-viewer>-->
