import { AssistanceRequest } from '../../core/models/assistance-request.model';
import { AssistanceRequestService } from '../../core/services/assistance-request.service';
import { LoggerService } from '../../core/services/logger.service';
import { SupervisorService } from '../../core/services/supervisor.service';
import { Router } from '@angular/router';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as UserActions from 'src/app/state/user.actions';
import { Observable, Subscription } from 'rxjs';
import { Customer } from 'src/app/core/models/customer.model';
import {
  getCurrentCustomer,
  selectCurrentUser,
} from 'src/app/state/user.selectors';
import { State } from '../../state/root.reducer';
import * as PresenceSelectors from '../../state/presence.selectors';
import * as PresenceActions from '../../state/presence.actions';
import { Resident } from '../../core/models/resident.model';

@Component({
  selector: 'cm-assistance-request-dialog',
  templateUrl: './assistance-request-dialog.component.html',
  styleUrls: ['./assistance-request-dialog.component.scss'],
})
export class AssistanceRequestDialogComponent implements OnInit, OnDestroy {
  currentCustomer$: Observable<Customer>;

  currentUser$: Observable<Resident>;
  currentUser: Resident;
  currentUserSubscription: Subscription;

  translateStrings: any;
  noProvidersMessage = '';

  assistanceProviderChoices$: Observable<Array<any>>;

  constructor(
    private router: Router,
    private supervisor: SupervisorService,
    private logger: LoggerService,
    private assistanceRequest: AssistanceRequestService,
    private translate: TranslateService,
    private store$: Store<State>
  ) {}

  ngOnInit(): void {
    this.currentCustomer$ = this.store$.select(getCurrentCustomer);
    this.currentUser$ = this.store$.select(selectCurrentUser);
    this.currentUserSubscription = this.currentUser$.subscribe(
      (resident: Resident) => (this.currentUser = resident)
    );

    const tokens = [
      'ASSISTANCE_NO_PROVIDERS_AVAILABLE',
      'ASSISTANCE_ERROR_TITLE',
      'ASSISTANCE_FAILED_TO_GET_PROVIDERS',
      'ASSISTANCE_ERROR_SENDING_REQUEST',
      'ASSISTANCE_REQUEST_CANCEL',
    ];
    this.translate.get(tokens).subscribe((result) => {
      this.translateStrings = result;
      this.noProvidersMessage =
        this.translateStrings.ASSISTANCE_NO_PROVIDERS_AVAILABLE;
    });

    this.assistanceProviderChoices$ = this.store$.select(
      PresenceSelectors.selectAssistanceProviderChoices
    );
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.backToListener();
    }
  }

  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
  }

  backToListener(): void {
    this.supervisor.showAssistance = false;
    this.supervisor.showSettings = false;
    this.store$.dispatch(UserActions.navigateToHome());
  }

  sendAssistanceRequest(choice): void {
    if (choice.type === 'cancel') {
      // cancel
      this.backToListener();
    } else {
      if (choice.type === 'supporter') {
        this.store$.dispatch(
          PresenceActions.sendSupporterAssistanceRequest({
            supporterResidentAssociation: choice.supporterResidentAssociation,
          })
        );
      } else {
        // send request
        const assistanceRequest = new AssistanceRequest();
        assistanceRequest.assistance_provider = choice.id;
        assistanceRequest.resident = this.currentUser.id;
        this.store$.dispatch(
          PresenceActions.sendAssistanceRequest({ assistanceRequest })
        );
      }
    }
  }
}
