import { ErrorViewerComponent } from './error-viewer/error-viewer.component';
import { RemoteControlGuard } from './../core/guards/remote-control-guard.service';
import { AuthGuard } from './../core/guards/auth-guard.service';
import { DefaultViewerComponent } from './default-viewer/default-viewer.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { REMOTE_KEY_SETS } from './../core/services/remote-control.service';
import { ImageViewerContainerComponent } from './image-viewer-container/image-viewer-container.component';
import { VideoViewerContainerComponent } from './video-viewer-container/video-viewer-container.component';

const routes: Routes = [
  {
    path: 'default/:messageRecipientId/:messageId',
    component: DefaultViewerComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back_colours },
  },
  {
    path: 'image-viewer/:messageRecipientId/:messageId',
    component: ImageViewerContainerComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'video-viewer/:messageRecipientId/:messageId',
    component: VideoViewerContainerComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back_colours },
  },
  {
    path: 'error/:errorTitle/:errorMessage/:buttonText',
    component: ErrorViewerComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ViewersRoutingModule {}
