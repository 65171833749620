import { LoggerService } from './logger.service';
import { MessageImage } from './../models/message-image.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

class MediaLibraryState {
  busy: boolean;
  next: string;
}

@Injectable()
export class MediaLibraryService {
  endpointUrl: string = `${environment.apiUrl}/message-image?page=1&page_size=50`;

  images: MessageImage[] = [];
  count: number;

  state: MediaLibraryState = {
    busy: false,
    next: this.endpointUrl,
  };

  constructor(private http: HttpClient, private logger: LoggerService) {}

  populate() {
    this.getNextPage();
  }

  getNextPage() {
    this.logger.info('getNextPage() called');
    if (this.state.busy) return;
    this.state.busy = true;
    if (this.state.next) {
      this.http
        .get(this.state.next)
        .toPromise()
        .then((response: any) => {
          this.count = response.count;
          this.images.push.apply(this.images, response.results);
          this.logger.info(`media library : count : ${this.count}`);
          this.logger.info(`media library : got : ${this.images.length}`);
          if (response.next) {
            this.state.next = response.next;
          } else {
            this.state.next = null;
          }

          // reset busy flag
          this.state.busy = false;
        });
    } else {
      // no next
      this.state.busy = false;
      return;
    }
  }
}
