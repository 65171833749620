<div class="media-box">
  <img class="bigimg" src="{{ currentImage.original_url }}" />

  <div class="arrows" *ngIf="images.length > 1">
    <mat-icon svgIcon="cm:previous"></mat-icon>
    <mat-icon svgIcon="cm:next"></mat-icon>
  </div>

  <div class="counter" *ngIf="images.length > 1">
    {{ currentImageIndex + 1 }} {{ "IMAGE_VIEWER_OF" | translate }} {{ images.length }}
  </div>

  <div class="timestamp">
    {{ currentImage.created | date: "dd/MM/yy" }} {{ "IMAGE_VIEWER_AT" | translate }}  {{ currentImage.created | date: "h:mma" }}
  </div>
</div>
