import { forkJoin, Observable, of } from 'rxjs';
import { MessageRecipient } from '../models/message-recipient.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from '../models/api-response';
import { BaseUser } from '../models/base-user.model';
import { Resident } from '../models/resident.model';

@Injectable()
export class MessageRecipientService {
  endpointUrl: string;

  constructor(private http: HttpClient) {
    this.endpointUrl = `${environment.apiUrl}/message-recipient`;
  }

  getMessageRecipientById(messageRecipientId): Observable<MessageRecipient> {
    return this.http
      .get<MessageRecipient>(`${this.endpointUrl}/${messageRecipientId}`)
      .pipe(catchError(this.handleError));
  }

  getMessageRecipient(
    messageSetId: number,
    recipientId: number
  ): Observable<MessageRecipient> {
    let params = new HttpParams();
    params = params.set('message_set', messageSetId.toString());
    params = params.set('recipient', recipientId.toString());
    return this.http
      .get<MessageRecipient>(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getMessageRecipients(resident: BaseUser, url?: string) {
    const urlToCall = url === undefined || url === '' ? this.endpointUrl : url;
    let params = new HttpParams();
    params = params.set('recipient', resident.id.toString());
    params = params.set('ordering', '-created');
    params = params.set('page_size', '20');
    return this.http
      .get<ApiResponse<MessageRecipient>>(urlToCall, { params })
      .pipe(catchError(this.handleError));
  }

  getUnreadMessageRecipients(resident: Resident) {
    let params = new HttpParams();
    params = params.set('recipient', resident.id.toString());
    params = params.append('status', 'unread');
    params = params.append('status', 'pending');
    params = params.append('status', 'delivered');

    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getReadMessageRecipients(resident: Resident) {
    let params = new HttpParams();
    params = params.set('recipient', resident.id.toString());
    params = params.set('status', 'read');
    params = params.set('ordering', '-created');
    params = params.set('page_size', '20');
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  putMessageRecpient(messageRecpient: MessageRecipient) {
    let params = new HttpParams();
    const url = `${this.endpointUrl}/${messageRecpient.id}`;

    return this.http
      .put(url, messageRecpient, { params })
      .pipe(catchError(this.handleError));
  }

  markAsDelivered(messageRecipients) {
    let observables: Object = {},
      unreadResults: Object = {};
    /*
     * Iterate messageRecipients, if any have a status of
     * 'unread' or 'pending', update their status to 'delivered'
     */
    messageRecipients.forEach((mr) => {
      unreadResults[mr.id] = mr;
      if (mr.status === 'unread' || mr.status === 'pending') {
        observables[mr.id] = this.http.patch(`${this.endpointUrl}/${mr.id}`, {
          status: 'delivered',
        });
      }
    });

    // convert map to array
    let obs = Object.keys(observables).map((key) => observables[key]);

    if (obs.length === 0) {
      return of(messageRecipients);
    }

    return forkJoin(obs).pipe(
      map((results) => {
        for (let result in results) {
          if (results.hasOwnProperty(result)) {
            let messageRecipient = results[result] as MessageRecipient;
            unreadResults[messageRecipient.id] = messageRecipient;
          }
        }
        // Convert map to array
        let unread = Object.keys(unreadResults).map(
          (key) => unreadResults[key]
        );
        return unread;
      }),
      catchError(this.handleError)
    );
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
