import { MessageHistoryComponent } from './message-history/message-history.component';
import { MediaLibraryComponent } from './media-library/media-library.component';
import { RequestCallComponent } from './request-call/request-call.component';
import { SettingsComponent } from './profile/settings/settings.component';
import { PincodeComponent } from './profile/pincode/pincode.component';
import { ThemesComponent } from './profile/themes/themes.component';
import { ProfileComponent } from './profile/profile.component';
import { UtilitiesComponent } from './utilities/utilities.component';
import { AuthGuard } from './../core/guards/auth-guard.service';
import { RemoteControlGuard } from './../core/guards/remote-control-guard.service';
import { MenuComponent } from './menu/menu.component';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { REMOTE_KEY_SETS } from './../core/services/remote-control.service';
import { AccountDetailComponent } from './profile/account-detail/account-detail.component';
import { MessageHistoryVideoViewerComponent } from './message-history/message-history-video-viewer/message-history-video-viewer.component';
import { MessageHistoryImageViewerComponent } from './message-history/message-history-image-viewer/message-history-image-viewer.component';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { VideoInputTestComponent } from './diagnostics/video-input-test/video-input-test.component';
import { AboutComponent } from './about/about.component';
import { LocalVideoPreviewComponent } from './diagnostics/video-input-test/local-video-preview/local-video-preview.component';
import { MediaConnectionBitrateTestComponent } from './diagnostics/media-connection-bitrate-test/media-connection-bitrate-test.component';

const routes: Routes = [
  {
    path: 'menu',
    component: MenuComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'utilities',
    component: UtilitiesComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_numbers },
  },
  {
    path: 'request-call',
    component: RequestCallComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'media-library',
    component: MediaLibraryComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'message-history',
    component: MessageHistoryComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back_colours },
  },
  {
    path: 'message-history/image-viewer/:messageRecipientId/:messageId',
    component: MessageHistoryImageViewerComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back_colours },
  },
  {
    path: 'message-history/video-viewer/:messageRecipientId/:messageId',
    component: MessageHistoryVideoViewerComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back_colours },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'profile/account-detail',
    component: AccountDetailComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'profile/themes',
    component: ThemesComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'profile/pincode',
    component: PincodeComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'profile/settings',
    component: SettingsComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'diagnostics',
    component: DiagnosticsComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'diagnostics/camera-test',
    component: VideoInputTestComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'diagnostics/camera-test/local-video-preview',
    component: LocalVideoPreviewComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'diagnostics/bitrate-test',
    component: MediaConnectionBitrateTestComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
