import { Router } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

    constructor(
        private router: Router
    ) {

    }

    ngOnInit() {

    }

    onOk(event) {
        this.router.navigate(['setup/authenticate']);
    }
}
