<div class="hud">
<!--  <div class="configuration">-->
<!--    <div class="hud-field" *ngIf="configuration?.messengerVersionNumber">-->
<!--      <mat-icon svgIcon="cm:comment-bubble"></mat-icon>-->
<!--      <h4>{{ configuration?.messengerVersionNumber }}</h4>-->
<!--    </div>-->
<!--    <div class="hud-field" *ngIf="configuration?.macAddress">-->
<!--      <mat-icon svgIcon="cm:router-wifi"></mat-icon>-->
<!--      <h4>{{ configuration?.macAddress }}</h4>-->
<!--    </div>-->
<!--    <div class="hud-field" *ngIf="configuration?.ipAddress">-->
<!--      <mat-icon svgIcon="cm:hierachy"></mat-icon>-->
<!--      <h4>{{ configuration?.ipAddress }}</h4>-->
<!--    </div>-->
<!--    <div class="hud-field">-->
<!--      <mat-icon svgIcon="cm:user"></mat-icon>-->
<!--      <h4>{{ configuration?.resident?.username }}</h4>-->
<!--    </div>-->

<!--    <div class="rcu-state">-->
<!--      &lt;!&ndash;<h4 [ngClass]="{true: 'active', false: 'inactive'}[configuration.setTopBox.av_key_enabled]">AV</h4>-->
<!--            <h4 [ngClass]="{true: 'active', false: 'inactive'}[configuration.setTopBox.menu_key_enabled]">MENU</h4>&ndash;&gt;-->
<!--    </div>-->

<!--    <div class="settings-state">-->
<!--      <mat-icon-->
<!--        svgIcon="cm:padlock-locked"-->
<!--        *ngIf="pusher.isEncrypted()"-->
<!--        class="active"-->
<!--      ></mat-icon>-->
<!--      <mat-icon-->
<!--        svgIcon="cm:padlock-unlocked"-->
<!--        *ngIf="!pusher.isEncrypted()"-->
<!--        class="inactive"-->
<!--      ></mat-icon>-->
<!--      <mat-icon svgIcon="cm:logging"></mat-icon>-->
<!--      <mat-icon svgIcon="cm:cloud-sync" [ngClass]="{true: 'active', false: 'inactive'}[pusher.getCurrentState() == 'connected']"></mat-icon>-->
<!--      <mat-icon svgIcon="cm:network-antenna"></mat-icon>-->
<!--    </div>-->
<!--  </div>-->
<!--    <div class="key-bindings">-->
<!--      <div class="hud-field">-->
<!--        <label><mat-icon svgIcon="cm:remote-control"></mat-icon></label>-->
<!--        <h4>{{ remoteControl.getActiveKeyNames().join(" | ") }}</h4>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
