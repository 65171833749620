import { RemoteControlService } from './../../../core/services/remote-control.service';
import { PusherService } from './../../../core/services/pusher.service';
import { ConfigurationService } from './../../../core/services/configuration.service';
import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'cm-debug-toolbar',
    templateUrl: './debug-toolbar.component.html',
    styleUrls: ['./debug-toolbar.component.scss']
})
export class DebugToolbarComponent implements OnInit {

    constructor(
        public configuration: ConfigurationService,
        public pusher: PusherService,
        public remoteControl: RemoteControlService
    ) { }

    ngOnInit() {

    }
}
