<div class="accept-call-with-delay-dialog-container">
  <div id="user-info">
    <cm-avatar-thumb
      [user]="callOptions.initiator"
      [size]="240"
      [hidePresence]="true"
    ></cm-avatar-thumb>
    <div id="name-container">
      <h3>
        {{ callOptions.initiator.first_name }}
        {{ callOptions.initiator.last_name }}
      </h3>
    </div>
    <div id="countdown-container"><span>{{ 'CONFERENCING_STARTING_CALL_IN' | translate }}</span><span id="countdown" [@animateTrigger]="animate">{{ countDown }}</span></div>
  </div>
</div>
