<cm-overlay
  [width]="1150"
  [height]="640"
  [header]="(currentCustomer$ | async).settings_menu_label"
>
  <cm-menu-grid
    [items]="currentItems"
    (itemSelected)="onItemSelected($event)"
    style="margin: 50px"
    [columns]="4"
  ></cm-menu-grid>
</cm-overlay>
