<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'PROFILE_SETTINGS_TITLE' | translate }}"
>
  <cm-choice-selector
    [choices]="settings"
    (choiceSelected)="onSettingChanged($event)"
    [columns]="1"
    [choiceWidth]="1000"
    [choiceHeight]="100"
    [showToggle]="true"
    [showIcon]="true"
    [iconHeight]="64"
    [iconWidth]="64"
  >
  </cm-choice-selector>
</cm-overlay>
