import { Component, Input, OnInit } from '@angular/core';
import { SupporterResidentAssociation } from '../../../core/models/supporter-resident-association.model';
import { Resident } from '../../../core/models/resident.model';

@Component({
  selector: 'cm-associations-debug-viewer',
  templateUrl: './associations-debug-viewer.component.html',
  styleUrls: ['./associations-debug-viewer.component.scss'],
})
export class AssociationsDebugViewerComponent implements OnInit {
  @Input() resident: Resident;
  @Input() associations: SupporterResidentAssociation[];

  constructor() {}

  ngOnInit(): void {}
}
