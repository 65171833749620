import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MessageRecipient } from 'src/app/core/models/message-recipient.model';
import { ASCII_REMOTE_KEYS } from 'src/app/core/services/remote-control.service';
import {
  isMessageRecipientsSpinnerShowing,
  selectCurrentMessageRecipient,
  selectCurrentMessageRecipientIndex,
  selectMessageRecipientCount,
} from 'src/app/state/messages.selectors';
import { State } from 'src/app/state/root.reducer';
import * as MessagesActions from 'src/app/state/messages.actions';

@Component({
  selector: 'app-message-set-history-viewer',
  templateUrl: './message-set-history-viewer.component.html',
  styleUrls: ['./message-set-history-viewer.component.scss'],
})
export class MessageSetHistoryViewerComponent implements OnInit, OnDestroy {
  @Input() activeMessageRecipientIndex = 0;

  currentMessageRecipient$: Observable<MessageRecipient>;
  currentMessageRecipientSubscription: Subscription;
  currentMessageRecipient: MessageRecipient;
  currentMessageRecipientIndex$: Observable<number>;
  messageRecipientCount$: Observable<number>;
  isMessageRecipientsSpinnerShowing$: Observable<boolean>;
  isMessageRecipientsSpinnerShowingSubscription: Subscription;
  isMessageRecipientsSpinnerShowing = false;

  constructor(private router: Router, private store$: Store<State>) {}

  ngOnInit(): void {
    this.currentMessageRecipient$ = this.store$.select(
      selectCurrentMessageRecipient
    );

    this.currentMessageRecipientIndex$ = this.store$.select(
      selectCurrentMessageRecipientIndex
    );

    this.messageRecipientCount$ = this.store$.select(
      selectMessageRecipientCount
    );

    this.isMessageRecipientsSpinnerShowing$ = this.store$.select(
      isMessageRecipientsSpinnerShowing
    );

    this.currentMessageRecipientSubscription =
      this.currentMessageRecipient$.subscribe(
        (mr: MessageRecipient) => (this.currentMessageRecipient = mr)
      );

    this.isMessageRecipientsSpinnerShowingSubscription =
      this.isMessageRecipientsSpinnerShowing$.subscribe(
        (isShowing) => (this.isMessageRecipientsSpinnerShowing = isShowing)
      );
  }

  ngOnDestroy(): void {
    this.currentMessageRecipientSubscription.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if (!this.isMessageRecipientsSpinnerShowing) {
      switch ($event.keyCode) {
        case ASCII_REMOTE_KEYS.down:
          this.next();
          break;
        case ASCII_REMOTE_KEYS.up:
          this.previous();
          break;
        case ASCII_REMOTE_KEYS.blue:
          this.goToViewer();
          break;
      }
    }
    return false;
  }

  next(): void {
    this.store$.dispatch(MessagesActions.moveToNextMessageRecipient());
  }

  previous(): void {
    this.store$.dispatch(MessagesActions.moveToPreviousMessageRecipient());
  }

  private goToViewer(): void {
    const isVideoMessage =
      this.currentMessageRecipient.message_set.messages[0].videos.length > 0;
    if (isVideoMessage) {
      this.goToVideoViewer();
    } else {
      this.goToImageViewer();
    }
  }

  private goToVideoViewer(): void {
    this.router.navigate([
      'dashboard/message-history/video-viewer/',
      this.currentMessageRecipient.id,
      this.currentMessageRecipient.message_set.messages[0].id,
    ]);
  }

  private goToImageViewer(): void {
    this.router.navigate([
      'dashboard/message-history/image-viewer/',
      this.currentMessageRecipient.id,
      this.currentMessageRecipient.message_set.messages[0].id,
    ]);
  }
}
