import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';

import { ViewersRoutingModule } from './viewers-routing.module';
import { DefaultViewerComponent } from './default-viewer/default-viewer.component';
import { ErrorViewerComponent } from './error-viewer/error-viewer.component';
import { ImageViewerContainerComponent } from './image-viewer-container/image-viewer-container.component';
import { VideoViewerContainerComponent } from './video-viewer-container/video-viewer-container.component';

@NgModule({
  imports: [SharedModule, ViewersRoutingModule],
  declarations: [
    DefaultViewerComponent,
    ErrorViewerComponent,
    ImageViewerContainerComponent,
    VideoViewerContainerComponent,
  ],
})
export class ViewersModule {}
