import { LoggerService } from '../../../core/services/logger.service';
import { MessageImage } from '../../../core/models/message-image.model';
import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { last, first } from 'lodash-es';
import { ASCII_REMOTE_KEYS } from '../../../core/services/remote-control.service';

@Component({
  selector: 'cm-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit {
  @Input() images: MessageImage[];
  @Input() currentImage: MessageImage;

  @Output() backPressed = new EventEmitter();

  private startImage: MessageImage;

  currentImageIndex: number;

  constructor(private logger: LoggerService) {}

  ngOnInit(): void {
    if (!this.currentImage) {
      this.currentImage = this.images[0];
    }

    this.startImage = this.currentImage;
    this.currentImageIndex = this.images.findIndex(
      (image) => image.id === this.currentImage.id
    );
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): boolean {
    switch ($event.keyCode) {
      case ASCII_REMOTE_KEYS.left:
        this.previous();
        break;
      case ASCII_REMOTE_KEYS.right:
        this.next();
        break;
      case ASCII_REMOTE_KEYS.back:
        this.backPressed.emit(this.currentImage);
        break;
      case ASCII_REMOTE_KEYS.blue:
        this.backPressed.emit(this.currentImage);
        break;
      case ASCII_REMOTE_KEYS.ok:
        break;
    }

    return false;
  }

  next(): void {
    if (last(this.images) !== this.currentImage) {
      this.currentImageIndex += 1;
      this.currentImage = this.images[this.currentImageIndex];
    }
  }

  previous(): void {
    if (first(this.images) !== this.currentImage) {
      this.currentImageIndex -= 1;
      this.currentImage = this.images[this.currentImageIndex];
    }
  }

  close(): void {
    this.logger.info(`image_viewer : close`);
    // Go back from whence we came...
    // $state.go($rootScope.fromState, {
    //     imageId: $state.params.imageId
    // });
  }
}
