import { SupporterResidentAssociation } from './../models/supporter-resident-association.model';
import { SetTopBox } from './../models/set-top-box.model';
import { Resident } from './../models/resident.model';
import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { merge } from 'lodash-es';

// const { version: MESSENGER_VERSION } = require('../../../../package.json')
declare var hcap: any;
const DEFAULT_MAC_ADDRESS = 'A0:B1:C2:D3:E4:F5';
const STORAGE_KEY = 'ny7y789t7tj7t9k97tk7t';
const MESSENGER_VERSION_NUMBER = '1.0';

export class Settings {
  showHUD: boolean = false;
  loggingEnabled: boolean = false;
  theme: string = 'default';
}

export class UrlConfiguration {
  startupUrl: string;
  sunriseUpdateUrl: string;
  customerSupportUrl: string;
  pingUrl: string;
}

@Injectable()
export class ConfigurationService {
  resident: Resident;
  setTopBox: SetTopBox;
  associations: SupporterResidentAssociation[];
  macAddress: string = this.getMacAddress();
  messengerVersionNumber: string;
  countryCode: string;
  ipAddress: string;
  defaultAVInput: string;
  settings: Settings = new Settings();
  urlConfiguration: UrlConfiguration = new UrlConfiguration();

  constructor(
    private storage: LocalStorageService,
    private logger: LoggerService
  ) {
    this.populateFromStorage();
    this.ipAddress = this.getIPAddress();
    this.save();
  }

  private populateFromStorage() {
    let config = this.storage.retrieve(STORAGE_KEY);
    if (config) {
      //let decrypted = sjcl.decrypt(this.getMacAddress(), config);
      let obj = JSON.parse(config);
      // merge stored object into this
      merge(this, obj);
    }
  }

  private getMacAddress() {
    /*
    Try's hardware first, then local storage,
    then returns hard coded default if not available
    */
    const macAddress = localStorage.getItem('macAddress');
    if (macAddress && macAddress !== '') {
      return macAddress;
    }
    return DEFAULT_MAC_ADDRESS;
  }

  private getMessengerVersionNumber() {
    return MESSENGER_VERSION_NUMBER;
  }

  private getIPAddress() {
    this.logger.info(`configuration : get ip address`);
    this.logger.warn(`getIPAddress() not implemented`);
    return '';
  }

  private getCountryCode() {
    this.logger.warn(`getCountryCode not implemented`);
  }

  private removeConfiguration() {
    this.storage.clear(STORAGE_KEY);
  }

  generateUUID() {
    let d = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  save() {
    let config = { ...this };
    delete config.storage;
    let json = JSON.stringify(config);
    //let encrypted = sjcl.encrypt(config.getMacAddress(), json);
    this.storage.store(STORAGE_KEY, json);
  }

  reload() {
    window.location.replace(window.location.origin);
  }

  reset() {
    this.removeConfiguration();
  }
}
