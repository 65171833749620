import { StbChannelService } from '../../core/services/stb-channel.service';
import { Observable, Subscription } from 'rxjs';
import { MessageRecipient } from '../../core/models/message-recipient.model';
import { SupervisorService } from '../../core/services/supervisor.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { find } from 'lodash-es';
import { Store } from '@ngrx/store';
import { MessagesState } from 'src/app/state/messages.reducer';
import { selectMessageRecipientById } from 'src/app/state/messages.selectors';

@Component({
  selector: 'cm-default-viewer',
  templateUrl: './default-viewer.component.html',
  styleUrls: ['./default-viewer.component.scss'],
})
export class DefaultViewerComponent implements OnInit, OnDestroy {
  private messageRecpientRevokedSubscription: Subscription;

  messageRecipientId: number;
  messageRecipient$: Observable<MessageRecipient>;
  currentMessage: number = 1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private supervisor: SupervisorService,
    private stbChannel: StbChannelService,
    private store$: Store<MessagesState>
  ) {}

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
      this.messageRecipientId = +params['messageRecipientId'];
    });

    this.messageRecipient$ = this.store$.select(
      selectMessageRecipientById(this.messageRecipientId)
    );

    this.messageRecpientRevokedSubscription =
      this.stbChannel.messageRecipientRevoked$.subscribe((data: any) => {
        this.revokeMessageRecipient(data);
      });
  }

  ngOnDestroy(): void {
    this.messageRecpientRevokedSubscription.unsubscribe();
  }

  getMessageRecipient() {
    return find(
      this.supervisor.unread,
      (mr) => mr.id === this.messageRecipientId
    );
  }

  onCurrentMessageChanged(messageIndex) {
    this.currentMessage = messageIndex + 1;
  }

  // getHeader() {
  //   let header: string = '';
  //   if (this.messageRecipient) {
  //     switch (this.messageRecipient.message_set.ingress_type) {
  //       case 'sms':
  //         header = `${this.messageRecipient.message_set.contact.first_name} ${this.messageRecipient.message_set.contact.last_name} (${this.messageRecipient.message_set.cli})`;
  //         break;
  //       case 'email':
  //         header = `${this.messageRecipient.message_set.contact.first_name} ${this.messageRecipient.message_set.contact.last_name}`;
  //         break;
  //       default:
  //         header = `${this.messageRecipient.message_set.sender.first_name} ${this.messageRecipient.message_set.sender.last_name}`;
  //         break;
  //     }
  //     header = `${this.messageRecipient.message_set.sender.first_name} ${this.messageRecipient.message_set.sender.last_name}`;
  //     return header;
  //   }
  // }

  getAvatar() {
    // if (this.messageRecipient) {
    //   switch (this.messageRecipient.message_set.ingress_type) {
    //     case 'sms':
    //       return this.messageRecipient.message_set.contact;
    //     case 'email':
    //       return this.messageRecipient.message_set.contact;
    //     default:
    //       return this.messageRecipient.message_set.sender;
    //   }
    // }
  }

  revokeMessageRecipient(data) {
    // if (this.messageRecipient.id === data.messageRecipientId) {
    //   this.router.navigate(['supervisor/listener']);
    // }
  }
}
