import { ErrorComponent } from './error/error.component';
import { CompleteComponent } from './complete/complete.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { REMOTE_KEY_SETS } from './../core/services/remote-control.service';
import { RemoteControlGuard } from './../core/guards/remote-control-guard.service';
import { WelcomeComponent } from './welcome/welcome.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok },
  },
  {
    path: 'authenticate',
    component: AuthenticateComponent,
    canActivate: [RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok },
  },
  {
    path: 'complete',
    component: CompleteComponent,
    canActivate: [RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok },
  },
  {
    path: 'error/:macAddress',
    component: ErrorComponent,
    canActivate: [RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SetupRoutingModule {}
