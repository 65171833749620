<cm-overlay
  [header]="header"
  [width]="800"
  [height]="600"
>
  <div *ngIf="lock">
    <mat-progress-spinner></mat-progress-spinner>
  </div>
  <div *ngIf="!lock">
    <h1 class="confirmation">{{ noSupportersMessage }}</h1>
    <cm-choice-selector
      [choices]="choices"
      (choiceSelected)="sendCallRequest($event)"
      [choiceWidth]="600"
      [choiceHeight]="110"
      showAvatar="true"
      *ngIf="choices"
    ></cm-choice-selector>
  </div>
</cm-overlay>
