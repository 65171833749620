import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { MenuComponent } from './menu/menu.component';
import { UtilitiesComponent } from './utilities/utilities.component';
import { ProfileComponent } from './profile/profile.component';
import { ThemesComponent } from './profile/themes/themes.component';
import { PincodeComponent } from './profile/pincode/pincode.component';
import { SettingsComponent } from './profile/settings/settings.component';
import { RequestCallComponent } from './request-call/request-call.component';
import { MediaLibraryComponent } from './media-library/media-library.component';
import { MessageHistoryComponent } from './message-history/message-history.component';
import { AccountDetailComponent } from './profile/account-detail/account-detail.component';
import { MessageHistoryImageViewerComponent } from './message-history/message-history-image-viewer/message-history-image-viewer.component';
import { MessageHistoryVideoViewerComponent } from './message-history/message-history-video-viewer/message-history-video-viewer.component';
import { VideoInputTestComponent } from './diagnostics/video-input-test/video-input-test.component';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { AudioInputTestComponent } from './diagnostics/audio-input-test/audio-input-test.component';
import { AudioOutputTestComponent } from './diagnostics/audio-output-test/audio-output-test.component';
import { MediaConnectionBitrateTestComponent } from './diagnostics/media-connection-bitrate-test/media-connection-bitrate-test.component';
import { AboutComponent } from './about/about.component';
import { LocalVideoPreviewComponent } from './diagnostics/video-input-test/local-video-preview/local-video-preview.component';

@NgModule({
  imports: [SharedModule, DashboardRoutingModule],
  declarations: [
    MenuComponent,
    UtilitiesComponent,
    ProfileComponent,
    ThemesComponent,
    PincodeComponent,
    SettingsComponent,
    RequestCallComponent,
    MediaLibraryComponent,
    MessageHistoryComponent,
    AccountDetailComponent,
    MessageHistoryImageViewerComponent,
    MessageHistoryVideoViewerComponent,
    VideoInputTestComponent,
    DiagnosticsComponent,
    AudioInputTestComponent,
    AudioOutputTestComponent,
    MediaConnectionBitrateTestComponent,
    AboutComponent,
    LocalVideoPreviewComponent,
  ],
})
export class DashboardModule {}
