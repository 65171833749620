import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {
  connect,
  ConnectOptions,
  createLocalVideoTrack,
  LocalAudioTrackPublication,
  LocalVideoTrackPublication,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video';
import { LoggerService } from '../../../core/services/logger.service';
import { TwilioVideoService } from 'src/app/core/services/twilio-video.service';
import { ExternalInput, Nullable } from 'src/app/core/types/types';
import { Store } from '@ngrx/store';
import { State } from 'src/app/state/root.reducer';
import {
  selectCallAccepted,
  selectCallDeclined,
  selectCallEnded,
} from 'src/app/state/conferencing.selectors';
import { CallOptions, CallType } from 'src/app/state/conferencing.reducer';
import { BaseUser } from 'src/app/core/models/base-user.model';
import { take } from 'rxjs/operators';
import {
  selectCurrentUser,
  selectIsChurch,
} from 'src/app/state/user.selectors';
import * as UserActions from '../../../state/user.actions';
import * as UserSelectors from '../../../state/user.selectors';
import * as ConferencingActions from '../../../state/conferencing.actions';
import { IdcapService } from '../../../core/services/idcap.service';

declare var hcap: any;

@Component({
  selector: 'app-twilio-call-viewer',
  templateUrl: './twilio-call-viewer.component.html',
  styleUrls: ['./twilio-call-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TwilioCallViewerComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private callAccepted$: Observable<boolean>;
  private callDeclined$: Observable<boolean>;
  private callEnded$: Observable<boolean>;

  private isChurch: boolean;
  private callAcceptedSubscription: Subscription;
  private callDeclinedSubscription: Subscription;
  private callEndedSubscription: Subscription;

  private isIdcap$: Observable<boolean>;
  private isIdcap: boolean;
  private isIdcapSubscription: Subscription;

  private translateStrings: any;

  private room: Room;

  callInProgress = false;
  showVideo = false;
  statusMessage: string;
  initiator: any;
  target: any;
  sessionId: string;
  localVideoEnabled = true;
  localAudioEnabled = true;

  @Input() callType: CallType;
  @Input() remoteUser: BaseUser;
  @Input() inboundCallOptions: CallOptions;
  @Input() outboundCallOptions: CallOptions;

  currentAvInput: ExternalInput;
  currentAvInputIndex: number;

  audioVideoChoices = [
    { id: 0, label: 'Video Call' },
    { id: 1, label: 'Audio Call' },
    { id: 2, label: 'Cancel' },
  ];
  callInProgressChoices = [
    { id: 0, tag: 'end_call', label: '', icon: 'cm:icon-x-circle' },
    { id: 1, tag: 'mute_video', label: '', icon: 'cm:icon-video' },
    {
      id: 2,
      tag: 'mute_audio',
      label: '',
      icon: 'cm:icon-mic',
    },
  ];

  @ViewChild('callWaitingSound') callWaitingSound: ElementRef;
  @ViewChild('callAcceptedSound') callAcceptedSound: ElementRef;
  @ViewChild('callDeclinedSound') callDeclinedSound: ElementRef;
  @ViewChild('remoteMediaContainer') remoteMediaContainer: ElementRef;
  @ViewChild('localMediaContainer') localMediaContainer: ElementRef;
  @ViewChild('remoteMediaMessage') remoteMediaMessage: ElementRef;

  constructor(
    private logger: LoggerService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private idcapService: IdcapService,
    private twilioVideo: TwilioVideoService,
    private store$: Store<State>
  ) {}

  ngOnInit(): void {
    this.callAccepted$ = this.store$.select(selectCallAccepted);
    this.callDeclined$ = this.store$.select(selectCallDeclined);
    this.callEnded$ = this.store$.select(selectCallEnded);

    this.isIdcapSubscription = this.store$
      .select(UserSelectors.selectIsIdcap)
      .subscribe((isIdcap) => {
        this.isIdcap = isIdcap;
      });

    this.store$
      .select(selectIsChurch)
      .pipe(take(1))
      .subscribe((isChurch) => (this.isChurch = isChurch));

    // this.route.params.forEach((params: Params) => {
    //   this.callType = params['callType'];
    //   if (this.callType === CallType.INBOUND) {
    //     this.callInProgress = true;
    //     this.showVideo = true;
    //   }
    // });

    if (this.callType === CallType.INBOUND) {
      this.callInProgress = true;
      this.showVideo = true;
      this.setup();
    }

    if (this.callType === CallType.OUTBOUND) {
      this.target = this.remoteUser;
    }
    if (this.callType === CallType.INBOUND) {
      this.initiator = this.remoteUser;
    }

    let currentUser: BaseUser;
    this.store$
      .select(selectCurrentUser)
      .pipe(take(1))
      .subscribe((u: BaseUser) => (currentUser = u));
    console.log(`USER FROM STORE: ${currentUser.username}`);

    this.outboundCallOptions = {
      audioOnly: false,
      initiator: currentUser,
      target: this.remoteUser,
      roomName: `${currentUser.username}-to-${this.remoteUser.username}`,
    };

    this.callAcceptedSubscription = this.callAccepted$.subscribe(
      (accepted: boolean) => {
        if (accepted) {
          this.callAccepted();
        }
      }
    );

    this.callDeclinedSubscription = this.callDeclined$.subscribe(
      (declined: boolean) => {
        if (declined) {
          this.callDeclined();
        }
      }
    );

    this.callEndedSubscription = this.callEnded$.subscribe((ended: boolean) => {
      if (ended) {
        this.callEnded();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.callType === CallType.INBOUND) {
      this.joinCall(this.inboundCallOptions);
    } else {
      this.joinCall(this.outboundCallOptions);
    }
  }

  ngOnDestroy(): void {
    this.callAcceptedSubscription.unsubscribe();
    this.callDeclinedSubscription.unsubscribe();
    this.callEndedSubscription.unsubscribe();
    this.isIdcapSubscription.unsubscribe();
  }

  createId(): string {
    return Math.random().toString(16).substr(2);
  }

  onAudioVideoSelected(choice): void {
    switch (choice.id) {
      case 0: // video
        this.requestVideoCall();
        break;
      case 1: // audio
        this.requestAudioCall();
        break;
      case 2: // cancel
        this.finishCall();
        break;
      default:
        this.finishCall();
        break;
    }
  }

  onCallInProgressChoiceSelected(choice): void {
    switch (choice.tag) {
      case 'mute_video':
        this.toggleLocalVideoMuted();
        break;
      case 'mute_audio':
        this.toggleLocalAudioMuted();
        break;
      case 'end_call':
        this.endCall();
        break;
    }
  }

  requestAudioCall(): void {
    this.requestCall(true);
  }

  requestVideoCall(): void {
    this.requestCall(false);
  }

  requestCall(audioOnly: boolean): void {
    this.outboundCallOptions.audioOnly = audioOnly;
    this.callInProgress = true;
    this.statusMessage = 'Calling...';
    this.store$.dispatch(
      ConferencingActions.requestCall({ callOptions: this.outboundCallOptions })
    );
    this.playWaitingSound();
  }

  playWaitingSound(): void {
    const audio =
      this.callWaitingSound !== undefined
        ? (this.callWaitingSound.nativeElement as HTMLAudioElement)
        : undefined;
    if (audio !== undefined) {
      audio
        .play()
        .then((value) => console.log('playing waiting sound'))
        .catch((error) => console.error('failed to play waiting sound'));
    }
  }

  stopWaitingSound(): void {
    const audio =
      this.callWaitingSound !== undefined
        ? (this.callWaitingSound.nativeElement as HTMLAudioElement)
        : undefined;
    if (audio !== undefined) {
      audio.pause();
      this.renderer.setProperty(audio, 'currentTime', 0);
    }
  }

  playAcceptedSound(): void {
    const audio =
      this.callAcceptedSound !== undefined
        ? (this.callAcceptedSound.nativeElement as HTMLAudioElement)
        : undefined;
    if (audio !== undefined) {
      audio
        .play()
        .then((value) => console.log('playing call accepted sound'))
        .catch((error) => console.error('failed to call accepted sound'));
    }
  }

  stopAcceptedSound(): void {
    const audio =
      this.callAcceptedSound !== undefined
        ? (this.callAcceptedSound.nativeElement as HTMLAudioElement)
        : undefined;
    if (audio !== undefined) {
      audio.pause();
      this.renderer.setProperty(audio, 'currentTime', 0);
    }
  }

  playDeclinedSound(): void {
    const audio =
      this.callDeclinedSound !== undefined
        ? (this.callDeclinedSound.nativeElement as HTMLAudioElement)
        : undefined;
    if (audio !== undefined) {
      audio
        .play()
        .then((value) => console.log('playing call declined sound'))
        .catch((error) => console.error('failed to call declined sound'));
    }
  }

  stopDeclinedSound(): void {
    const audio =
      this.callDeclinedSound !== undefined
        ? (this.callDeclinedSound.nativeElement as HTMLAudioElement)
        : undefined;
    if (audio !== undefined) {
      audio.pause();
      this.renderer.setProperty(audio, 'currentTime', 0);
    }
  }

  async callAccepted(): Promise<void> {
    this.stopWaitingSound();
    this.playAcceptedSound();
    this.logger.info(`call-viewer: Call Accepted`);
    this.statusMessage = 'Call Accepted!';
    this.showVideo = true;
    await this.setup();
    await this.joinCall(this.outboundCallOptions);
  }

  callDeclined(): void {
    this.stopWaitingSound();
    this.playDeclinedSound();
    this.restoreCurrentAvInput();
    this.logger.info(`call-viewer : Call Declined`);
    this.statusMessage = 'Call Declined!';
    this.callInProgress = false;
    this.store$.dispatch(ConferencingActions.resetState());
  }

  async endCall(): Promise<void> {
    this.stopWaitingSound();
    this.playDeclinedSound();
    this.store$.dispatch(
      ConferencingActions.endCall({ callType: this.callType })
    );
    this.logger.info(`call-viewer : Ending call`);
    this.statusMessage = 'Call Ended';
    this.finishCall();
    this.store$.dispatch(ConferencingActions.resetState());
    this.store$.dispatch(UserActions.navigateToHome());
    await this.restoreCurrentAvInput();
  }

  async callEnded(callOptions?: CallOptions): Promise<void> {
    this.stopWaitingSound();
    this.playDeclinedSound();
    this.logger.info(`call-viewer: Call ended`);
    this.statusMessage = 'Peer Ended Call';
    this.finishCall();
    this.store$.dispatch(ConferencingActions.resetState());
    this.store$.dispatch(UserActions.navigateToHome());
    await this.restoreCurrentAvInput();
  }

  finishCall(): void {
    this.stopLocalMedia();
    this.room.disconnect();
  }

  toggleLocalVideoMuted(): void {
    this.localVideoEnabled = !this.localVideoEnabled;
    if (this.localVideoEnabled) {
      this.callInProgressChoices[1].label = '';
      this.callInProgressChoices[1].icon = 'cm:icon-video';
      this.room.localParticipant.videoTracks.forEach(
        (publication: LocalVideoTrackPublication) => publication.track.enable()
      );
    } else {
      this.callInProgressChoices[1].label = '';
      this.callInProgressChoices[1].icon = 'cm:icon-video-off';
      this.room.localParticipant.videoTracks.forEach(
        (publication: LocalVideoTrackPublication) => publication.track.disable()
      );
    }
  }

  toggleLocalAudioMuted(): void {
    this.localAudioEnabled = !this.localAudioEnabled;
    if (this.localAudioEnabled) {
      this.callInProgressChoices[2].label = '';
      this.callInProgressChoices[2].icon = 'cm:icon-mic';
      this.room.localParticipant.audioTracks.forEach(
        (publication: LocalAudioTrackPublication) => publication.track.enable()
      );
    } else {
      this.callInProgressChoices[2].label = '';
      this.callInProgressChoices[2].icon = 'cm:icon-mic-off';
      this.room.localParticipant.audioTracks.forEach(
        (publication: LocalAudioTrackPublication) => publication.track.disable()
      );
    }
  }

  async storeCurrentAvInput(): Promise<void> {
    if (this.isIdcap) {
      try {
        const current =
          (await this.idcapService.getCurrentExternalInput()) as any;
        this.logger.info(
          `twilio-call-viewer : current input : ${JSON.stringify(current)}`
        );
        this.currentAvInput = current.type;
        this.currentAvInputIndex = current.index;
      } catch (e) {
        this.logger.error(
          `twilio-call-viewer : storeCurrentAvInput :  ${JSON.stringify(e)}`
        );
      }
    } else {
      console.warn(`twilio-call-viewer : storeCurrentAvInput : not hcap`);
    }
  }

  async restoreCurrentAvInput(): Promise<void> {
    if (this.isIdcap) {
      // await this.hcapService.setVideoMute(false);
      await this.idcapService.resetAV();
      // try {
      //   if (this.currentAvInput === 6) {
      //     // was HDMI
      //     const inputs = (await this.hcapService.getExternalInputList()) as any;
      //     const hdmiInputs = inputs.list.filter((input) => input.type === 6);
      //     this.logger.info(`hdmi inputs: ${JSON.stringify(hdmiInputs)}`);
      //     const hdmiIndexes = hdmiInputs.map((hdmi) => hdmi.index);
      //     this.logger.info(`hdmi indexes: ${JSON.stringify(hdmiIndexes[0])}`);
      //     const availableIndexes = hdmiIndexes.filter(
      //       (idx) => idx !== this.currentAvInputIndex
      //     );
      //     this.logger.info(
      //       `available indexes: ${JSON.stringify(availableIndexes)}`
      //     );
      //     if (availableIndexes.length > 0) {
      //       await this.hcapService.setCurrentExternalInput(
      //         6,
      //         availableIndexes[0]
      //       );
      //     }
      //     setTimeout(async () => {
      //       await this.hcapService.setCurrentExternalInput(
      //         this.currentAvInput,
      //         this.currentAvInputIndex
      //       );
      //     }, 500);
      //   }
      //   if (this.currentAvInput === 1) {
      //     // Was DVB-T
      //     await this.hcapService.setCurrentExternalInput(6, 0);
      //     setTimeout(
      //       async () =>
      //         await this.hcapService.setCurrentExternalInput(
      //           this.currentAvInput,
      //           this.currentAvInputIndex
      //         ),
      //       1000
      //     );
      //   }
      // } catch (e) {
      //   this.logger.error(
      //     `twilio-call-viewer : RestoreCurrentAvInput : ${JSON.stringify(e)}`
      //   );
      // }
    } else {
      console.warn(`twilio-call-viewer : restoreCurrentAvInput : not hcap`);
    }
  }

  async setVideoMute(mute: boolean): Promise<void> {
    if (this.isIdcap) {
      await this.idcapService.setVideoMute(mute);
    } else {
      console.warn(`twilio-call-viewer : setVideoMute : not hcap`);
    }
  }

  // Twilio Video

  async setup(): Promise<void> {
    // await this.setVideoMute(true);
    // await this.storeCurrentAvInput();
    navigator.mediaDevices.enumerateDevices().then(async (devices) => {
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      console.info('video devices: ', JSON.stringify(videoDevices));
      const camera = videoDevices[0];
      console.info(`using camera: ${camera.label} - ${camera.deviceId}`);

      const localVideoTrack = await createLocalVideoTrack({
        height: 720,
        width: 1280,
        frameRate: 24,
        deviceId: camera.deviceId,
      });
      this.localMediaContainer.nativeElement.appendChild(
        localVideoTrack.attach()
      );
    });
  }

  /**
   * join call
   */
  async joinCall(callOptions: CallOptions): Promise<void> {
    console.info(`room name: ${callOptions.roomName}`);
    const token: any = await this.twilioVideo.getVideoAccessToken(
      callOptions.roomName
    );
    console.info(`token: ${JSON.stringify(token)}`);

    const options: ConnectOptions = {
      audio: true,
      video: { height: 720, width: 1280, frameRate: 24 },
      name: callOptions.roomName,
      maxAudioBitrate: 16000,
    };
    this.room = await connect(token.token, options);

    // Attach the remote tracks of participants already in the room.
    this.room.participants.forEach((participant) =>
      this.manageTracksForRemoteParticipant(participant)
    );

    // Wire-up event handlers.
    this.room.on(
      'participantConnected',
      this.onParticipantConnected.bind(this)
    );
    this.room.on(
      'participantDisconnected',
      this.onParticipantDisconnected.bind(this)
    );

    // navigator.mediaDevices.enumerateDevices().then(async (devices) => {
    //   const videoDevices = devices.filter(
    //     (device) => device.kind === 'videoinput'
    //   );
    //   console.info('video devices: ', JSON.stringify(videoDevices));
    //   const camera = videoDevices[0];
    //   console.info(`using camera: ${camera.label} - ${camera.deviceId}`);
    //   const options: ConnectOptions = {
    //     audio: true,
    //     video: {
    //       height: 720,
    //       width: 1280,
    //       frameRate: 24,
    //       deviceId: camera.deviceId,
    //     },
    //     name: callOptions.roomName,
    //     maxAudioBitrate: 16000,
    //   };
    //   this.room = await connect(token.token, options);
    //
    //   // Attach the remote tracks of participants already in the room.
    //   this.room.participants.forEach((participant) =>
    //     this.manageTracksForRemoteParticipant(participant)
    //   );
    //
    //   // Wire-up event handlers.
    //   this.room.on(
    //     'participantConnected',
    //     this.onParticipantConnected.bind(this)
    //   );
    //   this.room.on(
    //     'participantDisconnected',
    //     this.onParticipantDisconnected.bind(this)
    //   );
    // });
  }

  /**
   * release local media
   */
  stopLocalMedia(): void {
    if (this.room) {
      this.room.localParticipant.videoTracks.forEach(
        (publication: LocalVideoTrackPublication) => {
          publication.unpublish();
          publication.track.stop();
        }
      );

      this.room.localParticipant.audioTracks.forEach((publication) => {
        publication.unpublish();
        publication.track.stop();
      });
    }
  }

  /**
   * Attaches all attachable published tracks from the remote participant.
   *
   * @param publications
   * The list of possible publications to attach.
   */
  attachAttachableTracksForRemoteParticipant(
    participant: RemoteParticipant
  ): void {
    participant.tracks.forEach((publication) => {
      if (!publication.isSubscribed) {
        return;
      }

      if (!this.trackExistsAndIsAttachable(publication.track)) {
        return;
      }

      this.attachTrack(publication.track);
    });
  }

  /**
   * Attaches a remote track.
   *
   * @param track
   * The remote track to attach.
   */
  async attachTrack(track: RemoteAudioTrack | RemoteVideoTrack): Promise<void> {
    if (
      this.remoteMediaContainer !== undefined &&
      this.remoteMediaContainer !== null &&
      this.remoteMediaContainer.nativeElement !== undefined &&
      this.remoteMediaContainer.nativeElement !== null
    ) {
      this.remoteMediaContainer.nativeElement.appendChild(track.attach());

      if (this.remoteMediaMessage !== undefined) {
        this.remoteMediaMessage.nativeElement.style.display = 'none';
      }
    }
  }

  /**
   * Guard that a track is attachable.
   *
   * @param track
   * The remote track candidate.
   */
  trackExistsAndIsAttachable(
    track?: Nullable<RemoteTrack>
  ): track is RemoteAudioTrack | RemoteVideoTrack {
    return (
      !!track &&
      ((track as RemoteAudioTrack).attach !== undefined ||
        (track as RemoteVideoTrack).attach !== undefined)
    );
  }

  /**
   * Triggers when a remote participant connects to the room.
   *
   * @param participant
   * The remote participant
   */
  onParticipantConnected(participant: RemoteParticipant): void {
    this.manageTracksForRemoteParticipant(participant);
  }

  /**
   * Triggers when a remote participant disconnects from the room.
   *
   * @param participant
   * The remote participant
   */
  onParticipantDisconnected(participant: RemoteParticipant): void {
    document.getElementById(participant.sid)?.remove();
  }

  /**
   * Triggers when a remote track is subscribed to.
   *
   * @param track
   * The remote track
   */
  onTrackSubscribed(track: RemoteTrack): void {
    if (!this.trackExistsAndIsAttachable(track)) {
      return;
    }

    this.attachTrack(track);
  }

  /**
   * Triggers when a remote track is unsubscribed from.
   *
   * @param track
   * The remote track
   */
  onTrackUnsubscribed(track: RemoteTrack): void {
    if (this.trackExistsAndIsAttachable(track)) {
      track.detach().forEach((element) => element.remove());
    }
  }

  /**
   * Manages track attachment and subscription for a remote participant.
   *
   * @param participant
   * The remote participant
   */
  manageTracksForRemoteParticipant(participant: RemoteParticipant): void {
    // Handle tracks that this participant has already published.
    this.attachAttachableTracksForRemoteParticipant(participant);

    // Handles tracks that this participant eventually publishes.
    participant.on('trackSubscribed', this.onTrackSubscribed.bind(this));
    participant.on('trackUnsubscribed', this.onTrackUnsubscribed.bind(this));
  }
}
