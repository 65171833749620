import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConferencingModule } from './conferencing/conferencing.module';
import { SupervisorModule } from './supervisor/supervisor.module';
import { SetupModule } from './setup/setup.module';
import { ViewersModule } from './viewers/viewers.module';
import { AssistanceModule } from './assistance/assistance.module';
import { DashboardModule } from './dashboard/dashboard.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'supervisor/listener',
    pathMatch: 'full',
  },
  // {
  //   path: 'setup',
  //   loadChildren: () =>
  //     import('./setup/setup.module').then((m) => m.SetupModule),
  // },
  {
    path: 'setup',
    loadChildren: () => SetupModule,
  },
  // {
  //   path: 'supervisor',
  //   loadChildren: () =>
  //     import('./supervisor/supervisor.module').then((m) => m.SupervisorModule),
  // },
  {
    path: 'supervisor',
    loadChildren: () => SupervisorModule,
  },
  // {
  //   path: 'conferencing',
  //   loadChildren: () =>
  //     import('./conferencing/conferencing.module').then(
  //       (m) => m.ConferencingModule
  //     ),
  // },
  {
    path: 'conferencing',
    loadChildren: () => ConferencingModule,
  },
  // {
  //   path: 'viewers',
  //   loadChildren: () =>
  //     import('./viewers/viewers.module').then((m) => m.ViewersModule),
  // },
  {
    path: 'viewers',
    loadChildren: () => ViewersModule,
  },
  //   {
  //     path: 'power',
  //     loadChildren: () =>
  //       import('./power/power.module').then((m) => m.PowerModule),
  //   },
  //   {
  //     path: 'av-source',
  //     loadChildren: () =>
  //       import('./av-source/av-source.module').then((m) => m.AvSourceModule),
  //   },
  // {
  //   path: 'assistance',
  //   loadChildren: () =>
  //     import('./assistance/assistance.module').then((m) => m.AssistanceModule),
  // },
  {
    path: 'assistance',
    loadChildren: () => AssistanceModule,
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () =>
  //     import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  // },
  {
    path: 'dashboard',
    loadChildren: () => DashboardModule,
  },
  {
    path: 'church',
    loadChildren: () =>
      import('./church/church.module').then((m) => m.ChurchModule),
  },
  {
    path: 'doro',
    loadChildren: () => import('./doro/doro.module').then((m) => m.DoroModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
