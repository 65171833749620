import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  CallType,
  CallOptions,
  ConferencingState,
} from 'src/app/state/conferencing.reducer';
import { selectInboundCallOptions } from 'src/app/state/conferencing.selectors';

@Component({
  selector: 'cm-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
})
export class CallComponent implements OnInit {
  constructor(private store$: Store<ConferencingState>) {}

  callType: CallType = CallType.INBOUND;
  inboundCallOptions$: Observable<CallOptions>;

  ngOnInit(): void {
    // const callOptions: CallOptions = {
    //   roomName: 'roger-to-test',
    //   audioOnly: false,
    //   initiator: {},
    //   target: {},
    //   declineReason: '',
    // };
    // this.inboundCallOptions$ = of(callOptions);
    this.inboundCallOptions$ = this.store$.select(selectInboundCallOptions);
  }
}
