<div class="call-viewer-container">
  <div class="dial-container" *ngIf="!showVideo">
    <h3 class="status-message">{{ statusMessage }}</h3>

    <cm-avatar-thumb
      [user]="remoteUser"
      size="240"
      [hidePresence]="true"
    ></cm-avatar-thumb>
    <div class="user-label">
      <h3>
        {{ remoteUser.first_name }}
        {{ remoteUser.last_name }} ({{ remoteUser.username }})
      </h3>
    </div>

    <div class="call-buttons">
      <cm-choice-selector
        [choices]="audioVideoChoices"
        (choiceSelected)="onAudioVideoSelected($event)"
        [columns]="3"
        [choiceWidth]="300"
        *ngIf="!callInProgress"
      ></cm-choice-selector>
      <cm-choice-selector
        [choices]="[{ id: 1, label: 'End Call' }]"
        (choiceSelected)="endCall()"
        [columns]="1"
        [choiceWidth]="200"
        *ngIf="callInProgress"
      ></cm-choice-selector>
    </div>
  </div>

  <div class="video-container" *ngIf="callInProgress && showVideo">
    <div #remoteMediaMessage class="remote-media-message">
      <mat-icon svgIcon="cm:icon-video"></mat-icon>
      <h1>{{ 'CONFERENCING_STARTING_CALL_HEADER' | translate }}</h1>
      <h2>{{ 'CONFERENCING_STARTING_CALL_SUB_HEADER' | translate }}</h2>
    </div>
    <div #remoteMediaContainer id="remote-media-container"></div>
    <div
      #localMediaContainer
      class="local-media-container"
      [ngClass]="{ muted: !localVideoEnabled }"
    ></div>
    <div class="call-controls">
      <cm-choice-selector
        [choices]="callInProgressChoices"
        (choiceSelected)="onCallInProgressChoiceSelected($event)"
        [columns]="3"
        [choiceWidth]="150"
        [showIcon]="true"
        [iconWidth]="48"
        [iconHeight]="48"
        *ngIf="callInProgress"
      ></cm-choice-selector>
    </div>
  </div>
</div>

<audio #callWaitingSound loop="true">
  <source src="assets/sounds/on-hold-ringtone.wav" type="audio/wav" />
</audio>
<audio #callAcceptedSound>
  <source src="assets/sounds/bleep.mp3" type="audio/mp3" />
  <source src="assets/sounds/bleep.wav" type="audio/wav" />
</audio>
<audio #callDeclinedSound>
  <source src="assets/sounds/pling.wav" type="audio/wav" />
</audio>
