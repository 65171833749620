import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Resident } from 'src/app/core/models/resident.model';
import { ASCII_REMOTE_KEYS } from 'src/app/core/services/remote-control.service';
import { UserState } from 'src/app/state/user.reducer';
import { selectCurrentUser } from 'src/app/state/user.selectors';
import { IdcapService } from '../../../core/services/idcap.service';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss'],
})
export class AccountDetailComponent implements OnInit {
  resident$: Observable<Resident>;
  procentricConfig: any;
  ethernetConfig: any;
  modelName: string;
  platformVersion: string;
  serialNumber: string;

  constructor(
    private router: Router,
    private idcapService: IdcapService,
    private store$: Store<UserState>
  ) {}

  ngOnInit(): void {
    this.resident$ = this.store$.select(selectCurrentUser);

    this.idcapService
      .getProcentricServer()
      .then((config) => (this.procentricConfig = config));

    this.idcapService.getNetworkInformation().then((ethernetConfig) => {
      console.info(`networkConfig: ${JSON.stringify(ethernetConfig)}`);
      this.ethernetConfig = ethernetConfig;
    });
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToProfile();
    }
  }

  goToProfile(): void {
    this.router.navigate(['dashboard/profile']);
  }
}
