import { RemoteControlService, REMOTE_KEY_SETS } from './../services/remote-control.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


@Injectable()
export class RemoteControlGuard implements CanActivate {

    constructor(
        private router: Router,
        private remoteControl: RemoteControlService
    )
    {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let keySet = route.data['keySet'];
        if (keySet && keySet in REMOTE_KEY_SETS) {
            this.remoteControl.setActiveBitmask(keySet);
            return true;
        } else {
            console.error('Cannot activate route as remote control key set was not set or invalid');
            return false;
        }
    }
}
