import { createReducer, on } from '@ngrx/store';
import { Customer } from '../core/models/customer.model';
import { Resident } from '../core/models/resident.model';
import * as UserActions from './user.actions';
import * as RootActions from './root.actions';
import { environment } from '../../environments/environment';
import * as StbActions from './stb.actions';

export const userFeatureKey = 'profile';

export interface Credentials {
  macAddress: string;
}

export interface LoginResponse {
  user: any;
  token: string;
}

export interface UserState {
  showDebug: boolean;
  isHcap: boolean;
  isIdcap: boolean;
  user: Resident;
  token: string;
  tokenRefreshInterval: number;
  tokenRefreshFailureMessage: string;
  pusherStatus: string;
  macAddress: string;
  pinCode: string;
  authenticated: boolean;
  failureMessage: string;
  macAddressErrorMessage: string;
  customer: Customer;
}

const initialState: UserState = {
  showDebug: false,
  isHcap: false,
  isIdcap: false,
  user: undefined,
  token: '',
  tokenRefreshInterval: 360000,
  tokenRefreshFailureMessage: '',
  pusherStatus: 'disconnected',
  macAddress: '',
  pinCode: '',
  authenticated: false,
  failureMessage: '',
  macAddressErrorMessage: '',
  customer: undefined,
};

export const userReducer = createReducer<UserState>(
  initialState,
  on(UserActions.getMacAddressSuccess, (state, action) => {
    return { ...state, macAddress: action.macAddress };
  }),
  on(UserActions.getMacAddressFailure, (state, action) => ({
    ...state,
    macAddressErrorMessage: action.message,
  })),
  on(UserActions.loginSuccess, (state, loginResponse: LoginResponse) => ({
    ...state,
    authenticated: true,
    user: loginResponse.user,
    token: loginResponse.token,
    failureMessage: '',
  })),
  on(UserActions.loginFailure, (state) => ({
    ...state,
    authenticated: false,
    failureMessage: 'Unknown MAC Address',
  })),
  on(UserActions.refreshTokenSuccess, (state, action) => {
    return { ...state, token: action.token };
  }),
  on(UserActions.refreshTokenFailure, (state, action) => {
    return { ...state, tokenRefreshFailureMessage: action.error };
  }),
  on(UserActions.getCustomerSuccess, (state, customer: Customer) => ({
    ...state,
    customer,
  })),
  on(UserActions.getCustomerFailure, (state, action) => ({
    ...state,
    failureMessage: 'Failed to get customer',
  })),
  on(UserActions.getPinCodeSuccess, (state, action) => ({
    ...state,
    pinCode: action.response.pin_code,
  })),
  on(UserActions.getPinCodeFailure, (state, action) => ({
    ...state,
    failureMessage: action.message,
  })),
  on(UserActions.setDeliverToInboxSuccess, (state, resident: Resident) => ({
    ...state,
    user: { ...state.user, deliver_to_inbox: resident.deliver_to_inbox },
  })),
  on(UserActions.setDeliverToInboxFailure, (state, action) => ({
    ...state,
    failureMessage: action.message,
  })),
  on(RootActions.updatePusherStatus, (state, action) => ({
    ...state,
    pusherStatus: action.status,
  })),
  on(UserActions.toggleShowDebug, (state, action) => ({
    ...state,
    showDebug: !state.showDebug,
  })),
  on(UserActions.updateCustomer, (state, action) => ({
    ...state,
    customer: action.customer,
  })),
  on(UserActions.updateResident, (state, action) => ({
    ...state,
    user: state.user.id === action.resident.id ? action.resident : state.user,
  })),
  on(UserActions.updateResident, (state, action) => ({
    ...state,
    user: state.user.id === action.resident.id ? action.resident : state.user,
  })),
  on(UserActions.setIsHcap, (state, action) => ({
    ...state,
    isHcap: action.isHcap,
  })),
  on(UserActions.setIsIdcap, (state, action) => ({
    ...state,
    isIdcap: action.isIdcap,
  })),
  on(StbActions.patchSetTopBoxSuccess, (state, action) => ({
    ...state,
    user: { ...state.user, set_top_box: action.setTopBox },
  }))
);
