import { Injectable } from '@angular/core';

import { Observable, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Resident } from '../models/resident.model';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ResidentService {
  endpointUrl: string = environment.apiUrl + '/resident';

  constructor(private http: HttpClient) {}

  getResident(residentId: number): Observable<Resident> {
    let url = `${this.endpointUrl}/${residentId}`;
    return this.http.get<Resident>(url);
  }

  updateResident(resident: Resident) {
    let url = `${this.endpointUrl}/${resident.id}`;
    return this.http
      .put(url, resident)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getPinCode(residentId: number) {
    let url = `${this.endpointUrl}/${residentId}/get_pin_code`;
    return this.http.get(url);
    pipe(map((data: any) => data.pin_code));
  }

  setDeliverToInbox(residentId: number, deliverToInbox: boolean) {
    let url = `${this.endpointUrl}/${residentId}/set_deliver_to_inbox`;
    let payload = { deliver_to_inbox: deliverToInbox };
    return this.http.post(url, payload);
  }

  private extractData(response: Response) {
    let body = response.json() as any;
    return body.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
