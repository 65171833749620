<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'UTILITIES_ABOUT_TITLE' | translate }}"
>
  <cm-about-card
    [modelName]="modelName"
    [platformVersion]="platformVersion"
    [serialNumber]="serialNumber"
    [macAddress]="macAddress"
    [ipAddress]="ipAddress"
    [procentricServer]="procentricServer"
  >
  </cm-about-card>
</cm-overlay>
