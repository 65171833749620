import { createAction, props } from '@ngrx/store';
import { Customer } from '../core/models/customer.model';
import { Resident } from '../core/models/resident.model';
import { Credentials, LoginResponse } from './user.reducer';

export const getMacAddress = createAction('[User] Get MAC Address');

export const getMacAddressSuccess = createAction(
  '[User] Get MAC Address Success',
  props<{ macAddress: string }>()
);

export const getMacAddressFailure = createAction(
  '[User] Get MAC Address Failure',
  props<Error>()
);

export const login = createAction('[User] Login', props<Credentials>());

export const loginSuccess = createAction(
  '[User] Login Success',
  props<LoginResponse>()
);

export const loginFailure = createAction(
  '[User] Login Failure',
  props<{ error: any }>()
);

export const logout = createAction('[User] Logout');

export const loginRedirect = createAction('[User] Login Redirect');

export const getCustomer = createAction(
  '[User] Get Customer',
  props<{ customerId: number }>()
);

export const getCustomerSuccess = createAction(
  '[User] Get Customer Success',
  props<Customer>()
);

export const updateCustomer = createAction(
  '[User] Update Customer',
  props<{ customer: Customer }>()
);

export const updateResident = createAction(
  '[User] Update Resident',
  props<{ resident: Resident }>()
);

export const getCustomerFailure = createAction(
  '[User] Get Customer Failure',
  props<{ error: any }>()
);

export const getPinCode = createAction('[User] Get Pin Code');

export const getPinCodeSuccess = createAction(
  '[User] Get Pin Code Success',
  props<{ response: { pin_code: string } }>()
);

export const getPinCodeFailure = createAction(
  '[User] Get Pin Code Failure',
  props<Error>()
);

export const setDeliverToInbox = createAction('[User] Set Deliver To Inbox');

export const setDeliverToInboxSuccess = createAction(
  '[User] Set Deliver To Inbox Success',
  props<Resident>()
);

export const setDeliverToInboxFailure = createAction(
  '[User] Set Deliver To Inbox Failure',
  props<Error>()
);

export const navigateToHome = createAction('[User] Navigate To Home');

export const refreshToken = createAction('[User] Refresh Token');

export const refreshTokenSuccess = createAction(
  '[User] Refresh Token Success',
  props<{ token: string }>()
);

export const refreshTokenFailure = createAction(
  '[User] Refresh Token Failure',
  props<{ error: any }>()
);

export const toggleShowDebug = createAction('[User] Toggle Show Debug');

export const setIsHcap = createAction(
  '[User] Set is HCAP',
  props<{ isHcap: boolean }>()
);

export const setIsIdcap = createAction(
  '[User] Set is IDCAP',
  props<{ isIdcap: boolean }>()
);
