import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserState } from '../../../state/user.reducer';
import * as UserSelectors from '../../../state/user.selectors';
import * as ConferencingSelectors from '../../../state/conferencing.selectors';
import { ConferencingSubscription } from '../../../state/conferencing.reducer';

@Component({
  selector: 'cm-conferencing-subscription-indicator',
  templateUrl: './conferencing-subscription-indicator.component.html',
  styleUrls: ['./conferencing-subscription-indicator.component.scss'],
})
export class ConferencingSubscriptionIndicatorComponent implements OnInit {
  showDebug$: Observable<boolean>;
  conferencingSubscription$: Observable<ConferencingSubscription>;

  constructor(private store$: Store<UserState>) {}

  ngOnInit(): void {
    this.conferencingSubscription$ = this.store$.select(
      ConferencingSelectors.selectConferencingSubscription
    );
    this.showDebug$ = this.store$.select(UserSelectors.selectShowDebug);
  }
}
