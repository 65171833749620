<div
  class="message-set-wrapper"
  [ngClass]="{
    center:
      currentMessage.images.length === 0 && currentMessage.videos.length === 0
  }"
>
  <div
    class="thumbnail-container"
    *ngIf="currentMessage.images.length > 0 || currentMessage.videos.length > 0"
  >
    <div class="image-thumbnail" *ngIf="currentMessage.images.length > 0">
      <img [src]="currentMessage.images[0].large_url" />
    </div>
    <div class="video-thumbnail" *ngIf="currentMessage.videos.length > 0">
      <img
        [src]="currentMessage.videos[0].encodings[0].thumbnails[0].file"
        *ngIf="currentMessage.videos[0].encodings.length > 0"
      />
      <svg
        class="icon grid"
        viewBox="0 0 32 32"
        *ngIf="currentMessage.videos[0].encodings.length == 0"
      >
        <use xlink:href="#icon-video"></use>
      </svg>
    </div>
    <div class="key">
      <div class="zoom-icon"></div>
      <span>{{ currentMessage.images.length > 0 ? "zoom" : "play" }}</span>
    </div>
  </div>

  <div class="message-content">
    <div class="subject">
      <h2 *ngIf="currentMessage.images[0] || currentMessage.videos[0]">
        {{ currentMessage.subject | truncate: 40 }}
      </h2>
      <h2 *ngIf="!currentMessage.images[0] && !currentMessage.videos[0]">
        {{ currentMessage.subject | truncate: 55 }}
      </h2>
    </div>
    <div class="body">
      <p *ngIf="currentMessage.images[0] || currentMessage.videos[0]">
        {{ currentMessage.body | truncate: 180 }}
      </p>
      <p *ngIf="!currentMessage.images[0] && !currentMessage.videos[0]">
        {{ currentMessage.body | truncate: 235 }}
      </p>
    </div>
  </div>
</div>
<div class="choices-wrapper">
  <cm-choice-selector
    [choices]="currentMessage.choices"
    (choiceSelected)="processSelection($event)"
    [columns]="1"
    [choiceWidth]="900"
    [choiceHeight]="40"
  ></cm-choice-selector>
</div>
