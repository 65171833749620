<div class="screen">
  <div
    class="overlay"
    [@overlayTrigger]="state"
    [ngStyle]="{ 'width.px': width, 'height.px': height }"
  >
    <div class="overlay-header-avatar" *ngIf="headerAvatar">
      <cm-avatar-thumb
        [user]="headerAvatar"
        size="140"
        [hidePresence]="true"
      ></cm-avatar-thumb>
    </div>
    <div
      class="overlay-header"
      *ngIf="header"
      [ngClass]="{ center: headerCenter }"
    >
      {{ header }}
      <cm-message-count
        [totalMessages]="totalMessages"
        [currentMessage]="currentMessage"
        *ngIf="showMessageCount"
      ></cm-message-count>
    </div>
    <div class="overlay-content" [ngClass]="{ 'justify-top': justifyTop }">
      <ng-content></ng-content>
    </div>
  </div>
</div>
