import { BaseUser } from './../../../core/models/base-user.model';
import { Component, OnInit, Input } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'cm-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  animations: [
    trigger('overlayTrigger', [
      state(
        'fadeIn',
        style({
          opacity: '1',
        })
      ),
      transition('void => *', [style({ opacity: '0' }), animate('500ms')]),
    ]),
  ],
})
export class OverlayComponent implements OnInit {
  state: string = 'fadeIn';

  @Input() header: string = '';
  @Input() headerAvatar: BaseUser;
  @Input() headerCenter: boolean = true;
  @Input() width: number = 900;
  @Input() height: number = 550;

  @Input() showMessageCount: boolean = false;
  @Input() totalMessages: number;
  @Input() currentMessage: number;

  @Input() justifyTop: boolean = false;

  constructor() {}

  ngOnInit() {}
}
