import { SupervisorService } from '../../core/services/supervisor.service';
import { Router } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';
import { Store } from '@ngrx/store';
import * as UserActions from 'src/app/state/user.actions';
import { UserState } from 'src/app/state/user.reducer';

@Component({
  selector: 'cm-assistance-request-confirmation-dialog',
  templateUrl: './assistance-request-confirmation-dialog.component.html',
  styleUrls: ['./assistance-request-confirmation-dialog.component.scss'],
})
export class AssistanceRequestConfirmationDialogComponent implements OnInit {
  constructor(
    private router: Router,
    private supervisor: SupervisorService,
    private store$: Store<UserState>
  ) {}

  ngOnInit(): void {}

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.backToListener();
    }
  }

  backToListener(): void {
    this.supervisor.showAssistance = false;
    this.store$.dispatch(UserActions.navigateToHome());
  }

  onSelection(choice): void {
    this.backToListener();
  }
}
