import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit, HostListener } from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../core/services/remote-control.service';

@Component({
  selector: 'cm-message-history',
  templateUrl: './message-history.component.html',
  styleUrls: ['./message-history.component.scss'],
})
export class MessageHistoryComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      const activeMessageRecipientId = +params['activeMessageRecipientId'];
      if (activeMessageRecipientId) {
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToMenu();
    }
    return false;
  }

  goToMenu() {
    this.router.navigate(['dashboard/menu']);
  }
}
