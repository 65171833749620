import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cm-supporter-assistance-provider-chip',
  templateUrl: './supporter-assistance-provider-chip.component.html',
  styleUrls: ['./supporter-assistance-provider-chip.component.scss'],
})
export class SupporterAssistanceProviderChipComponent implements OnInit {
  @Input() isAssistanceProvider: boolean;

  constructor() {}

  ngOnInit(): void {}
}
