import { createReducer, on } from '@ngrx/store';
import { MessageRecipient } from '../core/models/message-recipient.model';
import * as MessagesActions from './messages.actions';

export const messagesFeatureKey = 'messages';

export interface MessageRecipientNotification {
  message_recipient_id: number;
  message_set_id: number;
  recipient_id: number;
}

export interface MessagesState {
  showMessageRecipientsSpinner: boolean;
  messageRecipients: MessageRecipient[];
  currentMessageRecipientId: number;
  next: string;
  previous: string;
  count: number;
  messageRecipientsError: string;
}

const initialState: MessagesState = {
  showMessageRecipientsSpinner: false,
  messageRecipients: [],
  currentMessageRecipientId: undefined,
  next: '',
  previous: '',
  count: 0,
  messageRecipientsError: '',
};

export const messagesReducer = createReducer<MessagesState>(
  initialState,
  on(MessagesActions.getMessageRecipients, (state, action) => ({
    ...state,
    showMessageRecipientsSpinner: true,
  })),
  on(MessagesActions.getMessageRecipientsSuccess, (state, action) => ({
    ...state,
    showMessageRecipientsSpinner: false,
    messageRecipients: [
      ...state.messageRecipients,
      ...action.apiResponse.results,
    ],
    next: action.apiResponse.next,
    previous: action.apiResponse.previous,
    count: action.apiResponse.count,
    currentMessageRecipientId:
      action.apiResponse.results.length > 0
        ? action.apiResponse.results[0].id
        : undefined,
  })),
  on(MessagesActions.getMessageRecipientsFailed, (state, action) => ({
    ...state,
    messageRecipientsError: action.message,
    showMessageRecipientsSpinner: false,
  })),
  on(MessagesActions.loadNextMessageRecipients, (state, action) => ({
    ...state,
    showMessageRecipientsSpinner: true,
  })),
  on(MessagesActions.loadNextMessageRecipientsSuccess, (state, action) => ({
    ...state,
    showMessageRecipientsSpinner: false,
    messageRecipients: [
      ...state.messageRecipients,
      ...action.apiResponse.results,
    ],
    next: action.apiResponse.next,
    previous: action.apiResponse.previous,
  })),
  on(MessagesActions.loadNextMessageRecipientsFailure, (state, action) => ({
    ...state,
    messageRecipientsError: action.message,
    showMessageRecipientsSpinner: false,
  })),
  on(MessagesActions.setCurrentMessageRecipientId, (state, action) => ({
    ...state,
    currentMessageRecipientId: action.messageRecipient.id,
  })),
  on(MessagesActions.sendResponsesSuccess, (state, action) => {
    const messageRecipients = [...state.messageRecipients];
    const idx = messageRecipients.findIndex((mr) => mr.id === action.id);
    if (idx !== -1) {
      messageRecipients[idx] = action;
    }
    return { ...state, messageRecipients };
  }),
  on(MessagesActions.getMessageRecipientSuccess, (state, action) => {
    const idx = state.messageRecipients.findIndex(
      (mr) => mr.id === action.messageRecipient[0].id
    );
    if (idx === -1) {
      return {
        ...state,
        messageRecipients: [
          action.messageRecipient[0],
          ...state.messageRecipients,
        ],
        count: state.count + 1,
      };
    }
    const messageRecipients = [...state.messageRecipients];
    messageRecipients[idx] = action.messageRecipient[0];
    return {
      ...state,
      messageRecipients,
    };
  })
);
