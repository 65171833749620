import { ConferencingService } from './../../core/services/conferencing.service';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { CustomerPresenceService } from '../../core/services/customer-presence.service';
import { SupporterService } from './../../core/services/supporter.service';
import { LoggerService } from './../../core/services/logger.service';
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  HostListener,
} from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../core/services/remote-control.service';
import { share } from 'rxjs/operators';
import { CallOptions } from 'src/app/state/conferencing.reducer';

@Component({
  selector: 'cm-request-call',
  templateUrl: './request-call.component.html',
  styleUrls: ['./request-call.component.scss'],
})
export class RequestCallComponent implements OnInit, OnChanges {
  @Input() users: any[];
  public choices: Observable<any[]>;

  constructor(
    private logger: LoggerService,
    private customerPresence: CustomerPresenceService,
    private router: Router,
    private conferencing: ConferencingService
  ) {}

  ngOnInit() {
    this.choices = this.buildChoices(this.users);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('users')) {
      this.choices = this.buildChoices(changes['users'].currentValue);
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToMenu();
    }
    return false;
  }

  onChoiceSelected(choice) {
    this.logger.info(
      `${choice.user.last_name} ${choice.user.last_name} selected`
    );
    let callOptions: CallOptions = { target: choice.user };
    this.conferencing.callRequestedCallOptions = callOptions;
    this.router.navigate([
      'conferencing/call-viewer',
      { callType: 'outgoing' },
    ]);
  }

  buildChoices(users) {
    let tmpChoices = [];
    users.forEach((user) => tmpChoices.push(this.buildChoice(user)));
    return of(tmpChoices).pipe(share());
  }

  buildChoice(user) {
    let choice = {
      id: user.id,
      label: `${user.first_name} ${user.last_name}`,
      user: user,
    };
    return choice;
  }

  goToMenu() {
    this.router.navigate(['dashboard/menu']);
  }
}
