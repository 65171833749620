import { createAction, props } from '@ngrx/store';
import { ApiResponse } from '../core/models/api-response';
import { MessageRecipient } from '../core/models/message-recipient.model';
import { MessageRecipientNotification } from './messages.reducer';

export const getMessageRecipients = createAction(
  '[Messages] Load MessageRecipients'
);

export const getMessageRecipientsSuccess = createAction(
  '[Messages] Load MessageRecipients Success',
  props<{ apiResponse: ApiResponse<MessageRecipient> }>()
);

export const getMessageRecipientsFailed = createAction(
  '[Messages] Load MessageRecipients Failed',
  props<Error>()
);

export const getMessageRecipient = createAction(
  '[Messages] Load MessageRecipient',
  props<MessageRecipientNotification>()
);

export const getMessageRecipientSuccess = createAction(
  '[Messages] Load MessageRecipient Success',
  props<{ messageRecipient: MessageRecipient }>()
);

export const getMessageRecipientFailed = createAction(
  '[Messages] Load MessageRecipient Failed',
  props<Error>()
);

export const loadNextMessageRecipients = createAction(
  '[Messages] Load Next MessageRecipients'
);

export const loadNextMessageRecipientsSuccess = createAction(
  '[Messages] Load Next MessageRecipients Success',
  props<{ apiResponse: ApiResponse<MessageRecipient> }>()
);

export const loadNextMessageRecipientsFailure = createAction(
  '[Messages] Load Next MessageRecipients Failure',
  props<Error>()
);

export const setCurrentMessageRecipientId = createAction(
  '[Messages] Set Current MessageRecipient Id',
  props<{ messageRecipient: MessageRecipient }>()
);

export const moveToNextMessageRecipient = createAction(
  '[Messages] Move to Next MessageRecipient'
);

export const moveToPreviousMessageRecipient = createAction(
  '[Messages] Move to Previous MessageRecipient'
);

export const noOp = createAction('[Messages] No Op');

export const markAsDelivered = createAction(
  '[Messages] Mark As Delivered',
  props<{ messageRecipients: MessageRecipient[] }>()
);

export const markAsDeliveredSuccess = createAction(
  '[Messages] Mark As Delivered Success'
);

export const markAsDeliveredFailure = createAction(
  '[Messages] Mark As Delivered Failure'
);

export const sendResponses = createAction(
  '[Messages] Send Responses',
  props<MessageRecipient>()
);

export const sendResponsesSuccess = createAction(
  '[Messages] Send Responses Success',
  props<MessageRecipient>()
);

export const sendResponsesFailure = createAction(
  '[Messages] Send Responses Failure',
  props<Error>()
);

export const redirectToListener = createAction(
  '[Messages] Redirect To Listener'
);

// Messages WS Actions

export const messageRecipientCreated = createAction(
  '[Messages][WS] Message Recipient Created',
  props<MessageRecipientNotification>()
);

export const messageRecipientUpdated = createAction(
  '[Messages][WS] Message Recipient Updated',
  props<MessageRecipientNotification>()
);
