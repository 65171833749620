import { Injectable, OnDestroy } from '@angular/core';
import { ExternalInput } from '../types/types';
import { StbState } from '../../state/stb.reducer';
import { Store } from '@ngrx/store';
import * as StbSelectors from '../../state/stb.selectors';
import { Observable, Subscription } from 'rxjs';

declare var hcap: any;

@Injectable({
  providedIn: 'root',
})
export class HcapService implements OnDestroy {
  stbState: StbState;
  stbStateSubscription: Subscription;
  constructor(private store$: Store) {
    this.stbStateSubscription = this.store$
      .select(StbSelectors.selectStbState)
      .subscribe((state) => {
        this.stbState = state;
      });
  }

  ngOnDestroy(): void {
    this.stbStateSubscription.unsubscribe();
  }

  isAvailable(): boolean {
    return navigator.userAgent.includes('Web0S');
  }

  setBrowserDebugModeOn(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.setBrowserDebugMode({
        debugMode: true,
        onSuccess: () => {
          console.log('onSuccess');
        },
        onFailure: (f) => {
          console.log('onFailure : errorMessage = ' + f.errorMessage);
        },
      });
    });
  }

  getInstantPower(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'instant_power',
        onSuccess: (r) => {
          console.info(`onSuccess : getInstantPower : ${JSON.stringify(r)}`);
          resolve(r);
        },
        onFailure: (e) => {
          const errorMessage = `onFailure : getInstantPower : ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setInstantPowerOn(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.setProperty({
        key: 'instant_power',
        value: '2',
        onSuccess: (r) => {
          console.info(`onSuccess : setInstantPowerOn : ${JSON.stringify(r)}`);
          resolve(r);
        },
        onFailure: (e) => {
          const errorMessage = `onFailure : setInstantPowerOn : ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setInstantPowerOff(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.setProperty({
        key: 'instant_power',
        value: '0',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: setInstantPowerOff : ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setHcapMode0(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.mode.setHcapMode({
        mode: hcap.mode.HCAP_MODE_0,
        onSuccess: (param) => {
          console.info(`onSuccess : setHcapMode : set to 0`);
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: setHcapMode : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setHcapMode1(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.mode.setHcapMode({
        mode: hcap.mode.HCAP_MODE_1,
        onSuccess: (param) => {
          console.info(`onSuccess : setHcapMode : set to 1`);
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: setHcapMode : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setHcapMode2(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.mode.setHcapMode({
        mode: hcap.mode.HCAP_MODE_2,
        onSuccess: (param) => {
          console.info(`onSuccess : setHcapMode : set to 2`);
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: setHcapMode : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getMacAddress(): Promise<any> {
    /*
    Try's hardware first, then local storage,
    then returns hard coded default if not available
    */
    const DEFAULT_MAC = 'A0:B1:C2:D3:E4:F5';
    let mac;

    return this.getEthernetInformation()
      .then((r: any) => r.mac.toUpperCase())
      .catch(() => {
        mac = localStorage.getItem('macAddress');
        if (mac) {
          console.info(
            'configuration : got MAC address from localStorage : ' +
              mac.toUpperCase()
          );
        } else {
          mac = DEFAULT_MAC;
          console.info(
            'configuration : MAC address not found in localStorage, using default : ' +
              mac.toUpperCase()
          );
        }
        return mac.toUpperCase();
      });
  }

  getEthernetInformation(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.network.getNetworkDevice({
        index: 0,
        onSuccess: (r) => resolve(r),
        onFailure: (r) => {
          const errorMessage = 'onFailure: getNetworkDevice: ' + r.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getModelName(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'model_name',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: getModelName: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getPlatformVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'platform_version',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: getPlatformVersion: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getHardwareVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'hardware_version',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: getHardwareVersion: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getSerialNumber(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'serial_number',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: getSerialNumber: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getFirmwareVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'platform_version',
        onSuccess: (version) => resolve(version),
        onFailure: (error) => {
          const errorMessage = `onFailure: getFirmwareVersion: ${error.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getHcapJsVersion(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'hcap_js_extension_version',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: getHcapJsVersion: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getDisplayResolution(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.getProperty({
        key: 'display_resolution',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: getDisplayResolution: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setDisplayResolution1080(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.setProperty({
        key: 'display_resolution',
        value: '1920x1080',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: setDisplayResolution1080: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setDisplayResolution720(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.property.setProperty({
        key: 'display_resolution',
        value: '1280x720',
        onSuccess: (r) => resolve(r),
        onFailure: (e) => {
          const errorMessage = `onFailure: setDisplayResolution720: ${e.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  powerOffTv(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.power.powerOff({
        onSuccess: (r) => {
          console.info(`TV powered off successfully.`);
          resolve(r);
        },
        onFailure: (r) => {
          const errorMessage = 'onFailure: powerOffTv: ' + r.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  rebootTv(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.power.reboot({
        onSuccess: (r) => {
          console.info(`TV rebooted successfully.`);
          resolve(r);
        },
        onFailure: (r) => {
          const errorMessage = 'onFailure: rebootTv: ' + r.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setPowerModeWarm(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.power.setPowerMode({
        mode: hcap.power.PowerMode.WARM,
        onSuccess: (r) => {
          console.info(`set power mode warm successfully.`);
          resolve(r);
        },
        onFailure: (r) => {
          const errorMessage = 'onFailure: setPowerModeWarm: ' + r.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setPowerModeNormal(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.power.setPowerMode({
        mode: hcap.power.PowerMode.NORMAL,
        onSuccess: (r) => {
          console.info(`set power mode normal successfully.`);
          resolve(r);
        },
        onFailure: (r) => {
          const errorMessage =
            'onFailure: setPowerModeNormal: ' + r.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getProcentricServer(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.getProcentricServer({
        onSuccess: (p) => {
          console.info(
            `onSuccess : Pro:Centric server mode = ${p.mode}, media = ${p.media}`
          );
          resolve(p);
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure: getProcentricServer: ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getLocale(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.getLocale({
        onSuccess: (param) => {
          console.info(
            `onSuccess : specifier = ${param.specifier}, language = ${param.language}, country = ${param.country}`
          );
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: rebootTv: ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  requestLocaleChange(specifier: string): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.requestLocaleChange({
        specifier,
        onSuccess: (param) => {
          console.info(`onSuccess : requestLocaleChange`);
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure: requestLocaleChange : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getLocaleList(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.getLocaleList({
        onSuccess: (s) => {
          for (let i = 0; i < s.list.length; i++) {
            console.log(
              'list[' +
                i +
                '].specifier = ' +
                s.list[i].specifier +
                'list[' +
                i +
                '].language = ' +
                s.list[i].language +
                'list[' +
                i +
                '].country = ' +
                s.list[i].country
            );
          }
          resolve(s);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: getLocaleList : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setNoSignalImage(activated: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.setNoSignalImage({
        noSignalImage: activated,
        onSuccess: (param) => {
          console.info(`onSuccess : setNoSignalImage`);
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure : setNoSignalImage : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async getCurrentExternalInput(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.externalinput.getCurrentExternalInput({
        onSuccess: (param: any) => {
          console.info(
            `onSuccess : getCurrentExternalInput : type = ${param.type} : index = ${param.index}`
          );
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure : getCurrentExternalInput : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async setCurrentExternalInput(
    type: ExternalInput,
    index: number
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.externalinput.setCurrentExternalInput({
        type,
        index,
        onSuccess: (param) => {
          console.info(`onSuccess : setCurrentExternalInput`);
          resolve(param);
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure : setCurrentExternalInput : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async getExternalInputList(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.externalinput.getExternalInputList({
        onSuccess: (s) => {
          console.log(
            'onSuccess : getExternalInputList : list length = ' + s.list.length
          );
          for (const input of s.list) {
            console.log(`(${input.type},${input.index})`);
          }
          console.info(`onSuccess : getExternalInputList :`);
          resolve(s);
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure : getExternalInputList : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getVolumeLevel(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.volume.getVolumeLevel({
        onSuccess: (s) => {
          console.log('onSuccess : getVolumeLevel : level = ' + s.level);
          resolve(s);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure : getVolumeLevel : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  setVolumeLevel(level: number): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.volume.setVolumeLevel({
        level,
        onSuccess: (s) => {
          console.log(`onSuccess : setVolumeLevel`);
          resolve(s);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure : setVolumeLevel : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  audioStartUp(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.Media.startUp({
        onSuccess: () => {
          console.info(`audio media : startup : success`);
          const media = hcap.Media.createMedia({
            url,
            mimeType: 'audio/mp3',
          });
          resolve(media);
        },
        onFailure: (f) => {
          console.error(`audio media : startup : failure : ${f.errorMessage}`);
          reject(f);
        },
      });
    });
  }

  mediaShutDown(): Promise<void> {
    return new Promise((resolve, reject) => {
      hcap.Media.shutDown({
        onSuccess: () => {
          console.info(`media : shutdown : success`);
          resolve();
        },
        onFailure: (f) => {
          console.error(`media : shutdown : failure : ${f.errorMessage}`);
          reject(f);
        },
      });
    });
  }

  async isVideoMute(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      hcap.video.isVideoMute({
        onSuccess: (s) => {
          console.log('onSuccess : isVideoMute : mute = ' + s.videoMute);
          resolve(s.videoMute);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure : isVideoMute : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async setVideoMute(mute: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      hcap.video.setVideoMute({
        videoMute: mute,
        onSuccess: () => {
          console.log(`onSuccess : setVideoMute`);
          resolve();
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure : setVideoMute : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async stopCurrentChannel(): Promise<void> {
    return new Promise((resolve, reject) => {
      hcap.channel.stopCurrentChannel({
        onSuccess: () => {
          console.log(`onSuccess : stopCurrentChannel`);
          resolve();
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure : stopCurrentChannel : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async replayCurrentChannel(): Promise<void> {
    return new Promise((resolve, reject) => {
      hcap.channel.replayCurrentChannel({
        onSuccess: () => {
          console.log(`onSuccess : replayCurrentChannel`);
          resolve();
        },
        onFailure: (f) => {
          const errorMessage =
            'onFailure : replayCurrentChannel : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  async resetAV(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        console.info(
          `hcap-service : current input : type: ${this.stbState.currentInputType} : index: ${this.stbState.currentInputIndex}`
        );

        const currentInputType = this.stbState.currentInputType;
        const currentInputIndex = this.stbState.currentInputIndex;
        console.info(`START ${currentInputType} ${currentInputIndex}`);

        if (currentInputType === 6 || currentInputType === 8) {
          // was HDMI or UNKNOWN
          const inputs = (await this.getExternalInputList()) as any;
          const hdmiInputs = inputs.list.filter((input) => input.type === 6);
          console.info(`hdmi inputs: ${JSON.stringify(hdmiInputs)}`);
          const hdmiIndexes = hdmiInputs.map((hdmi) => hdmi.index);
          console.info(`hdmi indexes: ${JSON.stringify(hdmiIndexes[0])}`);
          const availableIndexes = hdmiIndexes.filter(
            (idx) => idx !== currentInputIndex
          );
          console.info(
            `available indexes: ${JSON.stringify(availableIndexes)}`
          );
          if (availableIndexes.length > 0) {
            console.info(
              `resetAV : switching to HDMI input ${availableIndexes[0]}`
            );
            await this.setCurrentExternalInput(6, availableIndexes[0]);
          }
          setTimeout(async () => {
            console.info(
              `resetAV : switching back to HDMI input ${currentInputIndex}`
            );
            await this.setCurrentExternalInput(
              currentInputType,
              currentInputIndex
            );
            resolve(this.stbState);
          }, 1000);
        }
        if (currentInputType === 1 || currentInputType === 8) {
          // Was DVB-T or UNKNOWN
          await this.setCurrentExternalInput(6, 0);
          setTimeout(async () => {
            await this.setCurrentExternalInput(
              currentInputType,
              currentInputIndex
            );
            resolve(this.stbState);
          }, 1000);
        }
      } catch (e) {
        console.error(`hcap-service : resetAv error : ${JSON.stringify(e)}`);
        reject(e);
      }
    });
  }

  async setSoundOutputType(type: number): Promise<void> {
    return new Promise((resolve, reject) => {
      hcap.system.setSoundOutput({
        soundOutput: type,
        onSuccess: () => {
          console.log(`onSuccess : setSoundOutputType : ${type}`);
          resolve();
        },
        onFailure: (f) => {
          const errorMessage = `onFailure : setSoundOutputType : ${type} : ${f.errorMessage}`;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getCpuUsage(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.getCpuUsage({
        onSuccess: (param) => {
          console.info(
            `onSuccess : getCpuUsage : ${JSON.stringify(param.percentage)}`
          );
          resolve(param.percentage);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: getCpuUsage : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  getMemoryUsage(): Promise<any> {
    return new Promise((resolve, reject) => {
      hcap.system.getMemoryUsage({
        onSuccess: (param) => {
          console.info(
            `onSuccess : getMemoryUsage : ${JSON.stringify(param.percentage)}`
          );
          resolve(param.percentage);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: getMemoryUsage : ' + f.errorMessage;
          console.error(errorMessage);
          reject(errorMessage);
        },
      });
    });
  }

  // Observables

  getCpuUsageObs(): Observable<any> {
    return new Observable((observer) => {
      hcap.system.getCpuUsage({
        onSuccess: (param) => {
          console.info(
            `onSuccess : getCpuUsage : ${JSON.stringify(param.percentage)}`
          );
          observer.next(param);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: getCpuUsage : ' + f.errorMessage;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  getMemoryUsageObs(): Observable<any> {
    return new Observable((observer) => {
      hcap.system.getMemoryUsage({
        onSuccess: (param) => {
          console.info(
            `onSuccess : getMemoryUsage : ${JSON.stringify(param.percentage)}`
          );
          observer.next(param);
        },
        onFailure: (f) => {
          const errorMessage = 'onFailure: getMemoryUsage : ' + f.errorMessage;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  getFirmwareVersionObs(): Observable<any> {
    return new Observable((observer) => {
      hcap.property.getProperty({
        key: 'platform_version',
        onSuccess: (version) => observer.next(version),
        onFailure: (error) => {
          const errorMessage = `onFailure: getFirmwareVersion: ${error.errorMessage}`;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  setPowerModeNormalObs(): Observable<any> {
    return new Observable((observer) => {
      hcap.power.setPowerMode({
        mode: hcap.power.PowerMode.NORMAL,
        onSuccess: (r) => observer.next(r),
        onFailure: (error) => {
          const errorMessage = `onFailure: setPowerModeNormalObs: ${error.errorMessage}`;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  setPowerModeWarmObs(): Observable<any> {
    return new Observable((observer) => {
      hcap.power.setPowerMode({
        mode: hcap.power.PowerMode.WARM,
        onSuccess: (r) => {
          console.info(`set power mode warm successfully.`);
          observer.next(r);
        },
        onFailure: (error) => {
          const errorMessage = `onFailure: setPowerModeWarmObs: ${error.errorMessage}`;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  powerOffObs(): Observable<any> {
    return new Observable((observer) => {
      hcap.power.powerOff({
        onSuccess: (r) => observer.next(r),
        onFailure: (error) => {
          const errorMessage = `onFailure: powerOffObs : ${error.errorMessage}`;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }

  rebootObs(): Observable<any> {
    return new Observable((observer) => {
      hcap.power.reboot({
        onSuccess: (r) => observer.next(r),
        onFailure: (error) => {
          const errorMessage = `onFailure: rebootObs : ${error.errorMessage}`;
          console.error(errorMessage);
          observer.error(errorMessage);
        },
      });
    });
  }
}
