import { LoggerService } from '../../../core/services/logger.service';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  HostListener,
  ViewChild,
  ContentChildren,
  QueryList,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ASCII_REMOTE_KEYS } from '../../../core/services/remote-control.service';

import { indexOf } from 'lodash-es';

@Component({
  selector: 'cm-choice-selector',
  templateUrl: './choice-selector.component.html',
  styleUrls: ['./choice-selector.component.scss'],
})
export class ChoiceSelectorComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() choices: Array<any>;
  @Input() currentChoice: any;
  @Input() choiceWidth = 400;
  @Input() choiceHeight = 60;
  @Input() iconWidth = 128;
  @Input() iconHeight = 128;
  @Input() columns = 1;
  @Input() showAvatar = false;
  @Input() avatarSize = 72;
  @Input() showIcon = false;
  @Input() showToggle = false;

  @Output() choiceSelected = new EventEmitter();
  @ViewChild('table') gridTable: ElementRef;

  private table: HTMLTableElement;
  private active = 0;

  constructor(private renderer: Renderer2, private logger: LoggerService) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.table = this.gridTable.nativeElement as HTMLTableElement;
    if (this.currentChoice) {
      this.active = indexOf(this.choices, this.currentChoice);
    }
    this.setFocus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('choices') &&
      changes['choices'].currentValue &&
      changes['choices'].currentValue.length > 0 &&
      changes['choices'].currentValue[0].hasOwnProperty('choice_text')
    ) {
      this.choices = this.choices.map((choice) => ({
        ...choice,
        label: choice.choice_text,
      }));
      this.active = 0;
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.ok) {
      this.onSelected($event);
    } else {
      this.reCalculate($event);
      this.rePosition();
    }
    return false;
  }

  setFocus(): void {
    const cells = this.table.getElementsByClassName(
      'cm-choice-selector-choice'
    ) as HTMLCollectionOf<HTMLTableCellElement>;
    if (cells && cells.length > 0) {
      cells[this.active].focus();
    }
    // remove active from all td
    const actives = this.table.getElementsByClassName('active');
    for (let i = 0; i < actives.length; i++) {
      this.renderer.removeClass(actives[i], 'active');
    }
    if (cells && cells.length > 0) {
      this.renderer.addClass(cells[this.active], 'active');
    }
  }

  onSelected($event): void {
    const choiceId = this.table
      .getElementsByClassName('active')[0]
      .getAttribute('data-choice-id');
    const choice = this.choices.find((c) => c.id === parseInt(choiceId, 10));
    if (choice) {
      this.choiceSelected.emit(choice);
    }
  }

  reCalculate($event: KeyboardEvent): void {
    const rows = this.table.getElementsByTagName('tr').length;
    const columns = this.table
      .getElementsByTagName('tr')[0]
      .getElementsByClassName('cm-choice-selector-choice').length;

    if ($event.keyCode === ASCII_REMOTE_KEYS.left) {
      // move left or wrap
      this.active = this.active > 0 ? this.active - 1 : this.active;
    }
    if ($event.keyCode === ASCII_REMOTE_KEYS.up) {
      // move up
      this.active =
        this.active - columns >= 0 ? this.active - columns : this.active;
    }
    if ($event.keyCode === ASCII_REMOTE_KEYS.right) {
      // move right or wrap
      this.active =
        this.active < columns * rows - 1 ? this.active + 1 : this.active;
    }
    if ($event.keyCode === ASCII_REMOTE_KEYS.down) {
      // move down
      this.active =
        this.active + columns <= rows * columns - 1
          ? this.active + columns
          : this.active;
    }

    console.log('active: ' + this.active);
  }

  rePosition(): void {
    const tdCount =
      this.table.getElementsByClassName('cm-choice-selector-choice').length - 1;
    if (this.active <= tdCount) {
      // Set focus on new element
      const cells = this.table.getElementsByClassName(
        'cm-choice-selector-choice'
      ) as HTMLCollectionOf<HTMLTableCellElement>;
      cells[this.active].focus();
      // remove active from all td
      const actives = this.table.getElementsByClassName('active');
      for (let i = 0; i < actives.length; i++) {
        this.renderer.removeClass(actives[i], 'active');
      }
      // Set active class on new element
      this.renderer.addClass(
        this.table.getElementsByClassName('cm-choice-selector-choice')[
          this.active
        ],
        'active'
      );
    } else {
      // set active to the last cell
      this.active = tdCount;
    }
  }
}
