<cm-overlay
  [header]="(currentCustomer$ | async).assistance_menu_label"
  [width]="800"
  [height]="600"
>
  <cm-choice-selector
    [choices]="choices"
    (choiceSelected)="sendAssistanceRequest($event)"
    [choiceWidth]="600"
    [showAvatar]="false"
    [avatarSize]="50"
    *ngIf="assistanceProviderChoices$ | async as choices else noProviders"
  ></cm-choice-selector>

  <ng-template #noProviders>
    <h1 class="confirmation">{{ noProvidersMessage }}</h1>
  </ng-template>
</cm-overlay>
