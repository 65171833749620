import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as ConferenceSelectors from 'src/app/state/conferencing.selectors';

@Component({
  selector: 'cm-camera-present-indicator',
  templateUrl: './camera-present-indicator.component.html',
  styleUrls: ['./camera-present-indicator.component.scss'],
})
export class CameraPresentIndicatorComponent implements OnInit {
  cameraConnected$: Observable<boolean>;

  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.cameraConnected$ = this.store$.select(
      ConferenceSelectors.selectCameraConnected
    );
  }
}
