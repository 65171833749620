import { Component, Input, OnInit } from '@angular/core';
import { Resident } from 'src/app/core/models/resident.model';

@Component({
  selector: 'app-account-detail-card',
  templateUrl: './account-detail-card.component.html',
  styleUrls: ['./account-detail-card.component.scss'],
})
export class AccountDetailCardComponent implements OnInit {
  @Input() resident: Resident;

  constructor() {}

  ngOnInit(): void {}
}
