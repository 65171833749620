import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../../core/services/remote-control.service';


@Component({
    selector: 'cm-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit {
    @Input() title: string;
    @Input() subTitle: string;
    @Input() showOk: boolean = true;
    @Output() okPressed = new EventEmitter();

    constructor() { }

    ngOnInit() {

    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown($event: KeyboardEvent) {
        if ($event.keyCode === ASCII_REMOTE_KEYS.ok) {
            this.onOk($event);
        }
        return false;
    }

    onOk($event: any) {
        this.okPressed.emit();
    }
}
