import { MessageRecipientService } from './../../../core/services/message-recipient.service';
import { SupervisorService } from './../../../core/services/supervisor.service';
import { MessageResponse } from './../../../core/models/message-response.modal';
import { Message } from './../../../core/models/message.model';
import { LoggerService } from './../../../core/services/logger.service';
import { NavigationEnd, Router } from '@angular/router';
import { MessageChoice } from './../../../core/models/message-choice.model';
import { MessageRecipient } from './../../../core/models/message-recipient.model';
import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../../core/services/remote-control.service';
import { last, cloneDeep } from 'lodash-es';
import * as MessagesActions from 'src/app/state/messages.actions';
import { MessagesState } from 'src/app/state/messages.reducer';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { PreviousRouteService } from 'src/app/core/services/previous-route.service';

@Component({
  selector: 'cm-message-set-viewer',
  templateUrl: './message-set-viewer.component.html',
  styleUrls: ['./message-set-viewer.component.scss'],
})
export class MessageSetViewerComponent implements OnInit, OnChanges {
  lock: boolean = false;
  currentMessage: Message;
  currentMessageIndex: number = 0;
  currentUrl: string;
  previousUrl: string;

  @Input() messageRecipient: MessageRecipient;
  @Output() responsesSent = new EventEmitter();
  @Output() currentMessageChanged = new EventEmitter();

  constructor(
    private router: Router,
    private logger: LoggerService,
    private supervisor: SupervisorService,
    private messageRecipientService: MessageRecipientService,
    private store$: Store<MessagesState>,
    private previousRouteService: PreviousRouteService
  ) {}

  ngOnInit() {
    this.currentMessage =
      this.messageRecipient.message_set.messages[this.currentMessageIndex];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.hasOwnProperty('messageRecipient') &&
      changes['messageRecipient'].currentValue
    ) {
      this.currentMessageIndex = 0;
      this.currentMessage =
        changes['messageRecipient'].currentValue.message_set.messages[
          this.currentMessageIndex
        ];
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    switch ($event.keyCode) {
      case ASCII_REMOTE_KEYS.back:
        this.close();
        break;
      case ASCII_REMOTE_KEYS.blue:
        this.goToViewer();
        break;
    }
    return false;
  }

  processSelection(choice: MessageChoice) {
    let response = new MessageResponse();
    response.choice = choice.id;
    response.message_recipient = this.messageRecipient.id;
    response.message = this.currentMessage.id;

    // Add the response to the messageRecipient's responses array
    this.messageRecipient = {
      ...this.messageRecipient,
      responses: [...this.messageRecipient.responses, response],
    };

    if (
      last(this.messageRecipient.message_set.messages) === this.currentMessage
    ) {
      // This is the last message in the set, send responses
      this.sendResponses();
    } else {
      // Move on to the next message
      this.currentMessageIndex += 1;
      this.currentMessage =
        this.messageRecipient.message_set.messages[this.currentMessageIndex];
      this.currentMessageChanged.emit(this.currentMessageIndex);
    }
  }

  private sendResponses() {
    if (!this.lock) {
      this.lock = true;

      let mrClone = cloneDeep(this.messageRecipient);
      mrClone.recipient = this.messageRecipient.recipient.id;
      mrClone.message_set = this.messageRecipient.message_set.id;
      mrClone.status = 'read';

      this.store$.dispatch(MessagesActions.sendResponses(mrClone));
      this.lock = false;

      // this.messageRecipientService.putMessageRecpient(mrClone).subscribe(
      //   (messageRecipient: MessageRecipient) => {
      //     this.logger.info(
      //       'messageset_viewer : messageRecipient PUT successfully : ' +
      //         messageRecipient.id
      //     );
      //     // Notify parent
      //     this.responsesSent.emit();
      //     // Message recipient updated, remove current messageSet from local array
      //     this.supervisor.markAsRead(messageRecipient.id);
      //     // check is any more unread messages
      //     this.supervisor.nextUnread();
      //     // unlock
      //     this.lock = false;
      //   },
      //   (failure) => {
      //     this.logger.error(
      //       'messageset_viewer : send_responses_mark_as_read : failed : ' +
      //         failure.status
      //     );
      //     // Show error dialog
      //     this.supervisor.showErrorDialog({
      //       message: 'An error occured when responding to this message set',
      //     });
      //   }
      // );
    } else {
      this.logger.warn(
        'messageset_viewer : sendResponses : action blocked by lock'
      );
    }
  }

  private close() {
    // this.router.navigate(['supervisor/listener']);
    this.router.navigate([this.previousRouteService.getPreviousUrl()]);
  }

  private goToViewer() {
    const isVideoMessage = this.currentMessage.videos.length > 0;
    if (isVideoMessage) {
      this.goToVideoViewer();
    } else {
      this.goToImageViewer();
    }
  }

  private goToVideoViewer() {
    this.router.navigate([
      'viewers/video-viewer/',
      this.messageRecipient.id,
      this.currentMessage.id,
    ]);
  }

  private goToImageViewer() {
    this.router.navigate([
      'viewers/image-viewer/',
      this.messageRecipient.id,
      this.currentMessage.id,
    ]);
  }
}
