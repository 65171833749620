import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response';
import { Customer } from '../models/customer.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  endpointUrl: string = environment.apiUrl + '/customer';

  constructor(private http: HttpClient) {}

  getCustomers() {
    let params = new HttpParams();
    params = params.set('page_size', '50');
    return this.http
      .get<ApiResponse<Customer>>(this.endpointUrl, { params })
      .pipe(catchError(this.handleError));
  }

  addCustomer(customer) {
    return this.http.post<Customer>(this.endpointUrl, customer);
  }

  updateCustomer(customer: Customer) {
    return this.http
      .patch<Customer>(this.endpointUrl + '/' + customer.id, customer)
      .pipe(catchError(this.handleError));
  }

  getCustomer(customerId: number) {
    return this.http
      .get<Customer>(this.endpointUrl + '/' + customerId)
      .pipe(catchError(this.handleError));
  }

  private extractData(data: any) {
    return data.results || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
