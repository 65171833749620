import { LinearPartition } from '../../../../linear-partition';
import { LoggerService } from '../../core/services/logger.service';
import { MessageImage } from '../../core/models/message-image.model';
import { MediaLibraryService } from '../../core/services/media-library.service';
import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ElementRef,
  Renderer2,
  OnDestroy,
  AfterContentInit,
  NgZone,
} from '@angular/core';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';
import { Store } from '@ngrx/store';
import { MessagesState } from 'src/app/state/messages.reducer';
import {
  selectAllImages,
  selectAllVideos,
} from 'src/app/state/messages.selectors';
import { Observable, Subscription } from 'rxjs';
import { MessageVideo } from '../../core/models/message-video.model';

@Component({
  selector: 'cm-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss'],
})
export class MediaLibraryComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  showImageViewer = false;
  currentImage: MessageImage;
  currentImages: MessageImage[] = [];
  images$: Observable<MessageImage[]>;
  imagesSubscription: Subscription;
  images: MessageImage[];
  videos$: Observable<MessageVideo[]>;
  videosSubscription: Subscription;
  videos: MessageVideo[];

  el: HTMLDivElement = this.elementRef.nativeElement;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private store$: Store<MessagesState>,
    public mediaLibrary: MediaLibraryService,
    public ngZone: NgZone
  ) {
    this.images$ = this.store$.select(selectAllImages);
    this.imagesSubscription = this.images$.subscribe(
      (images: MessageImage[]) => {
        this.images = images;
      }
    );
  }

  ngOnInit(): void {
    // this.videos$ = this.store$.select(selectAllVideos);
    // this.videosSubscription = this.videos$.subscribe(
    //   (videos: MessageVideo[]) => (this.videos = videos)
    // );
  }

  ngAfterViewInit(): void {
    // (1) Find appropriate number of rows by dividing the sum of ideal photo widths by the width of the viewport
    let media = [...this.images];
    const result = [];
    const viewportWidth = this.el.children[0].clientWidth - 12;
    const idealHeight = window.innerHeight / 2;
    const summedWidth = this.images.reduce((sum, media) => {
      const aspectRatio = media.aspect_ratio;
      return (sum += aspectRatio * idealHeight);
    }, 0);

    const rows = Math.round(summedWidth / viewportWidth);

    if (rows < 1) {
      // (2a) Fallback to just standard size when just a few images
      // this.images.forEach((image) =>
      //   this.resize(Math.round(idealHeight * image.aspect_ratio), idealHeight)
      // );
      media.forEach((media) => {
        result.push(media);
      });
    } else {
      // (2b) Partition photos across rows using the aspect_ratio as weight
      const weights = media.map((m) => {
        // console.info(`AR: ${m.aspect_ratio}`);
        return parseInt((m.aspect_ratio * 100).toString(), 10);
      });
      // weight must be an integer
      const partition = LinearPartition.partition(weights, rows);

      // (3) Iterate through partition
      let index = 0;
      let rowBuffer = [];

      media = partition.map((row) => {
        rowBuffer = [];
        row.forEach((m) => rowBuffer.push(media[index++]));
        let summedArs = rowBuffer.reduce(
          (sum, m) => (sum += m.aspect_ratio),
          0
        );

        const r = rowBuffer.map((media) => ({
          ...media,
          width:
            parseInt(
              ((viewportWidth / summedArs) * media.aspect_ratio).toString(),
              10
            ) - 4,
          height: parseInt((viewportWidth / summedArs).toString(), 10) - 4,
        }));

        return r;
      });

      media.forEach((row: any) => {
        row.forEach((media) => {
          result.push(media);
        });
      });
    }

    this.images = result;

    setTimeout(() => {
      let imagesElement: HTMLDivElement = <HTMLDivElement>(
        this.el.getElementsByClassName('images')[0]
      );
      // this.ngZone.run(() => {
      //   this.renderer.addClass(imagesElement.children[0], 'active');
      //   this.renderer.setProperty(imagesElement.children[0], 'focus', 'focus');
      // });
    }, 500);
  }

  ngAfterContentInit(): void {}

  ngOnDestroy(): void {
    this.imagesSubscription.unsubscribe();
  }

  resize(width: number, height: number): void {
    this.logger.info(`Width: ${width} Height:${height}`);
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): boolean {
    switch ($event.keyCode) {
      case ASCII_REMOTE_KEYS.back:
        if (!this.showImageViewer) {
          this.goToMenu();
        }
        break;
      case ASCII_REMOTE_KEYS.right:
        this.next();
        break;
      case ASCII_REMOTE_KEYS.left:
        this.previous();
        break;
      case ASCII_REMOTE_KEYS.ok:
        this.goToImage($event);
        break;
    }

    return false;
  }

  next(): void {
    const actives = this.el.getElementsByClassName('active');
    const current = actives[0];

    if (
      current &&
      current.nextSibling !== undefined &&
      current.nextSibling.nodeName !== '#comment'
    ) {
      for (let i = 0; i < actives.length; i++) {
        this.renderer.removeClass(actives[i], 'active');
      }
      this.renderer.addClass(current.nextSibling, 'active');
      this.renderer.setProperty(current.nextSibling, 'focus', 'focus');

      const next: HTMLDivElement = <HTMLDivElement>current.nextSibling;
      next.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  previous(): void {
    const actives = this.el.getElementsByClassName('active');
    const current = actives[0];
    if (
      current &&
      current.previousSibling !== undefined &&
      current.previousSibling !== null
    ) {
      for (let i = 0; i < actives.length; i++) {
        this.renderer.removeClass(actives[i], 'active');
      }
      this.renderer.addClass(current.previousSibling, 'active');
      this.renderer.setProperty(current.previousSibling, 'focus', 'focus');

      const previous: HTMLDivElement = <HTMLDivElement>current.previousSibling;
      previous.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }

  goToMenu(): void {
    this.router.navigate(['dashboard/menu']);
  }

  goToImage(img): void {
    const actives = this.el.getElementsByClassName('active');
    const current = actives[0];
    const imageId = current.getAttribute('data-photo-id');
    const image: MessageImage = this.images.find(
      (i) => i.id === parseInt(imageId, 10)
    );
    if (image) {
      console.info(`found image: ${image.large_url}`);
      this.currentImage = image;
      this.currentImages = [image];
      this.showImageViewer = true;
    }
  }

  onBackPressed($event): void {
    this.showImageViewer = false;
    setTimeout(() => {
      // Remove active from everything
      let activeElements = this.el.getElementsByClassName('active');
      Array.from(activeElements).forEach((active) =>
        this.renderer.removeClass(active, 'active')
      );

      // set active and focus in current
      const photoElement: HTMLDivElement = this.el.querySelector(
        `[data-photo-id="${this.currentImage.id}"]`
      );

      if (photoElement) {
        this.renderer.addClass(photoElement, 'active');
        this.renderer.setProperty(photoElement, 'focus', 'focus');
        photoElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }
    }, 50);
  }
}
