import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MessageRecipient } from 'src/app/core/models/message-recipient.model';
import { Message } from 'src/app/core/models/message.model';
import { MessagesState } from 'src/app/state/messages.reducer';
import { selectMessageRecipientById } from 'src/app/state/messages.selectors';

@Component({
  selector: 'app-video-viewer-container',
  templateUrl: './video-viewer-container.component.html',
  styleUrls: ['./video-viewer-container.component.scss'],
})
export class VideoViewerContainerComponent implements OnInit, OnDestroy {
  messageRecipientId: number;
  messageId: number;
  messageRecipient$: Observable<MessageRecipient>;
  messageRecipientSubscription: Subscription;
  message: Message;

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<MessagesState>
  ) {}

  ngOnInit(): void {
    this.route.params.forEach((params: Params) => {
      this.messageRecipientId = +params['messageRecipientId'];
      this.messageId = +params['messageId'];
    });

    this.messageRecipient$ = this.store$.select(
      selectMessageRecipientById(this.messageRecipientId)
    );

    this.messageRecipientSubscription = this.messageRecipient$.subscribe(
      (mr: MessageRecipient) => {
        this.message = mr.message_set.messages.find(
          (message: Message) => message.id === this.messageId
        );
      }
    );
  }

  ngOnDestroy() {
    this.messageRecipientSubscription.unsubscribe();
  }

  onCloseVideoViewer($event) {
    console.log('video player closed!');
    this.zone.run(() => {
      this.router.navigate([
        '/viewers/default/',
        this.messageRecipientId,
        this.messageId,
      ]);
    });
  }
}
