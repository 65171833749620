import { createAction, props } from '@ngrx/store';
import { SupporterResidentAssociation } from '../core/models/supporter-resident-association.model';
import { AssistanceRequest } from '../core/models/assistance-request.model';
import { AssistanceProvider } from '../core/models/assistance-provider.model';

export const getAssistanceProviders = createAction(
  '[Presence] Get Assistance Providers'
);

export const getAssistanceProvidersSuccess = createAction(
  '[Presence] Get Assistance Providers Success',
  props<{ assistanceProviders: AssistanceProvider[] }>()
);

export const getAssistanceProvidersFailure = createAction(
  '[Presence] Get Assistance Providers Failure',
  props<{ error: Error }>()
);

export const sendAssistanceRequest = createAction(
  '[Presence] Send Assistance Request',
  props<{ assistanceRequest: AssistanceRequest }>()
);

export const sendAssistanceRequestSuccess = createAction(
  '[Presence] Send Assistance Request Success'
);

export const sendAssistanceRequestFailure = createAction(
  '[Presence] Send Assistance Request Failure'
);

export const sendSupporterAssistanceRequest = createAction(
  '[Presence] Send Supporter Assistance Request',
  props<{ supporterResidentAssociation: SupporterResidentAssociation }>()
);

export const sendSupporterAssistanceRequestSuccess = createAction(
  '[Presence] Send Supporter Assistance Request Success'
);

export const sendSupporterAssistanceRequestFailure = createAction(
  '[Presence] Send Supporter Assistance Request Failure'
);
