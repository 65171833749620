import { CallComponent } from './call/call.component';
import { REMOTE_KEY_SETS } from '../core/services/remote-control.service';
import { RemoteControlGuard } from '../core/guards/remote-control-guard.service';
import { AuthGuard } from '../core/guards/auth-guard.service';
import { AcceptCallComponent } from './accept-call/accept-call.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RequestCallDialogComponent } from './request-call-dialog/request-call-dialog.component';
import { RequestCallConfirmationDialogComponent } from './request-call-confirmation-dialog/request-call-confirmation-dialog.component';
import { AcceptCallWithDelayComponent } from './accept-call-with-delay/accept-call-with-delay.component';

const routes: Routes = [
  {
    path: 'accept-call',
    component: AcceptCallComponent,
    canActivate: [AuthGuard],
    // data: { keySet: REMOTE_KEY_SETS.ok_arrows }
  },
  {
    path: 'accept-call-with-delay',
    component: AcceptCallWithDelayComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'call-viewer',
    component: CallComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows },
  },
  {
    path: 'request-call',
    component: RequestCallDialogComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
  {
    path: 'request-call-confirmation',
    component: RequestCallConfirmationDialogComponent,
    canActivate: [AuthGuard, RemoteControlGuard],
    data: { keySet: REMOTE_KEY_SETS.ok_arrows_back },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ConferencingRoutingModule {}
