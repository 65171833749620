import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { SetupRoutingModule } from './setup-routing.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { CompleteComponent } from './complete/complete.component';
import { ErrorComponent } from './error/error.component';

@NgModule({
  imports: [SharedModule, SetupRoutingModule],
  declarations: [
    WelcomeComponent,
    AuthenticateComponent,
    CompleteComponent,
    ErrorComponent,
  ],
})
export class SetupModule {}
