import { LoggerService } from './../../core/services/logger.service';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserState } from 'src/app/state/user.reducer';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Customer } from 'src/app/core/models/customer.model';
import { getCurrentCustomer } from 'src/app/state/user.selectors';
import { TranslateService } from '@ngx-translate/core';
import * as UserActions from 'src/app/state/user.actions';

@Component({
  selector: 'app-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss'],
})
export class CompleteComponent implements OnInit, OnDestroy {
  currentCustomer$: Observable<Customer>;
  currentCustomer: Customer;
  currentCustomerSubscription: Subscription;

  constructor(
    private router: Router,
    private logger: LoggerService,
    private store$: Store<UserState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.currentCustomer$ = this.store$.select(getCurrentCustomer);
    this.currentCustomerSubscription = this.currentCustomer$.subscribe(
      (customer: Customer) => (this.currentCustomer = customer)
    );
  }

  ngOnDestroy() {
    this.currentCustomerSubscription.unsubscribe();
  }

  onOk($event: any) {
    this.logger.info('customer: ' + this.currentCustomer.name);
    this.store$.dispatch(UserActions.navigateToHome());
  }
}
