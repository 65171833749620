import { createAction, props } from '@ngrx/store';
import { BaseUser } from '../core/models/base-user.model';
import { Customer } from '../core/models/customer.model';
import {
  ConferencingSubscription,
  CallOptions,
  CallType,
} from './conferencing.reducer';
import {
  AudioInputTest,
  AudioOutputTest,
  DiagnosticError,
  MediaConnectionBitrateTest,
  VideoInputTest,
  WarningName,
} from '@twilio/rtc-diagnostics';

export const subscribeConferencingChannel = createAction(
  '[Conferencing] Subscribe Conferencing Channel',
  props<{ customer: Customer; user: BaseUser }>()
);

export const subscribeConferencingChannelSuccess = createAction(
  '[Conferencing] Subscribe Conferencing Channel Success',
  props<{ subscription: ConferencingSubscription }>()
);

export const subscribeConferencingChannelFailure = createAction(
  '[Conferencing] Subscribe Conferencing Channel Failure',
  props<{ subscription: ConferencingSubscription }>()
);

// Outbound Actions
export const requestCall = createAction(
  '[Conferencing][Outbound] Request Call',
  props<{ callOptions: CallOptions }>()
);

export const acceptCall = createAction('[Conferencing][Outbound] Accept Call');

export const declineCall = createAction(
  '[Conferencing][Outbound] Decline Call',
  props<{ reason: string }>()
);

export const endCall = createAction(
  '[Conferencing][Outbound] End Call',
  props<{ callType: CallType }>()
);

// Inbound Actions

export const acceptCallRedirect = createAction(
  '[Conferencing] Accept Call Redirect'
);

export const acceptCallWithDelayRedirect = createAction(
  '[Conferencing] Accept Call With Delay Redirect'
);

export const callViewerRedirect = createAction(
  '[Conferencing] Call Viewer Redirect'
);

export const callRequested = createAction(
  '[Conferencing] Call Requested',
  props<{ callOptions: CallOptions }>()
);

export const callAccepted = createAction('[Conferencing] Call Accepted');

export const callDeclined = createAction('[Conferencing] Call Declined');

export const callEnded = createAction('[Conferencing] Call Ended');

export const showCallViewer = createAction(
  '[Conferencing] Show Call Viewer',
  props<{ callType: CallType; remoteUser: BaseUser }>()
);

export const resetState = createAction('[Conferencing] Reset State');

export const noOp = createAction('[Conferencing] No Op');

// Diagnostic Actions

export const testVideoInputDevices = createAction(
  '[Conferencing] Test Video Input Devices',
  props<{ options?: VideoInputTest.Options }>()
);

export const testVideoInputDevicesError = createAction(
  '[Conferencing] Test Video Input Devices Error',
  props<{ error: DiagnosticError }>()
);

export const testVideoInputDevicesEnd = createAction(
  '[Conferencing] Test Video Input Devices End',
  props<{ report: VideoInputTest.Report }>()
);

export const testAudioInputDevices = createAction(
  '[Conferencing] Test Audio Input Devices',
  props<{ options?: AudioInputTest.Options }>()
);

export const testAudioInputDevicesVolume = createAction(
  '[Conferencing] Test Audio Input Devices Volume',
  props<{ volume: number }>()
);

export const testAudioInputDevicesWarning = createAction(
  '[Conferencing] Test Audio Input Devices Warning',
  props<{ warningName: WarningName }>()
);

export const testAudioInputDevicesWarningCleared = createAction(
  '[Conferencing] Test Audio Input Devices Warning Cleared',
  props<{ warningName: WarningName }>()
);

export const testAudioInputDevicesError = createAction(
  '[Conferencing] Test Audio Input Devices Error',
  props<{ error: DiagnosticError }>()
);

export const testAudioInputDevicesEnd = createAction(
  '[Conferencing] Test Audio Input Devices End',
  props<{ report: AudioInputTest.Report }>()
);

export const testAudioOutputDevices = createAction(
  '[Conferencing] Test Audio Output Devices',
  props<{ options?: AudioOutputTest.Options }>()
);

export const testAudioOutputDevicesVolume = createAction(
  '[Conferencing] Test Audio Output Devices Volume',
  props<{ volume: number }>()
);

export const testAudioOutputDevicesError = createAction(
  '[Conferencing] Test Audio Output Devices Error',
  props<{ error: DiagnosticError }>()
);

export const testAudioOutputDevicesEnd = createAction(
  '[Conferencing] Test Audio Output Devices End',
  props<{ report: AudioOutputTest.Report }>()
);

export const testMediaConnectionBitrate = createAction(
  '[Conferencing] Test Media Connection Bitrate',
  props<{ options?: MediaConnectionBitrateTest.Options }>()
);

export const clearMediaConnectionBitrateTestResult = createAction(
  '[Conferencing] Clear Media Connection Bitrate Test Result'
);

export const testMediaConnectionBitrateBitrate = createAction(
  '[Conferencing] Test Media Connection Bitrate Bitrate',
  props<{ bitrate: number }>()
);

export const testMediaConnectionBitrateWarning = createAction(
  '[Conferencing] Test Media Connection Bitrate Warning',
  props<{ warningName: WarningName }>()
);

export const testMediaConnectionBitrateWarningCleared = createAction(
  '[Conferencing] Test Media Connection Bitrate Warning Cleared',
  props<{ warningName: WarningName }>()
);

export const testMediaConnectionBitrateError = createAction(
  '[Conferencing] Test Media Connection Bitrate Error',
  props<{ error: DiagnosticError }>()
);

export const testMediaConnectionBitrateEnd = createAction(
  '[Conferencing] Test Media Connection Bitrate End',
  props<{ report: MediaConnectionBitrateTest.Report }>()
);
