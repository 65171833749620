import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        let s = value as string;
        return s.substring(0, parseInt(args));
    }

}
