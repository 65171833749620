import { AssistanceRequestConfirmationDialogComponent } from './assistance-request-confirmation-dialog/assistance-request-confirmation-dialog.component';
import { RemoteControlGuard } from './../core/guards/remote-control-guard.service';
import { AuthGuard } from './../core/guards/auth-guard.service';
import { AssistanceRequestDialogComponent } from './assistance-request-dialog/assistance-request-dialog.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { REMOTE_KEY_SETS } from './../core/services/remote-control.service';

const routes: Routes = [
    {
        path: 'request-assistance',
        component: AssistanceRequestDialogComponent,
        canActivate: [AuthGuard, RemoteControlGuard],
        data: { keySet: REMOTE_KEY_SETS.ok_arrows_back }
    },
    {
        path: 'request-assistance-confirmation',
        component: AssistanceRequestConfirmationDialogComponent,
        canActivate: [AuthGuard, RemoteControlGuard],
        data: { keySet: REMOTE_KEY_SETS.ok_arrows_back }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: []
})
export class AssistanceRoutingModule { }
