<div *ngIf="isMessageRecipientsSpinnerShowing$ | async; then loading_content else history_content"></div>
<ng-template #loading_content>
  <div id="loading-container">
<!--    <mat-progress-spinner mode="indeterminate" class="spinner"></mat-progress-spinner>-->
    <mat-progress-bar mode="indeterminate" class="bar"></mat-progress-bar>
  </div>
</ng-template>
<ng-template #history_content>
  <div
    id="history-container"
    *ngIf="currentMessageRecipient$ | async as messageRecipient"
  >
    <div id="header">
    <span id="timestamp">
      {{ messageRecipient.message_set.created | date: "medium" }}
    </span>
      <span id="message-count">
      {{ (currentMessageRecipientIndex$ | async) + 1 }} {{ "HISTORY_OF" | translate }}
        {{ messageRecipientCount$ | async }}
    </span>
    </div>
    <div
      class="message-set-wrapper"
      [ngClass]="{
      center:
        messageRecipient.message_set.messages[0].images.length === 0 &&
        messageRecipient.message_set.messages[0].videos.length === 0
    }"
    >
      <div
        class="thumbnail-container"
        *ngIf="
        messageRecipient.message_set.messages[0].images.length > 0 ||
        messageRecipient.message_set.messages[0].videos.length > 0
      "
      >
        <div
          class="image-thumbnail"
          *ngIf="messageRecipient.message_set.messages[0].images.length > 0"
        >
          <img
            [src]="messageRecipient.message_set.messages[0].images[0].large_url"
          />
        </div>
        <div
          class="video-thumbnail"
          *ngIf="messageRecipient.message_set.messages[0].videos.length > 0"
        >
          <img
            [src]="
            messageRecipient.message_set.messages[0].videos[0].encodings[0]
              .thumbnails[0].file
          "
            *ngIf="
            messageRecipient.message_set.messages[0].videos[0].encodings
              .length > 0
          "
          />
          <svg
            class="icon grid"
            viewBox="0 0 32 32"
            *ngIf="
            messageRecipient.message_set.messages[0].videos[0].encodings
              .length == 0
          "
          >
            <use xlink:href="#icon-video"></use>
          </svg>
        </div>
        <div class="key">
          <div class="zoom-icon"></div>
          <span>{{
            messageRecipient.message_set.messages[0].images.length > 0
              ? "zoom"
              : "play"
            }}</span>
        </div>
      </div>

      <div class="message-content">
        <div class="subject">
          <h2
            *ngIf="
            messageRecipient.message_set.messages[0].images[0] ||
            messageRecipient.message_set.messages[0].videos[0]
          "
          >
            {{ messageRecipient.message_set.messages[0].subject | truncate: 40 }}
          </h2>
          <h2
            *ngIf="
            !messageRecipient.message_set.messages[0].images[0] &&
            !messageRecipient.message_set.messages[0].videos[0]
          "
          >
            {{ messageRecipient.message_set.messages[0].subject | truncate: 55 }}
          </h2>
        </div>
        <div class="body">
          <p
            *ngIf="
            messageRecipient.message_set.messages[0].images[0] ||
            messageRecipient.message_set.messages[0].videos[0]
          "
          >
            {{ messageRecipient.message_set.messages[0].body | truncate: 180 }}
          </p>
          <p
            *ngIf="
            !messageRecipient.message_set.messages[0].images[0] &&
            !messageRecipient.message_set.messages[0].videos[0]
          "
          >
            {{ messageRecipient.message_set.messages[0].body | truncate: 235 }}
          </p>
        </div>
      </div>
    </div>
    <div id="footer" *ngIf="messageRecipient.recipient">
      <h3 *ngIf="messageRecipient.responses.length > 0">
        {{ "HISTORY_RESPONSE" | translate }}
        <span class="response-label">{{
          messageRecipient.responses[0].choice.choice_text | uppercase
          }}</span>
        {{ "HISTORY_AT" | translate }}
        {{ messageRecipient.status_changed | date: "medium" }}
      </h3>
      <h3 *ngIf="messageRecipient.responses.length === 0">
        {{ "HISTORY_NO_RESPONSE" | translate }}
      </h3>
    </div>
  </div>
</ng-template>
