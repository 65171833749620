<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'PROFILE_PINCODE_TITLE' | translate }}"
>
  <h3 style="text-align: center">
    {{ "PROFILE_PINCODE_MESSAGE" | translate }}
  </h3>
  <cm-pin [pinCode]="pinCode$ | async" style="margin-top: 30px"></cm-pin>
</cm-overlay>
