import { Customer } from '../models/customer.model';
import { LoggerService } from './logger.service';
import { Supporter } from '../models/supporter.model';
import { Resident } from '../models/resident.model';
import { Observable, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { PusherService } from './pusher.service';

import { Channel } from 'pusher-js';
import { UserState } from 'src/app/state/user.reducer';
import * as UserActions from 'src/app/state/user.actions';
import * as UserSelectors from 'src/app/state/user.selectors';
import * as RootActions from '../../state/root.actions';
import { Store } from '@ngrx/store';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable()
export class CustomerPresenceService implements OnDestroy {
  channel;
  channelName: string;

  showDebug: boolean;
  showDebug$: Observable<boolean>;
  showDebugSubscription: Subscription;

  constructor(
    private pusher: PusherService,
    private logger: LoggerService,
    private store$: Store<UserState>,
    private matSnackBar: MatSnackBar
  ) {
    this.showDebugSubscription = this.store$
      .select(UserSelectors.selectShowDebug)
      .subscribe((showDebug) => {
        this.showDebug = showDebug;
      });
  }

  ngOnDestroy(): void {
    this.showDebugSubscription.unsubscribe();
  }

  getSnackBarConfig(cssClass): MatSnackBarConfig {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'left';
    config.verticalPosition = 'top';
    config.panelClass = [`snackbar-${cssClass}`];
    return config;
  }

  public subscribe(customer: Customer): void {
    this.channelName = `private-presence-${customer.id}`;
    this.channel = this.pusher.subscribe(this.channelName);

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_succeeded',
      (channel) => {
        this.store$.dispatch(
          RootActions.subscribeCustomerChannelSuccess({
            channelName: this.channelName,
          })
        );
        this.logger.info(
          'customer presence : subscription succeeded: ' + this.channel.name
        );
      }
    );

    this.pusher.bindAllChannelEvent(this.channel, (status) => {
      this.logger.info('BIND ALL: ', status);
    });

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'customer presence : subscription FAILED: ' + this.channel.name
        );
        this.logger.error(status);
      }
    );

    // bind to customer_updated event
    this.pusher.bindChannelEvent(
      this.channel,
      'customer_updated',
      this.handleCustomerUpdated.bind(this)
    );

    // bind to resident_updated event
    this.pusher.bindChannelEvent(
      this.channel,
      'resident_updated',
      this.handleResidentUpdated.bind(this)
    );
  }

  public unsubscribe(): void {
    this.pusher.unsubscribe(this.channel.name);
    this.logger.info(
      `customer presence : unsubscribed channel : ${this.channel.name}`
    );
  }

  public subscribePresenceUpdatesForSupporter(supporter: Supporter): void {
    this.pusher.bindChannelEvent(
      this.channel,
      `presence_update_${supporter.id}`,
      this.handlePresenceUserUpdated.bind(this)
    );
    this.logger.info(
      `customer presence : subscribed updates for user : ${supporter.username}`
    );
  }

  public unSubscribePresenceUpdatesForSupporter(supporter: Supporter): void {
    this.pusher.unbindChannelEvent(
      this.channel,
      `presence_update_${supporter.id}`,
      () => {}
    );
    this.logger.info(
      `customer presence : unsubscribed updates for user : ${supporter.username}`
    );
  }

  private handlePresenceUserUpdated(userData: any): void {
    const user = userData;
    const message = `presence: ${user.username} is ${
      user.online ? 'online' : 'offline'
    }`;
    if (this.showDebug) {
      const cssClass = user.online === true ? 'success' : 'warning';
      this.matSnackBar.open(message, '', this.getSnackBarConfig(cssClass));
    }
    this.logger.log(message);
    this.store$.dispatch(RootActions.customerUserPresenceUpdated({ user }));
  }

  private handleCustomerUpdated(customer: Customer): void {
    this.store$.dispatch(UserActions.updateCustomer({ customer }));
    this.logger.info(`customer update received: ${JSON.stringify(customer)}`);
  }

  private handleResidentUpdated(resident: Resident): void {
    this.store$.dispatch(UserActions.updateResident({ resident }));
    this.logger.info(`resident update received: ${JSON.stringify(resident)}`);
  }
}
