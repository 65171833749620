import { Component, OnInit } from '@angular/core';
import { UserState } from 'src/app/state/user.reducer';
import { Store } from '@ngrx/store';

import * as UserActions from '../../state/user.actions';
import { userChannelCallRequest } from 'src/app/state/root.actions';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss'],
})
export class AuthenticateComponent implements OnInit {
  constructor(private store$: Store<UserState>) {}

  ngOnInit() {
    this.store$.dispatch(UserActions.getMacAddress());
  }
}
