import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { VideoInputTest, VideoResolution } from '@twilio/rtc-diagnostics';
import * as ConferencingActions from '../../../../state/conferencing.actions';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

@Component({
  selector: 'cm-local-video-preview',
  templateUrl: './local-video-preview.component.html',
  styleUrls: ['./local-video-preview.component.scss'],
})
export class LocalVideoPreviewComponent implements OnInit {
  translateStrings: any;
  counter = 10;

  @ViewChild('localMediaContainer') localMediaContainer: ElementRef;

  constructor(
    private translate: TranslateService,
    private store$: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    const tokens = [
      'DIAGNOSTICS_TITLE',
      'DIAGNOSTICS_CAMERA_TEST',
      'DIAGNOSTICS_EXIT',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));

    setTimeout(() => {
      const options: VideoInputTest.Options = {
        debug: false,
        duration: 10000,
        resolution: { width: 1080, height: 720 } as VideoResolution,
        element: this.localMediaContainer.nativeElement as HTMLVideoElement,
      };
      this.store$.dispatch(
        ConferencingActions.testVideoInputDevices({ options })
      );
    }, 50);

    setTimeout(() => {
      this.goToDiagnostics();
    }, 11000);

    setInterval(() => this.counter--, 1000);
  }

  goToDiagnostics(): void {
    this.router.navigate(['dashboard/diagnostics/camera-test']);
  }
}
