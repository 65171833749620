<div
  tabindex="0"
  [attr.data-choice-id]="choice.id"
  [@focusedTrigger]="active"
  [cmFocus]="focused"
  class="cm-choice-selector-choice"
  [ngStyle]="{ 'width.px': choiceWidth, 'height.px': choiceHeight }"
  [ngClass]="{ active: focused }"
>
  <cm-avatar-thumb
    [user]="choice.user"
    [size]="avatarSize"
    *ngIf="showAvatar && choice.user"
  ></cm-avatar-thumb>
  <mat-icon
    [svgIcon]="choice.icon"
    [ngStyle]="{ 'width.px': iconWidth, 'height.px': iconHeight }"
    *ngIf="showIcon && choice.icon"
  ></mat-icon>
  <h4
    *ngIf="choice.label !== ''"
    class="label"
    [style.text-align]="
      showAvatar || showIcon || showToggle ? 'left' : 'center'
    "
  >
    {{ choice.label | uppercase }}
  </h4>
  <div class="current-choice" *ngIf="isCurrentChoice"></div>
  <cm-toggle-switch
    [active]="choice.active"
    *ngIf="showToggle"
  ></cm-toggle-switch>
</div>
