import {
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer,
  Action,
  ActionReducerMap,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';

import merge from 'lodash.merge';
const INIT_ACTION = '@ngrx/store/init';
const UPDATE_ACTION = '@ngrx/store/update-reducers';

import * as fromRouter from '@ngrx/router-store';

import * as fromUser from './user.reducer';
import * as fromPresence from './presence.reducer';
import * as fromConferencing from './conferencing.reducer';
import * as fromMessages from './messages.reducer';
import * as fromStb from './stb.reducer';

import * as UserActions from './user.actions';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import { InjectionToken } from '@angular/core';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */

export interface State {
  // [fromLayout.layoutFeatureKey]: fromLayout.State;
  router: fromRouter.RouterReducerState<any>;
  [fromUser.userFeatureKey]: fromUser.UserState;
  [fromPresence.presenceFeatureKey]: fromPresence.PresenceState;
  [fromConferencing.conferencingFeatureKey]: fromConferencing.ConferencingState;
  [fromMessages.messagesFeatureKey]: fromMessages.MessagesState;
  [fromStb.stbFeatureKey]: fromStb.StbState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    //    [fromLayout.layoutFeatureKey]: fromLayout.reducer,
    router: fromRouter.routerReducer,
    [fromUser.userFeatureKey]: fromUser.userReducer,
    [fromPresence.presenceFeatureKey]: fromPresence.presenceReducer,
    [fromConferencing.conferencingFeatureKey]:
      fromConferencing.conferencingReducer,
    [fromMessages.messagesFeatureKey]: fromMessages.messagesReducer,
    [fromStb.stbFeatureKey]: fromStb.stbReducer,
  }),
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

// ngrx-store-localstorage
const mergeReducer = (
  state: State,
  rehydratedState: State,
  action: Action
): State => {
  if (
    (action.type === INIT_ACTION || action.type === UPDATE_ACTION) &&
    rehydratedState
  ) {
    state = merge(state, rehydratedState);
  }

  return state;
};

function localStorageSyncReducer(
  reducer: ActionReducer<State>
): ActionReducer<State> {
  return localStorageSync({
    keys: [
      fromUser.userFeatureKey,
      // { featureOne: ['sliceOne', 'sliceTwo'] },
      // { featureTwo: ['sliceOne', 'sliceThree'] },
    ],
    rehydrate: true,
    mergeReducer,
  })(reducer);
}

function logout(reducer) {
  return function (state, action) {
    if (action.type === UserActions.logout.type) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */

export const metaReducers: Array<MetaReducer<State, any>> =
  !environment.production
    ? [logout, logger, localStorageSyncReducer]
    : [logout, localStorageSyncReducer];

/**
 * Router Selectors
 */
export const selectRouter =
  createFeatureSelector<fromRouter.RouterReducerState>('router');

export const { selectRouteData } = fromRouter.getSelectors(selectRouter);
