import {
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MenuItem } from '../../../core/models/menu-item.model';
import { ASCII_REMOTE_KEYS } from '../../../core/services/remote-control.service';
import { VideoInputTest } from '@twilio/rtc-diagnostics';
import { Observable } from 'rxjs';

import * as ConferencingSelectors from '../../../state/conferencing.selectors';
import * as ConferencingActions from '../../../state/conferencing.actions';
import { VideoInputDiagnostics } from '../../../state/conferencing.reducer';

@Component({
  selector: 'cm-video-input-test',
  templateUrl: './video-input-test.component.html',
  styleUrls: ['./video-input-test.component.scss'],
})
export class VideoInputTestComponent implements OnInit {
  translateStrings: any;
  diagnosticItems: MenuItem[];

  cameraConnected$: Observable<boolean>;
  videoInputDiagnostics$: Observable<VideoInputDiagnostics>;

  @ViewChild('localMediaContainer') localMediaContainer: ElementRef;

  constructor(
    private translate: TranslateService,
    private store$: Store,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    const tokens = [
      'DIAGNOSTICS_TITLE',
      'DIAGNOSTICS_CAMERA_TEST',
      'DIAGNOSTICS_START',
      'DIAGNOSTICS_EXIT',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));

    const options: VideoInputTest.Options = {
      debug: false,
      duration: 500,
    };

    this.videoInputDiagnostics$ = this.store$.select(
      ConferencingSelectors.selectVideoInputDiagnostics
    );

    this.cameraConnected$ = this.store$.select(
      ConferencingSelectors.selectCameraConnected
    );

    this.diagnosticItems = [
      {
        id: 1,
        icon: 'cm:video-camera',
        tag: 'start',
        label: this.translateStrings.DIAGNOSTICS_START,
      },
      {
        id: 2,
        icon: 'cm:cross-circle',
        tag: 'exit',
        label: this.translateStrings.DIAGNOSTICS_EXIT,
      },
    ];

    this.store$.dispatch(ConferencingActions.testVideoInputDevices({}));
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToCameraTest();
    }
  }

  onItemSelected(item: MenuItem): void {
    switch (item.tag) {
      case 'start':
        this.goToLocalVideoPreview();
        break;
      case 'exit':
        this.goToCameraTest();
        break;
    }
  }

  goToLocalVideoPreview(): void {
    this.router.navigate([
      'dashboard/diagnostics/camera-test/local-video-preview',
    ]);
  }

  goToCameraTest(): void {
    this.router.navigate(['dashboard/diagnostics']);
  }
}
