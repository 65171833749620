import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MessageImage } from '../core/models/message-image.model';
import { MessageRecipient } from '../core/models/message-recipient.model';
import { MessageVideo } from '../core/models/message-video.model';
import { Message } from '../core/models/message.model';
import { MessagesState, messagesFeatureKey } from './messages.reducer';

const getMessagesFeatureState =
  createFeatureSelector<MessagesState>(messagesFeatureKey);

export const selectMessageRecipientCount = createSelector(
  getMessagesFeatureState,
  (state) => state.count
);

export const selectUnreadMessageRecipientCount = createSelector(
  getMessagesFeatureState,
  (state) =>
    state.messageRecipients.filter(
      (mr) =>
        mr.status === 'unread' ||
        mr.status === 'pending' ||
        mr.status === 'delivered'
    ).length
);

export const selectUnreadMessageRecipients = createSelector(
  getMessagesFeatureState,
  (state) =>
    state.messageRecipients.filter(
      (mr) =>
        mr.status === 'unread' ||
        mr.status === 'pending' ||
        mr.status === 'delivered'
    )
);

export const selectMessageRecipients = createSelector(
  getMessagesFeatureState,
  (state) => state.messageRecipients
);

export const selectMessageRecipientById = (messageRecipientId: number) =>
  createSelector(getMessagesFeatureState, (state) => {
    return state.messageRecipients.find((mr) => mr.id === messageRecipientId);
  });

export const selectNextUrl = createSelector(
  getMessagesFeatureState,
  (state) => state.next
);

export const isMessageRecipientsSpinnerShowing = createSelector(
  getMessagesFeatureState,
  (state) => state.showMessageRecipientsSpinner
);

export const selectCurrentMessageRecipient = createSelector(
  getMessagesFeatureState,
  (state) =>
    state.messageRecipients.find(
      (mr) => mr.id === state.currentMessageRecipientId
    )
);

export const selectCurrentMessageRecipientId = createSelector(
  getMessagesFeatureState,
  (state) => state.currentMessageRecipientId
);

export const selectCurrentMessageRecipientIndex = createSelector(
  getMessagesFeatureState,
  (state) =>
    state.messageRecipients.findIndex(
      (mr) => mr.id === state.currentMessageRecipientId
    )
);

export const selectAllImages = createSelector(
  getMessagesFeatureState,
  (state) => {
    let images: MessageImage[] = [];
    state.messageRecipients.forEach((mr: MessageRecipient) => {
      mr.message_set.messages.forEach((message: Message) => {
        images =
          message.images.length > 0
            ? [...images, ...message.images]
            : [...images];
      });
    });
    return images;
  }
);

export const selectAllVideos = createSelector(
  getMessagesFeatureState,
  (state) => {
    let videos: MessageVideo[] = [];
    state.messageRecipients.forEach((mr: MessageRecipient) => {
      mr.message_set.messages.forEach((message: Message) => {
        videos =
          message.videos.length > 0
            ? [...videos, ...message.videos]
            : [...videos];
      });
    });
    return videos;
  }
);

export const selectAllMedia = createSelector(
  getMessagesFeatureState,
  (state) => {
    let media: Array<MessageImage | MessageVideo> = [];
    state.messageRecipients.forEach((mr: MessageRecipient) => {
      mr.message_set.messages.forEach((message: Message) => {
        media =
          message.images.length > 0
            ? [...media, ...message.images]
            : [...media];
        media =
          message.videos.length > 0
            ? [...media, ...message.videos]
            : [...media];
      });
    });
    return media;
  }
);
