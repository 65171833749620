import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state/user.reducer';
import { Store } from '@ngrx/store';
import { first, flatMap } from 'rxjs/operators';
import { getToken } from 'src/app/state/user.selectors';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public store$: Store<UserState>) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url !==
      `${environment.apiUrl.substring(
        0,
        environment.apiUrl.length - 3
      )}/stb/api-token-auth/`
    ) {
      return this.store$.select(getToken).pipe(
        first(),
        flatMap((token) => {
          const authReq = !!token
            ? request.clone({
                setHeaders: { Authorization: `Bearer ${token}` },
              })
            : request;
          return next.handle(authReq);
        })
      );
    }
    return next.handle(request);
  }
}
