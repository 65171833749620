import { LoggerService } from './../../../core/services/logger.service';
import {
  Component,
  OnInit,
  Input,
  Renderer2,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'cm-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnInit, OnChanges {
  @Input() active: boolean;
  @ViewChild('switch') switch: ElementRef;

  private switchElement: HTMLDivElement;

  constructor(private logger: LoggerService, private renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.switchElement = this.switch.nativeElement;
    this.setState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('active')) {
      this.setState();
    }
  }

  setState() {
    if (!this.active) {
      this.renderer.addClass(this.switchElement, 'on');
      this.renderer.removeClass(this.switchElement, 'off');
    } else {
      this.renderer.addClass(this.switchElement, 'off');
      this.renderer.removeClass(this.switchElement, 'on');
    }
  }
}
