import {
  Component,
  Input,
  ElementRef,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'cm-avatar-thumb',
  templateUrl: 'avatar-thumb.component.html',
  styleUrls: ['./avatar-thumb.component.scss'],
})
export class AvatarThumbComponent implements OnInit, OnChanges {
  @Input() user;
  @Input() size = 20;
  @Input() hidePresence = false;

  public img: HTMLImageElement;
  public hidden: boolean;
  public src = 'assets/images/no-avatar.png';

  constructor(public el: ElementRef) {}

  ngOnInit(): void {
    this.updateImage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.hasOwnProperty('user') &&
      changes['user'].currentValue &&
      changes['user'].currentValue.avatar_large
    ) {
      this.src = changes['user'].currentValue.avatar_large;
      this.updateImage();
    }
  }

  updateImage(): void {
    this.img = this.el.nativeElement.querySelector('img');
    if (this.img) {
      this.img.crossOrigin = 'Anonymous';
    }
  }

  /**
   * This function will show the image when it has loaded
   */
  load(): void {
    this.hidden = false;
  }

  /**
   * This function will be triggered when http request fails
   */
  error(): void {
    this.img.remove();
  }
}
