<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'MENU_CONFERENCING_TITLE' | translate }}"
>
  <cm-choice-selector
    *ngIf="choices | async; let choices; else: loading"
    [choices]="choices"
    (choiceSelected)="onChoiceSelected($event)"
    [columns]="1"
    [choiceWidth]="1000"
    [choiceHeight]="140"
    [showAvatar]="true"
  >
  </cm-choice-selector>
  <ng-template #loading>
    <mat-spinner></mat-spinner>
  </ng-template>
</cm-overlay>
