import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

const getUserFeatureState = createFeatureSelector<UserState>('profile');

export const selectShowDebug = createSelector(
  getUserFeatureState,
  (state) => state.showDebug
);

export const selectCurrentUser = createSelector(
  getUserFeatureState,
  (state) => state.user
);

export const getCurrentCustomer = createSelector(
  getUserFeatureState,
  (state) => state.customer
);

export const getToken = createSelector(
  getUserFeatureState,
  (state) => state.token
);

export const selectToken = createSelector(
  getUserFeatureState,
  (state) => state.token
);

export const selectTokenRefreshInterval = createSelector(
  getUserFeatureState,
  (state) => state.tokenRefreshInterval
);

export const selectTokenRefreshFailureMessage = createSelector(
  getUserFeatureState,
  (state) => state.tokenRefreshFailureMessage
);

export const selectIsChurch = createSelector(
  getUserFeatureState,
  (state) => state.user !== undefined && state.user.customer.name === 'RO'
);

export const selectIsDoro = createSelector(
  getUserFeatureState,
  (state) => state.user !== undefined && state.user.customer.name === 'Doro'
);

export const selectMacAddress = createSelector(
  getUserFeatureState,
  (state) => state.macAddress
);

export const getProfile = createSelector(getUserFeatureState, (state) => state);

export const getFailureMessage = createSelector(
  getUserFeatureState,
  (state) => state.failureMessage
);

export const selectPinCode = createSelector(
  getUserFeatureState,
  (state) => state.pinCode
);

export const selectDeliverToInbox = createSelector(
  getUserFeatureState,
  (state) => state.user.deliver_to_inbox
);

export const selectPusherStatus = createSelector(
  getUserFeatureState,
  (state) => state.pusherStatus
);

export const selectIsHcap = createSelector(
  getUserFeatureState,
  (state) => state.isHcap
);

export const selectIsIdcap = createSelector(
  getUserFeatureState,
  (state) => state.isIdcap
);

export const selectSetTopBox = createSelector(
  getUserFeatureState,
  (state) => state.user.set_top_box
);
