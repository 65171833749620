import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  withLatestFrom,
  concatMap,
  map,
  catchError,
  exhaustMap,
  tap,
} from 'rxjs/operators';
import { ApiResponse } from '../core/models/api-response';
import { MessageRecipientService } from '../core/services/message-recipient.service';
import { getProfile } from './user.selectors';
import * as MessagesActions from './messages.actions';
import { MessageRecipient } from '../core/models/message-recipient.model';
import {
  selectCurrentMessageRecipientIndex,
  selectMessageRecipients,
  selectNextUrl,
  selectUnreadMessageRecipients,
} from './messages.selectors';
import { MessagesState } from './messages.reducer';
import { PreviousRouteService } from '../core/services/previous-route.service';

@Injectable()
export class MessagesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<MessagesState>,
    private messageRecipientService: MessageRecipientService,
    private router: Router,
    private previousRouteService: PreviousRouteService
  ) {}

  getMessageRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.getMessageRecipients),
      withLatestFrom(this.store$.select(getProfile)),
      concatMap(([action, profile]) => {
        return this.messageRecipientService
          .getMessageRecipients(profile.user)
          .pipe(
            map((apiResponse: ApiResponse<MessageRecipient>) =>
              MessagesActions.getMessageRecipientsSuccess({ apiResponse })
            ),
            catchError((error: Error) =>
              of(MessagesActions.getMessageRecipientsFailed(error))
            )
          );
      })
    )
  );

  getMessageRecipientsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.getMessageRecipientsSuccess),
      withLatestFrom(this.store$.select(selectUnreadMessageRecipients)),
      exhaustMap(([action, messageRecipients]) =>
        of(MessagesActions.markAsDelivered({ messageRecipients }))
      )
    )
  );

  getMessageRecipient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.getMessageRecipient),
      concatMap((action) => {
        return this.messageRecipientService
          .getMessageRecipient(action.message_set_id, action.recipient_id)
          .pipe(
            map((messageRecipient: MessageRecipient) =>
              MessagesActions.getMessageRecipientSuccess({ messageRecipient })
            )
          );
      })
    )
  );

  loadNextMessageRecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.loadNextMessageRecipients),
      withLatestFrom(
        this.store$.select(getProfile),
        this.store$.select(selectNextUrl)
      ),
      concatMap(([action, profile, url]) => {
        return this.messageRecipientService
          .getMessageRecipients(profile.user, url)
          .pipe(
            map((apiResponse: ApiResponse<MessageRecipient>) =>
              MessagesActions.loadNextMessageRecipientsSuccess({ apiResponse })
            ),
            catchError((error: Error) =>
              of(MessagesActions.loadNextMessageRecipientsFailure(error))
            )
          );
      })
    )
  );

  moveToNextMessageRecipient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.moveToNextMessageRecipient),
      withLatestFrom(
        this.store$.select(selectMessageRecipients),
        this.store$.select(selectCurrentMessageRecipientIndex),
        this.store$.select(selectNextUrl)
      ),
      exhaustMap(([action, messageRecipients, currentIndex, nextUrl]) => {
        if (currentIndex < messageRecipients.length - 1) {
          if (
            messageRecipients.length - currentIndex === 2 &&
            nextUrl !== null
          ) {
            return [
              MessagesActions.loadNextMessageRecipients(),
              MessagesActions.setCurrentMessageRecipientId({
                messageRecipient: messageRecipients[currentIndex + 1],
              }),
            ];
          }
          return [
            MessagesActions.setCurrentMessageRecipientId({
              messageRecipient: messageRecipients[currentIndex + 1],
            }),
          ];
        }
        return [MessagesActions.noOp()];
      })
    )
  );

  moveToPreviousMessageRecipient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.moveToPreviousMessageRecipient),
      withLatestFrom(
        this.store$.select(selectMessageRecipients),
        this.store$.select(selectCurrentMessageRecipientIndex)
      ),
      map(([action, messageRecipients, currentIndex]) => {
        if (currentIndex > 0) {
          return MessagesActions.setCurrentMessageRecipientId({
            messageRecipient: messageRecipients[currentIndex - 1],
          });
        }
        return MessagesActions.noOp();
      })
    )
  );

  markAsDelivered$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.markAsDelivered),
      concatMap((action) => {
        return this.messageRecipientService
          .markAsDelivered(action.messageRecipients)
          .pipe(
            map((results) => MessagesActions.markAsDeliveredSuccess()),
            catchError((error) => {
              console.error(`${error}`);
              return of(MessagesActions.markAsDeliveredFailure());
            })
          );
      })
    )
  );

  sendResponses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.sendResponses),
      concatMap((action) => {
        return this.messageRecipientService.putMessageRecpient(action).pipe(
          map((mr: MessageRecipient) =>
            MessagesActions.sendResponsesSuccess(mr)
          ),
          catchError((error: Error) =>
            of(MessagesActions.sendResponsesFailure(error))
          )
        );
      })
    )
  );

  sendResponsesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.sendResponsesSuccess),
      map((action) => MessagesActions.redirectToListener())
    )
  );

  redirectToListener$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MessagesActions.redirectToListener),
        map((action) => this.router.navigate(['supervisor/listener']))
      ),
    { dispatch: false }
  );

  messageRecipientCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.messageRecipientCreated),
      tap((action) => console.log(`messageRecipientCreated!`)),
      concatMap((action) => [MessagesActions.getMessageRecipient(action)])
    )
  );

  messageRecipientCreatedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.getMessageRecipientSuccess),
      concatMap((action) => [
        MessagesActions.markAsDelivered({
          messageRecipients: [action.messageRecipient[0]],
        }),
      ])
    )
  );

  messageRecipientUpdated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessagesActions.messageRecipientUpdated),
      tap((action) => console.log(`messageRecipientUpdated!`)),
      concatMap((action) => [MessagesActions.getMessageRecipient(action)])
    )
  );
}
