import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from './root.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as StbActions from './stb.actions';
import * as UserActions from './user.actions';
import {
  catchError,
  concatMap,
  map,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { asyncScheduler, EMPTY, of, timer } from 'rxjs';
import * as UserSelectors from './user.selectors';
import { SetTopBoxService } from '../core/services/set-top-box.service';
import { IdcapService } from '../core/services/idcap.service';
import { selectSetTopBox } from './user.selectors';

@Injectable()
export class StbEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private matSnackBar: MatSnackBar,
    private idcapService: IdcapService,
    private setTopBoxService: SetTopBoxService
  ) {}

  setPowerWarm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StbActions.setPowerWarm),
        map((action) => {
          this.idcapService.setPowerModeWarmObs().subscribe();
        })
      ),
    { dispatch: false }
  );

  setPowerNormal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StbActions.setPowerNormal),
        map((action) => {
          this.idcapService.setPowerModeNormalObs().subscribe();
        })
      ),
    { dispatch: false }
  );

  standby$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StbActions.standby),
        map((action) => {
          this.idcapService.powerOffTvObs().subscribe();
        })
      ),
    { dispatch: false }
  );

  reboot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StbActions.reboot),
        map((action) => {
          this.idcapService.rebootTvObs().subscribe();
        })
      ),
    { dispatch: false }
  );

  reload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StbActions.reload),
        map((action) => {
          window.location.replace(window.location.origin);
        })
      ),
    { dispatch: false }
  );

  reset$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StbActions.reset),
        map((action) => {
          return UserActions.logout();
        })
      ),
    { dispatch: true }
  );

  getCpuUsage$ = createEffect(
    () =>
      ({ scheduler = asyncScheduler, stopTimer = EMPTY } = {}) =>
        this.actions$.pipe(
          ofType(StbActions.getCpuUsage),
          concatLatestFrom(() =>
            this.store$.select(UserSelectors.selectIsIdcap)
          ),
          switchMap(([action, isIdcap]) =>
            timer(0, 60000, scheduler).pipe(
              takeUntil(stopTimer),
              switchMap(() => {
                return this.idcapService.getCpuUsageObs().pipe(
                  map((response) =>
                    StbActions.getCpuUsageSuccess({
                      percentage: response.percentage,
                    })
                  ),
                  catchError((error) =>
                    of(StbActions.getCpuUsageFailure(error))
                  )
                );
              })
            )
          )
        )
  );

  getMemoryUsage$ = createEffect(
    () =>
      ({ scheduler = asyncScheduler, stopTimer = EMPTY } = {}) =>
        this.actions$.pipe(
          ofType(StbActions.getMemoryUsage),
          switchMap((action) =>
            timer(0, 60000, scheduler).pipe(
              takeUntil(stopTimer),
              switchMap(() => {
                return this.idcapService.getMemoryUsageObs().pipe(
                  map((response) =>
                    StbActions.getMemoryUsageSuccess({
                      percentage: response.percentage,
                    })
                  ),
                  catchError((error) =>
                    of(StbActions.getMemoryUsageFailure(error))
                  )
                );
              })
            )
          )
        )
  );

  getFirmwareVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StbActions.getFirmwareVersion),
      concatMap((action) => {
        return this.idcapService.getFirmwareVersionObs().pipe(
          map((result) =>
            StbActions.getFirmwareVersionSuccess({ version: result.value })
          ),
          catchError((error) => of(StbActions.getFirmwareVersionFailure(error)))
        );
      })
    )
  );

  getFirmwareVersionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StbActions.getFirmwareVersionSuccess),
      concatLatestFrom(() => [this.store$.select(selectSetTopBox)]),
      concatMap(([action, stb]) => {
        return this.setTopBoxService
          .patchSetTopBox({ id: stb.id, firmware_version: action.version })
          .pipe(
            map((setTopBox) => StbActions.patchSetTopBoxSuccess({ setTopBox })),
            catchError((error) => of(StbActions.patchSetTopBoxFailure(error)))
          );
      })
    )
  );

  getSerialNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StbActions.getSerialNumber),
      concatMap((action) => {
        return this.idcapService.getSerialNumberObs().pipe(
          map((result) =>
            StbActions.getSerialNumberSuccess({ serialNumber: result.value })
          ),
          catchError((error) => of(StbActions.getSerialNumberFailure(error)))
        );
      })
    )
  );

  getSerialNumberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StbActions.getSerialNumberSuccess),
      concatLatestFrom(() => [this.store$.select(selectSetTopBox)]),
      concatMap(([action, stb]) => {
        return this.setTopBoxService
          .patchSetTopBox({ id: stb.id, serial_number: action.serialNumber })
          .pipe(
            map((setTopBox) => StbActions.patchSetTopBoxSuccess({ setTopBox })),
            catchError((error) => of(StbActions.patchSetTopBoxFailure(error)))
          );
      })
    )
  );

  getModelNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StbActions.getModelNumber),
      concatMap((action) => {
        return this.idcapService.getModelNameObs().pipe(
          map((result) =>
            StbActions.getModelNumberSuccess({ modelNumber: result.value })
          ),
          catchError((error) => of(StbActions.getModelNumberFailure(error)))
        );
      })
    )
  );

  getModelNumberSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StbActions.getModelNumberSuccess),
      concatLatestFrom(() => [this.store$.select(selectSetTopBox)]),
      concatMap(([action, stb]) => {
        return this.setTopBoxService
          .patchSetTopBox({ id: stb.id, model_number: action.modelNumber })
          .pipe(
            map((setTopBox) => StbActions.patchSetTopBoxSuccess({ setTopBox })),
            catchError((error) => of(StbActions.patchSetTopBoxFailure(error)))
          );
      })
    )
  );
}
