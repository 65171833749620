<table #table class="choice-selector">
  <tr *ngFor="let row of choices | chunk: columns; let i = index">
    <td
      *ngFor="let choice of row; let j = index"
      cmChoiceSelectorChoice
      [choice]="choice"
      [isCurrentChoice]="currentChoice && currentChoice.id === choice.id"
      [choiceWidth]="choiceWidth"
      [choiceHeight]="choiceHeight"
      [iconWidth]="iconWidth"
      [iconHeight]="iconHeight"
      [focused]="columns === 1 ? i === 0 : i === 0 && j === 0"
      [showAvatar]="showAvatar"
      [avatarSize]="avatarSize"
      [showIcon]="showIcon"
      [showToggle]="showToggle"
    ></td>
  </tr>
</table>
