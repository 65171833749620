import { Injectable } from '@angular/core';
import {
  AudioInputTest,
  AudioOutputTest,
  DiagnosticError,
  MediaConnectionBitrateTest,
  testAudioInputDevice,
  testMediaConnectionBitrate,
  testVideoInputDevice,
  VideoInputTest,
  WarningName,
} from '@twilio/rtc-diagnostics';
import { TwilioVideoService } from './twilio-video.service';
import { ConferencingState } from '../../state/conferencing.reducer';
import { Store } from '@ngrx/store';

import * as ConferencingActions from '../../state/conferencing.actions';

@Injectable({
  providedIn: 'root',
})
export class TwilioRtcDiagnosticsService {
  constructor(
    private twilioVideoService: TwilioVideoService,
    private store$: Store<ConferencingState>
  ) {}

  public testMediaConnectionBitRate(
    options?: MediaConnectionBitrateTest.Options
  ): void {
    const iceServers = [];

    this.twilioVideoService.getAccessToken().subscribe((token: any) => {
      // Grab STUN server
      iceServers.push({
        urls: token.ice_servers.find((item) =>
          item.urls.includes('stun:global.stun.twilio.com')
        ).urls,
      });

      // Grab TURN servers.
      // Use the following filters if you want to use UDP, TCP, or TLS
      // UDP: turn:global.turn.twilio.com:3478?transport=udp
      // TCP: turn:global.turn.twilio.com:3478?transport=tcp
      // TLS: turn:global.turn.twilio.com:443?transport=tcp
      const { urls, username, credential } = token.ice_servers.find(
        (item) => item.url === 'turn:global.turn.twilio.com:3478?transport=udp'
      );
      iceServers.push({ urls, username, credential });

      // By default, global will be used as the default edge location.
      // You can replace global with a specific edge name.
      iceServers.forEach((iceServer) => {
        iceServer.urls = iceServer.urls.replace('global', 'frankfurt');
      });

      // Use the TURN credentials using the iceServers parameter
      const opts: MediaConnectionBitrateTest.Options = options
        ? options
        : {
            minBitrateThreshold: 300,
            iceServers,
          };

      const mediaConnectionBitrateTest = testMediaConnectionBitrate(opts);

      mediaConnectionBitrateTest.on(
        MediaConnectionBitrateTest.Events.Bitrate,
        (bitrate: number) => {
          this.store$.dispatch(
            ConferencingActions.testMediaConnectionBitrateBitrate({ bitrate })
          );
          console.log(bitrate);
        }
      );

      mediaConnectionBitrateTest.on(
        MediaConnectionBitrateTest.Events.Warning,
        (warningName: WarningName) => {
          this.store$.dispatch(
            ConferencingActions.testMediaConnectionBitrateWarning({
              warningName,
            })
          );
          console.warn(warningName);
        }
      );

      mediaConnectionBitrateTest.on(
        MediaConnectionBitrateTest.Events.WarningCleared,
        (warningName: WarningName) => {
          this.store$.dispatch(
            ConferencingActions.testMediaConnectionBitrateWarningCleared({
              warningName,
            })
          );
          console.warn(warningName);
        }
      );

      mediaConnectionBitrateTest.on(
        MediaConnectionBitrateTest.Events.Error,
        (error: DiagnosticError) => {
          this.store$.dispatch(
            ConferencingActions.testMediaConnectionBitrateError({ error })
          );
          console.log(error);
        }
      );

      mediaConnectionBitrateTest.on(
        MediaConnectionBitrateTest.Events.End,
        (report: MediaConnectionBitrateTest.Report) => {
          this.store$.dispatch(
            ConferencingActions.testMediaConnectionBitrateEnd({ report })
          );
          console.log(report);
        }
      );

      setTimeout(() => {
        mediaConnectionBitrateTest.stop();
      }, 10000);
    });
  }

  public testVideoInputDevices(options?: VideoInputTest.Options): void {
    const opts: VideoInputTest.Options = options
      ? options
      : { debug: false, duration: 200 };
    const videoInputTest: VideoInputTest = testVideoInputDevice(opts);
    videoInputTest.on(VideoInputTest.Events.Error, (error: DiagnosticError) => {
      this.store$.dispatch(
        ConferencingActions.testVideoInputDevicesError({ error })
      );
      console.error(error);
    });

    videoInputTest.on(
      VideoInputTest.Events.End,
      (report: VideoInputTest.Report) => {
        this.store$.dispatch(
          ConferencingActions.testVideoInputDevicesEnd({ report })
        );
        console.log(report);
      }
    );
  }

  public testAudioInputDevices(options?: AudioInputTest.Options): void {
    const opts: AudioInputTest.Options = options
      ? options
      : { debug: false, duration: 5000 };

    const audioInputDeviceTest = testAudioInputDevice(opts);

    audioInputDeviceTest.on(AudioInputTest.Events.Volume, (volume: number) => {
      this.store$.dispatch(
        ConferencingActions.testAudioInputDevicesVolume({ volume })
      );
      console.log(volume);
    });

    audioInputDeviceTest.on(
      AudioInputTest.Events.Warning,
      (warningName: WarningName) => {
        this.store$.dispatch(
          ConferencingActions.testAudioInputDevicesWarning({ warningName })
        );
        console.log(warningName);
      }
    );

    audioInputDeviceTest.on(
      AudioInputTest.Events.WarningCleared,
      (warningName: WarningName) => {
        this.store$.dispatch(
          ConferencingActions.testAudioInputDevicesWarningCleared({
            warningName,
          })
        );
        console.log(warningName);
      }
    );

    audioInputDeviceTest.on(
      AudioInputTest.Events.Error,
      (error: DiagnosticError) => {
        this.store$.dispatch(
          ConferencingActions.testAudioInputDevicesError({ error })
        );
        console.error(error);
      }
    );

    audioInputDeviceTest.on(
      AudioInputTest.Events.End,
      (report: AudioInputTest.Report) => {
        this.store$.dispatch(
          ConferencingActions.testAudioInputDevicesEnd({ report })
        );
        console.log(report);
      }
    );
  }

  public testAudioOutputDevices(options?: AudioOutputTest.Options): void {
    const opts: AudioOutputTest.Options = options
      ? options
      : { debug: false, duration: 5000 };

    const audioOutputDeviceTest = new AudioOutputTest(opts);

    audioOutputDeviceTest.on(
      AudioOutputTest.Events.Volume,
      (volume: number) => {
        this.store$.dispatch(
          ConferencingActions.testAudioOutputDevicesVolume({ volume })
        );
        console.info(volume);
      }
    );

    audioOutputDeviceTest.on(AudioOutputTest.Events.Error, (error) => {
      this.store$.dispatch(
        ConferencingActions.testAudioOutputDevicesError({ error })
      );
      console.error(error);
    });

    audioOutputDeviceTest.on(
      AudioOutputTest.Events.End,
      (report: AudioOutputTest.Report) => {
        this.store$.dispatch(
          ConferencingActions.testAudioOutputDevicesEnd({ report })
        );
        console.log(report);
      }
    );
  }
}
