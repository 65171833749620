import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GridComponent } from './components/grid/grid.component';
import { ChunkPipe } from './pipes/chunk.pipe';
import { GridCellComponent } from './components/grid/grid-cell/grid-cell.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { SetupComponent } from './components/setup/setup.component';
import { AcceptCallDialogComponent } from './components/accept-call-dialog/accept-call-dialog.component';
import { AvatarThumbComponent } from './components/avatar-thumb/avatar-thumb.component';
import { ChoiceSelectorComponent } from './components/choice-selector/choice-selector.component';
import { ChoiceSelectorChoiceComponent } from './components/choice-selector/choice-selector-choice/choice-selector-choice.component';
import { MessageSetViewerComponent } from './components/message-set-viewer/message-set-viewer.component';
import { MessageIndicatorComponent } from './components/message-indicator/message-indicator.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FocusDirective } from './directives/focus.directive';
import { MessageCountComponent } from './components/message-count/message-count.component';
import { MenuGridComponent } from './components/menu-grid/menu-grid.component';
import { MenuGridCellComponent } from './components/menu-grid/menu-grid-cell/menu-grid-cell.component';
import { DebugToolbarComponent } from './components/debug-toolbar/debug-toolbar.component';
import { PinComponent } from './components/pin/pin.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { MaterialModule } from '../material.module';
import { VideoViewerComponent } from './components/video-viewer/video-viewer.component';
import { TranslateModule } from '@ngx-translate/core';
import { AccountDetailCardComponent } from './components/account-detail-card/account-detail-card.component';
import { MessageSetHistoryViewerComponent } from './components/message-set-history-viewer/message-set-history-viewer.component';
import { TwilioCallViewerComponent } from './components/twilio-call-viewer/twilio-call-viewer.component';
import { NetworkIndicatorComponent } from './components/network-indicator/network-indicator.component';
import { ConferencingSubscriptionIndicatorComponent } from './components/conferencing-subscription-indicator/conferencing-subscription-indicator.component';
import { AssociationsDebugViewerComponent } from './components/associations-debug-viewer/associations-debug-viewer.component';
import { AssociationStatusChipComponent } from './components/association-status-chip/association-status-chip.component';
import { AboutCardComponent } from './components/about-card/about-card.component';
import { CameraPresentIndicatorComponent } from './components/camera-present-indicator/camera-present-indicator.component';
import { SupporterAssistanceProviderChipComponent } from './components/supporter-assistance-provider-chip/supporter-assistance-provider-chip.component';
import { AvInputIndicatorComponent } from './components/av-input-indicator/av-input-indicator.component';
import { CpuIndicatorComponent } from './components/cpu-indicator/cpu-indicator.component';
import { MemoryIndicatorComponent } from './components/memory-indicator/memory-indicator.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { AcceptCallWithDelayDialogComponent } from './components/accept-call-with-delay-dialog/accept-call-with-delay-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    InfiniteScrollModule,
    MaterialModule,
    TranslateModule,
  ],
  declarations: [
    GridComponent,
    GridCellComponent,
    ChunkPipe,
    OverlayComponent,
    SetupComponent,
    AcceptCallDialogComponent,
    AvatarThumbComponent,
    ChoiceSelectorComponent,
    ChoiceSelectorChoiceComponent,
    MessageSetViewerComponent,
    MessageIndicatorComponent,
    TruncatePipe,
    FocusDirective,
    MessageCountComponent,
    MenuGridComponent,
    MenuGridCellComponent,
    DebugToolbarComponent,
    PinComponent,
    ToggleSwitchComponent,
    ImageViewerComponent,
    VideoViewerComponent,
    AccountDetailCardComponent,
    MessageSetHistoryViewerComponent,
    TwilioCallViewerComponent,
    NetworkIndicatorComponent,
    ConferencingSubscriptionIndicatorComponent,
    AssociationsDebugViewerComponent,
    AssociationStatusChipComponent,
    AboutCardComponent,
    CameraPresentIndicatorComponent,
    SupporterAssistanceProviderChipComponent,
    AvInputIndicatorComponent,
    CpuIndicatorComponent,
    MemoryIndicatorComponent,
    ImageCarouselComponent,
    AcceptCallWithDelayDialogComponent,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    OverlayComponent,
    SetupComponent,
    GridComponent,
    AcceptCallDialogComponent,
    AvatarThumbComponent,
    ChoiceSelectorComponent,
    MessageSetViewerComponent,
    MessageIndicatorComponent,
    TruncatePipe,
    FocusDirective,
    MessageCountComponent,
    MenuGridComponent,
    DebugToolbarComponent,
    PinComponent,
    ToggleSwitchComponent,
    ImageViewerComponent,
    VideoViewerComponent,
    AccountDetailCardComponent,
    MessageSetHistoryViewerComponent,
    TwilioCallViewerComponent,
    ChunkPipe,
    NetworkIndicatorComponent,
    ConferencingSubscriptionIndicatorComponent,
    AssociationsDebugViewerComponent,
    AssociationStatusChipComponent,
    AboutCardComponent,
    CameraPresentIndicatorComponent,
    SupporterAssistanceProviderChipComponent,
    AvInputIndicatorComponent,
    CpuIndicatorComponent,
    MemoryIndicatorComponent,
    ImageCarouselComponent,
    AcceptCallWithDelayDialogComponent,
  ],
})
export class SharedModule {}
