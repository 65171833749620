import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'cm-pin',
    templateUrl: './pin.component.html',
    styleUrls: ['./pin.component.scss']
})
export class PinComponent implements OnInit {

    @Input() pinCode: string;

    constructor() { }

    ngOnInit() {

    }

}
