import { ReactiveFormsModule } from '@angular/forms';
import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'cm-menu-grid',
  templateUrl: './menu-grid.component.html',
  styleUrls: ['./menu-grid.component.scss'],
})
export class MenuGridComponent implements OnInit, AfterViewInit {
  @Input() items;
  @Input() tileWidth: number = 250;
  @Input() tileHeight: number = 200;
  @Input() columns: number = 1;
  @Output() itemSelected = new EventEmitter();
  @ViewChild('table') gridTable: ElementRef;

  private imageSelectedEvent: EventEmitter<any> = new EventEmitter();

  table: HTMLTableElement;
  active: number = 0;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.table = this.gridTable.nativeElement as HTMLTableElement;
    let cell = this.table.getElementsByClassName(
      'cm-menu-grid-cell'
    )[0] as HTMLTableCellElement;
    cell.focus();
    this.renderer.addClass(cell, 'active');
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === 13) {
      // enter/ok
      this.onSelected($event);
    } else {
      this.reCalculate($event);
      this.rePosition();
    }
    return false;
  }

  onSelected($event: any) {
    let itemId = this.table
      .getElementsByClassName('active')[0]
      .getAttribute('data-item-id');
    let item = this.items.find((itm) => itm.id === parseInt(itemId));
    if (item) {
      this.itemSelected.emit(item);
    }
  }

  reCalculate($event: KeyboardEvent) {
    let rows = this.table.getElementsByTagName('tr').length;
    let columns = this.table
      .getElementsByTagName('tr')[0]
      .getElementsByClassName('cm-menu-grid-cell').length;

    if ($event.keyCode === 37) {
      //move left or wrap
      this.active = this.active > 0 ? this.active - 1 : this.active;
    }
    if ($event.keyCode === 38) {
      // move up
      this.active =
        this.active - columns >= 0 ? this.active - columns : this.active;
    }
    if ($event.keyCode === 39) {
      // move right or wrap
      this.active =
        this.active < columns * rows - 1 ? this.active + 1 : this.active;
    }
    if ($event.keyCode === 40) {
      // move down
      this.active =
        this.active + columns <= rows * columns - 1
          ? this.active + columns
          : this.active;
    }

    console.log('active: ' + this.active);
  }

  rePosition() {
    let tdCount =
      this.table.getElementsByClassName('cm-menu-grid-cell').length - 1;
    if (this.active <= tdCount) {
      // Set focus on new element
      const cell = this.table.getElementsByClassName('cm-menu-grid-cell')[
        this.active
      ] as HTMLTableCellElement;
      cell.focus();
      // remove active from all td
      let actives = this.table.getElementsByClassName('active');
      for (let i = 0; i < actives.length; i++) {
        this.renderer.removeClass(actives[i], 'active');
      }
      // Set active class on new element
      this.renderer.addClass(
        this.table.getElementsByClassName('cm-menu-grid-cell')[this.active],
        'active'
      );
    } else {
      // set active to the last cell
      this.active = tdCount;
    }

    //scrollInView();
  }
}
