<div id="container">
  <div class="sidebar">
    <cm-avatar-thumb [user]="resident" size="220" hidePresence="true"></cm-avatar-thumb>
  </div>
  <div class="content">
    <div class="wrapper">
      <span class="label">{{ "ACCOUNT_DETAIL_NAME" | translate }}</span>
      <span class="value"
      >{{ resident?.first_name }} {{ resident?.last_name }}</span
      >
    </div>

    <div class="wrapper">
      <span class="label">{{ "ACCOUNT_DETAIL_USERNAME" | translate }}</span>
      <span class="value">{{ resident?.username }}</span>
    </div>

    <div class="wrapper">
      <span class="label">{{ "ACCOUNT_DETAIL_LOCALE" | translate }}</span>
      <span class="value">{{ resident?.locale }}</span>
    </div>

    <div class="wrapper">
      <span class="label">{{ "ACCOUNT_DETAIL_TIMEZONE" | translate }}</span>
      <span class="value">{{ resident?.time_zone }}</span>
    </div>
  </div>
</div>
