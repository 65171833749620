import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cm-association-status-chip',
  templateUrl: './association-status-chip.component.html',
  styleUrls: ['./association-status-chip.component.scss'],
})
export class AssociationStatusChipComponent implements OnInit {
  @Input() status: string;

  constructor() {}

  ngOnInit(): void {}
}
