import { ConfigurationService } from './configuration.service';
import { SupporterResidentAssociation } from '../models/supporter-resident-association.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { catchError, map } from 'rxjs/operators';
import { Resident } from '../models/resident.model';

/*
  Generated class for the SupporterResidentAssociationService provider.

  See https://angular.io/docs/ts/latest/guide/dependency-injection.html
  for more info on providers and Angular 2 DI.
*/
@Injectable()
export class SupporterResidentAssociationService {
  endpointUrl: string;

  constructor(private http: HttpClient) {
    this.endpointUrl = environment.apiUrl + '/supporter-resident-association';
  }

  lookupResidentByUsername(username) {
    let params = new HttpParams();
    params = params.set('username', username);

    return this.http
      .get<Resident>(environment.apiUrl + '/resident/lookup-by-username/', {
        params,
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<Resident>) => {
          if (response.status === 204) {
            return [];
          }
          // If a user is found, this end point currently return sigle user not in array
          // for our purposes we need to return as array
          return [response.body];
        }),
        catchError(this.handleError)
      );
  }

  getAssociations() {
    return this.http
      .get(this.endpointUrl, { observe: 'response' })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getAssociation(id: number) {
    return this.http
      .get(`${this.endpointUrl}/${id}`, { observe: 'response' })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  postAssociation(association: SupporterResidentAssociation) {
    let payload = {
      supporter: association.supporter.id,
      resident: association.resident.id,
    };
    if (association.pin_code) {
      payload['pin_code'] = association.pin_code;
    }

    return this.http
      .post(this.endpointUrl, payload)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  deleteAssociation(association: SupporterResidentAssociation) {
    return this.http
      .delete(`${this.endpointUrl}/${association.id}`)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  sendSupporterAssistanceRequest(association: SupporterResidentAssociation) {
    return this.http
      .patch(`${this.endpointUrl}/${association.id}/assistance_request`, {})
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  private extractData(response: any) {
    switch (response.status) {
      case 200:
        return response.body.results ? response.body.results : response.body; // Always return array even for single object
      case 201:
        return response.body;
      case 204:
        return null;
      default:
        console.error('Got unknown 2xx response');
    }
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(error);
  }
}
