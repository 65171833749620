<cm-overlay
  header="{{ 'CONFERENCING_INCOMING_VIDEO_CALL' | translate }}"
  width="1000"
  height="600"
>
  <cm-accept-call-with-delay-dialog
    *ngIf="inboundCallOptions$ | async as inboundCallOptions"
    [callOptions]="inboundCallOptions"
  ></cm-accept-call-with-delay-dialog>
</cm-overlay>
