import { Router } from '@angular/router';
import { LoggerService } from './logger.service';
import { MessageRecipientService } from './message-recipient.service';
import { MessageRecipient } from '../models/message-recipient.model';
import { Injectable } from '@angular/core';
import { reject } from 'lodash-es';
import { State } from 'src/app/state/root.reducer';
import { Store } from '@ngrx/store';
import * as UserActions from 'src/app/state/user.actions';

@Injectable()
export class SupervisorService {
  unread: MessageRecipient[] = [];
  pusherSocketId: null;

  showSettings = false;
  showAVSource = false;
  showPower = false;
  showAssistance = false;

  constructor(
    private messageRecipient: MessageRecipientService,
    private logger: LoggerService,
    private router: Router,
    private store$: Store<State>
  ) {}

  // populate() {
  //   this.messageRecipient
  //     .getUnreadMessageRecipients()
  //     .pipe(
  //       flatMap((recipients) =>
  //         this.messageRecipient.markAsDelivered(recipients)
  //       )
  //     )
  //     .subscribe((unread: MessageRecipient[]) => {
  //       this.unread = unread;
  //     });
  // }

  routeToViewer(messageRecipient: MessageRecipient) {
    this.router.navigate([
      'viewers/default',
      messageRecipient.id,
      messageRecipient.message_set.messages[0].id,
    ]);
  }

  isMessageSetUrgent(messageRecipient: MessageRecipient) {
    /*
     * Given a messageRecipient object, this function checks if the messageSet should be considered
     * urgent or not.  It does this by looking at messageSet.force_urgent, if this is set
     * to true, then the display preference in the resident's profile is overridden.  If it
     * is set to false, then the resident's display preference is obeyed.
     *
     */
    if (
      messageRecipient.message_set.force_urgent ||
      messageRecipient.message_set.system_message_type !== ''
    ) {
      return true;
    } else {
      return !messageRecipient.recipient.deliver_to_inbox;
    }
  }

  nextUnread() {
    if (this.unread.length > 0) {
      // Messages in queue, determine viewer and route
      // Display urgent first if any present
      const urgent = this.unread.filter((mr: MessageRecipient) =>
        this.isMessageSetUrgent(mr)
      );

      if (urgent.length > 0) {
        // Route to first urgent
        this.routeToViewer(urgent[0]);
      } else {
        // No urgent, go to first unread
        this.routeToViewer(this.unread[0]);
      }
    } else {
      // No more message sets, go to listener
      this.store$.dispatch(UserActions.navigateToHome());
    }
  }

  markAsRead(messageRecipientId) {
    // Remove from unread
    this.unread = reject(
      this.unread,
      (msgRecipient) => msgRecipient.id === messageRecipientId
    );
  }

  isUrgentUnreadInQueue() {
    const urgent = this.unread.filter((mr: MessageRecipient) =>
      this.isMessageSetUrgent(mr)
    );
    return urgent.length > 0;
  }

  showErrorDialog(options) {
    this.router.navigate([
      'viewers/error',
      options.title ? options.title : 'Error',
      options.message
        ? options.message
        : 'An error occured when processing your request',
      options.buttonText ? options.buttonText : 'Try Again',
    ]);
  }
}
