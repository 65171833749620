<div class="avatar-thumbnail" [ngStyle]="{'width.px': size, 'height.px': size}">
    <!-- Avatar available -->
    <div class="ispy-avatar" [ngStyle]="{'width.px': size, 'height.px': size}">
        <img [ngClass]="{ 'active': !hidden }" [src]="src" (load)="load()" (error)="error()"
            [ngStyle]="{'width.px': size, 'height.px': size, 'border-radius.px': size}" />

        <i class="presence" *ngIf="!hidePresence"
            [ngStyle]="{'width.px': size/3, 'height.px': size/3, 'border-radius.px': (size/3), 'bottom.px': size/8 }"
            [ngClass]="user?.online === true ? 'online' : 'offline'">
        </i>
    </div>
</div>
