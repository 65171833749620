import { MediaLibraryService } from './media-library.service';
import { CustomerPresenceService } from './customer-presence.service';
import { Router } from '@angular/router';
import { ConferencingService } from './conferencing.service';
import { SupervisorService } from './supervisor.service';
import { StbChannelService } from './stb-channel.service';
import { UserChannelService } from './user-channel.service';
import { PusherService } from './pusher.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from './configuration.service';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { CallOptions } from 'src/app/state/conferencing.reducer';

@Injectable()
export class OrchestratorService {
  private loggedInSubscription: Subscription;
  private loggedOutSubscription: Subscription;
  private callRequestedSubscription: Subscription;

  constructor(
    private configuration: ConfigurationService,
    private logger: LoggerService,
    private auth: AuthService,
    private translate: TranslateService,
    private pusher: PusherService,
    private userChannel: UserChannelService,
    private stbChannel: StbChannelService,
    private supervisor: SupervisorService,
    private conferencing: ConferencingService,
    private router: Router,
    private customerPresence: CustomerPresenceService,
    private mediaLibrary: MediaLibraryService
  ) {
    // subscribe to login/logout events
    this.loggedInSubscription = this.auth.loggedIn$.subscribe(
      this.onLoggedIn.bind(this),
      this.onLoggedInError.bind(this)
    );

    this.loggedOutSubscription = this.auth.loggedOut$.subscribe(
      this.onLoggedOut.bind(this),
      this.onLoggedOutError.bind(this)
    );

    // this.callRequestedSubscription = this.conferencing.callRequested$.subscribe(
    //   (callOptions: CallOptions) => {
    //     this.openAcceptCallDialog(callOptions);
    //   }
    // );

    // start auth token refresh if valid token already present
    //this.auth.startupTokenRefresh();

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en-GB');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en-GB');
  }

  private onLoggedIn() {
    console.info(`orchestrator: user logged in`);

    // this.pusher.init(); - MIGRATED
    // this.userChannel.subscribe(); - MIGRATED
    // this.stbChannel.subscribe();
    // this.conferencing.subscribe(); - MIGRATED
    // this.presence.subscribe(); - MIGRATED
    // this.supervisor.populate();
    // this.mediaLibrary.populate();
    // this.historicalMessages.populate(); - MIGRATED

    if (this.configuration.resident.customer.name === 'RO') {
      this.router.navigate(['/church/home']);
    }
  }

  private onLoggedInError(err) {
    console.info(`orchestrator: user logged in error - ${JSON.stringify(err)}`);
  }

  private onLoggedOut() {
    console.info(`orchestrator: user logged out`);
    //this.userChannel.unsubscribe();
    //this.stbChannel.unsubscribe();
    // this.conferencing.unsubscribe();
    this.customerPresence.unsubscribe();
    //this.pusher.disconnect();
  }

  private onLoggedOutError(err) {
    console.info(
      `orchestrator: user logged out error - ${JSON.stringify(err)}`
    );
  }

  private openAcceptCallDialog(callOptions: CallOptions) {
    this.conferencing.callRequestedCallOptions = callOptions;
    this.router.navigate(['conferencing/accept-call']);
  }
}
