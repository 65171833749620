import { Injectable } from '@angular/core';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import {
  tap,
  withLatestFrom,
  map,
  catchError,
  concatMap,
  mergeMap,
} from 'rxjs/operators';
import { AuthService } from '../core/services/auth.service';
import { GlobalPresenceService } from '../core/services/global-presence.service';
import { PusherService } from '../core/services/pusher.service';
import { State } from './root.reducer';
import { getProfile } from './user.selectors';

import * as RootActions from './root.actions';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import * as ConferencingActions from './conferencing.actions';
import * as MessagesActions from './messages.actions';
import * as PresenceActions from './presence.actions';
import * as StbActions from './stb.actions';
import { CustomerPresenceService } from '../core/services/customer-presence.service';
import { UserChannelService } from '../core/services/user-channel.service';
import { StbChannelService } from '../core/services/stb-channel.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SupporterResidentAssociationService } from '../core/services/supporter-resident-association.service';
import { SupporterResidentAssociation } from '../core/models/supporter-resident-association.model';
import { of } from 'rxjs';

@Injectable()
export class RootEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private authService: AuthService,
    private pusherService: PusherService,
    private globalPresenceService: GlobalPresenceService,
    private customerPresenceService: CustomerPresenceService,
    private userChannelService: UserChannelService,
    private stbChannelService: StbChannelService,
    private supporterResidentAssociationService: SupporterResidentAssociationService,
    private matSnackBar: MatSnackBar
  ) {}

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        tap((action: Action) => {
          console.log(`ROOT EFFECTS INIT`);
        }),
        concatLatestFrom(() => [
          this.store$.select(getProfile),
          this.store$.select(UserSelectors.selectShowDebug),
        ]),
        map(([action, profile, showDebug]) => {
          if (!this.authService.isTokenExpired(profile.token)) {
            const isIdcap = navigator.userAgent.includes('Web0S');
            this.store$.dispatch(UserActions.setIsIdcap({ isIdcap }));
            this.store$.dispatch(RootActions.connectToPusher());
            this.store$.dispatch(UserActions.refreshToken());
            this.store$.dispatch(PresenceActions.getAssistanceProviders());
            if (isIdcap && showDebug) {
              this.store$.dispatch(StbActions.getCpuUsage());
              this.store$.dispatch(StbActions.getMemoryUsage());
            }
            if (isIdcap) {
              this.store$.dispatch(StbActions.getSerialNumber());
              this.store$.dispatch(StbActions.getFirmwareVersion());
              this.store$.dispatch(StbActions.getModelNumber());
            }
          } else {
            console.log('User not authenticated');
            this.store$.dispatch(UserActions.logout());
          }
        })
      ),
    { dispatch: false }
  );

  connectToPusher$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.connectToPusher),
      withLatestFrom(this.store$.select(getProfile)),
      map(([action, profile]) => {
        this.pusherService.init(profile.token);
        return RootActions.connectToPusherSuccess();
      })
    )
  );

  updatePusherStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.updatePusherStatus),
        withLatestFrom(this.store$.select(UserSelectors.selectShowDebug)),
        map(([action, showDebug]) => {
          if (showDebug) {
            console.log(`Pusher status: ${action.status}`);

            const cssClass =
              action.status === 'connected' ? 'success' : 'warning';
            const config: MatSnackBarConfig = {
              duration: 3000,
              horizontalPosition: 'center',
              verticalPosition: 'top',
              panelClass: [`snackbar-${cssClass}`],
            };
            this.matSnackBar.open(action.status, '', config);
          }
        })
      ),
    { dispatch: false }
  );

  subscribeToChannels$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.connectToPusherSuccess),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          this.store$.dispatch(MessagesActions.getMessageRecipients());
          this.store$.dispatch(RootActions.subscribeGlobalChannel());
          this.store$.dispatch(RootActions.subscribeCustomerChannel());
          this.store$.dispatch(RootActions.subscribeUserChannel());
          this.store$.dispatch(RootActions.subscribeStbChannel());
          this.store$.dispatch(
            ConferencingActions.subscribeConferencingChannel({
              customer: profile.user.customer,
              user: profile.user,
            })
          );
          this.store$.dispatch(RootActions.getSupporterResidentAssociations());
        })
      ),
    { dispatch: false }
  );

  subscribeGlobalChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.subscribeGlobalChannel),
        map((action: Action) => {
          this.globalPresenceService.subscribe();
        })
      ),
    {
      dispatch: false,
    }
  );

  subscribeCustomerChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.subscribeCustomerChannel),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          this.customerPresenceService.subscribe(profile.user.customer);
        })
      ),
    {
      dispatch: false,
    }
  );

  subscribeUserChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.subscribeUserChannel),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          this.userChannelService.subscribe(profile.user);
        })
      ),
    {
      dispatch: false,
    }
  );

  subscribeStbChannel$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.subscribeStbChannel),
        withLatestFrom(this.store$.select(getProfile)),
        map(([action, profile]) => {
          this.stbChannelService.subscribe(profile.macAddress);
        })
      ),
    {
      dispatch: false,
    }
  );

  userChannelCallRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.userChannelCallRequest),
        map((callRequest: any) =>
          console.log(`CALL REQUEST: ${JSON.stringify(callRequest)}`)
        )
      ),
    { dispatch: false }
  );

  getSupporterResidentAssociations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.getSupporterResidentAssociations),
      concatMap((action) => {
        return this.supporterResidentAssociationService.getAssociations().pipe(
          map((associations: SupporterResidentAssociation[]) =>
            RootActions.getSupporterResidentAssociationsSuccess({
              associations,
            })
          ),
          catchError((error: Error) =>
            of(RootActions.getSupporterResidentAssociationsFailure({ error }))
          )
        );
      })
    )
  );

  supporterAssociationsSubscribePresenceUpdates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RootActions.getSupporterResidentAssociationsSuccess),
      mergeMap((action) => {
        return action.associations.map(
          (association: SupporterResidentAssociation) =>
            RootActions.subscribePresenceUpdatesForSupporter({
              supporter: association.supporter,
            })
        );
      })
    )
  );

  subscribePresenceUpdates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.subscribePresenceUpdatesForSupporter),
        map((action) =>
          this.customerPresenceService.subscribePresenceUpdatesForSupporter(
            action.supporter
          )
        )
      ),
    { dispatch: false }
  );
}
