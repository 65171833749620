import { AssistanceRequest } from './../models/assistance-request.model';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AssistanceRequestService {
  endpointUrl: string = environment.apiUrl + '/assistance-request';

  constructor(private http: HttpClient) {}

  getAssistanceRequests() {
    return this.http
      .get(this.endpointUrl)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  sendAssistanceRequest(assistanceRequest: AssistanceRequest) {
    return this.http
      .post(this.endpointUrl, assistanceRequest)
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  private extractData(data: any) {
    return data || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
