import { LoggerService } from './logger.service';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UrlConfigurationService {
  constructor(
    private configuration: ConfigurationService,
    private http: HttpClient,
    private logger: LoggerService
  ) {}

  getUrlConfiguration() {
    let endpointUrl = `${environment.apiUrl.slice(
      0,
      -3
    )}/core/stb-redirect/?hardware_id=${this.configuration.macAddress}`;

    return this.http.get(endpointUrl).pipe(
      map((urlConfigResponse: any) => {
        let urlConfig = urlConfigResponse.json();
        this.logger.info(`url config : configurarion received`);
        this.configuration.urlConfiguration.startupUrl = urlConfig.startup_url;
        this.configuration.urlConfiguration.sunriseUpdateUrl =
          urlConfig.sunrise_update_url;
        this.configuration.urlConfiguration.customerSupportUrl =
          urlConfig.customer_support_url;
        this.configuration.urlConfiguration.pingUrl = urlConfig.ping_url;
        this.configuration.save();

        this.logger.info(
          `url config : startup url : ${this.configuration.urlConfiguration.startupUrl}`
        );
        this.logger.info(
          `url config : sunrise url : ${this.configuration.urlConfiguration.sunriseUpdateUrl}`
        );
        this.logger.info(
          `url config : customer support url : ${this.configuration.urlConfiguration.customerSupportUrl}`
        );
        this.logger.info(
          `url config : ping url : ${this.configuration.urlConfiguration.pingUrl}`
        );

        return this.configuration.urlConfiguration;
      }),
      catchError((error) => {
        return error;
      })
    );
  }
}
