import { SupervisorService } from './../../core/services/supervisor.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { RouterExtensionService } from 'src/app/core/services/router-extension.service';

@Component({
  selector: 'cm-error-viewer',
  templateUrl: './error-viewer.component.html',
  styleUrls: ['./error-viewer.component.scss'],
})
export class ErrorViewerComponent implements OnInit {
  errorTitle: string;
  errorMessage: string;
  buttonText: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private supervisor: SupervisorService,
    private routerExtension: RouterExtensionService
  ) {}

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.errorTitle = params['errorTitle'];
      this.errorMessage = params['errorMessage'];
      this.buttonText = params['buttonText'];
    });
  }

  onOk(choice: any) {
    this.router.navigate([this.routerExtension.previousRoutePath.value]);
  }
}
