import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State } from './root.reducer';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, concatMap, map } from 'rxjs/operators';
import { SupporterResidentAssociationService } from '../core/services/supporter-resident-association.service';
import * as PresenceActions from './presence.actions';
import { of } from 'rxjs';
import { SupervisorService } from '../core/services/supervisor.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AssistanceProviderService } from '../core/services/assistance-provider.service';
import { AssistanceRequestService } from '../core/services/assistance-request.service';

@Injectable()
export class PresenceEffects {
  translateStrings: any;

  constructor(
    private actions$: Actions,
    private store$: Store<State>,
    private matSnackBar: MatSnackBar,
    private supporterResidentAssociationService: SupporterResidentAssociationService,
    private supervisorService: SupervisorService,
    private router: Router,
    private translateService: TranslateService,
    private assistanceProviderService: AssistanceProviderService,
    private assistanceRequestService: AssistanceRequestService
  ) {
    const tokens = [
      'ASSISTANCE_NO_PROVIDERS_AVAILABLE',
      'ASSISTANCE_ERROR_TITLE',
      'ASSISTANCE_FAILED_TO_GET_PROVIDERS',
      'ASSISTANCE_ERROR_SENDING_REQUEST',
      'ASSISTANCE_REQUEST_CANCEL',
    ];
    this.translateService
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));
  }

  sendSupporterAssistanceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresenceActions.sendSupporterAssistanceRequest),
      concatMap((action) => {
        return this.supporterResidentAssociationService
          .sendSupporterAssistanceRequest(action.supporterResidentAssociation)
          .pipe(
            map((result) =>
              PresenceActions.sendSupporterAssistanceRequestSuccess()
            ),
            catchError((error) =>
              of(PresenceActions.sendSupporterAssistanceRequestFailure())
            )
          );
      })
    )
  );

  sendAssistanceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresenceActions.sendAssistanceRequest),
      concatMap((action) => {
        return this.assistanceRequestService
          .sendAssistanceRequest(action.assistanceRequest)
          .pipe(
            map((result) => PresenceActions.sendAssistanceRequestSuccess()),
            catchError((error) =>
              of(PresenceActions.sendAssistanceRequestFailure())
            )
          );
      })
    )
  );

  sendSupporterAssistanceRequestSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PresenceActions.sendAssistanceRequestSuccess,
          PresenceActions.sendSupporterAssistanceRequestSuccess
        ),
        map((action) => {
          this.router.navigate(['assistance/request-assistance-confirmation']);
        })
      ),
    { dispatch: false }
  );

  sendSupporterAssistanceRequestFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          PresenceActions.sendAssistanceRequestFailure,
          PresenceActions.sendSupporterAssistanceRequestFailure
        ),
        map((action) => {
          this.supervisorService.showErrorDialog({
            title: this.translateStrings.ASSISTANCE_ERROR_TITLE,
            message: this.translateStrings.ASSISTANCE_ERROR_SENDING_REQUEST,
          });
        })
      ),
    { dispatch: false }
  );

  getAssistanceProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PresenceActions.getAssistanceProviders),
      concatMap((action) => {
        return this.assistanceProviderService.getAssistanceProviders().pipe(
          map((response) =>
            PresenceActions.getAssistanceProvidersSuccess({
              assistanceProviders: response.results,
            })
          ),
          catchError((error) =>
            of(PresenceActions.getAssistanceProvidersFailure({ error }))
          )
        );
      })
    )
  );
}
