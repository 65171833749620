<table #table class="grid-table">
  <tr *ngFor="let row of items | chunk: columns; let i = index">
    <td
      cmMenuGridCell
      [item]="item"
      *ngFor="let item of row; let j = index"
      [tileWidth]="tileWidth"
      [tileHeight]="tileHeight"
    ></td>
  </tr>
</table>
