import { Injectable } from '@angular/core';
import { HcapService } from './hcap.service';
import { IdcapService } from './idcap.service';

@Injectable({
  providedIn: 'root',
})
export class LgMiddlewareService {
  constructor(
    private hcapService: HcapService,
    private idcapService: IdcapService
  ) {}
}
