<div class="associations-debug-viewer">
  <mat-list *ngIf="associations && associations.length > 0" dense>
    <h3 matSubheader class="sub-header">Supporters</h3>
    <mat-list-item *ngFor="let association of associations">
      <cm-avatar-thumb matListAvatar [user]="association.supporter" [size]="40"></cm-avatar-thumb>
      <p matLine>
        <span>{{ association.supporter.first_name }} {{ association.supporter.last_name }}</span><cm-supporter-assistance-provider-chip [isAssistanceProvider]="association.is_assistance_provider"></cm-supporter-assistance-provider-chip>
      </p>
      <p matLine>
        <span> {{ association.supporter.username }} </span> <cm-association-status-chip [status]="association.current_status.status"></cm-association-status-chip>
      </p>
    </mat-list-item>
    <h3 matSubheader class="sub-header">Resident</h3>
    <mat-list-item class="resident">
      <cm-avatar-thumb matListAvatar hidePresence="true" [user]="resident" [size]="40"></cm-avatar-thumb>
      <h3 matLine>{{ resident.first_name }} {{ resident.last_name }} ({{ resident.username }})</h3>
      <h3 matLine>Customer: {{ resident.customer.name }}</h3>
      <h3 matLine>Site: {{ resident.site.name }}</h3>
    </mat-list-item>
  </mat-list>
</div>
