import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Supporter } from '../models/supporter.model';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SupporterService {
  endpointUrl: string = environment.apiUrl + '/supporter';

  constructor(private http: HttpClient) {}

  getSupporters(params?: HttpParams): Observable<Supporter[]> {
    return this.http
      .get(this.endpointUrl, { params })
      .pipe(map(this.extractData), catchError(this.handleError));
  }

  getSupporter(supporterId): Observable<Supporter> {
    return this.http.get<Supporter>(this.endpointUrl + '/' + supporterId);
  }

  private extractData(data: any) {
    return data || {};
  }

  private handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
