<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'DIAGNOSTICS_CAMERA_TEST' | translate }}"
>
<div>
  <ng-container *ngIf="{value: cameraConnected$ | async} as context">
    <div *ngIf="context.value; else noCamera">
      <cm-menu-grid
        [items]="diagnosticItems"
        (itemSelected)="onItemSelected($event)"
        style="margin: 50px"
        [columns]="4"
      ></cm-menu-grid>
    </div>
    <ng-template #noCamera>
      <div class="no-camera">
        <div>
          <mat-icon>videocam_off</mat-icon>
        </div>
        <div>{{ 'DIAGNOSTICS_CAMERA_TEST_NO_CAMERA' | translate }}</div>
      </div>
    </ng-template>
  </ng-container>
</div>
</cm-overlay>
