import { LoggerService } from './logger.service';
import { ConfigurationService } from './configuration.service';
import { AuthService } from './auth.service';
import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

import { PusherService } from './pusher.service';

import { Customer } from '../models/customer.model';
import { BaseUser } from '../models/base-user.model';
import { Store } from '@ngrx/store';
import * as ConferencingActions from 'src/app/state/conferencing.actions';
import {
  CallOptions,
  ConferencingState,
} from 'src/app/state/conferencing.reducer';

@Injectable()
export class ConferencingService {
  callRequestedCallOptions: CallOptions; // Call options received via inbound call request

  constructor(
    private pusher: PusherService,
    private logger: LoggerService,
    private store$: Store<ConferencingState>,
    private ngZone: NgZone
  ) {}

  subscribe(customer: Customer, user: BaseUser): void {
    const channelName = `private-call-${customer.id}`;
    const channel = this.pusher.subscribe(channelName);

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_succeeded',
      () => {
        this.logger.info(
          `Call channel subscription succeeded: ${channelName} (${customer.name})`
        );
        this.store$.dispatch(
          ConferencingActions.subscribeConferencingChannelSuccess({
            subscription: { channelName, connected: true, message: '' },
          })
        );
      }
    );

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_error',
      (error: Error) => {
        this.store$.dispatch(
          ConferencingActions.subscribeConferencingChannelFailure({
            subscription: {
              channelName,
              connected: false,
              message: error.message,
            },
          })
        );
        this.logger.error(
          `Customer call channel subscription FAILED: ${channelName}`
        );
        this.logger.error(status);
      }
    );

    this.logger.info(
      `Binding to call events with ${user.username} and id ${user.id}`
    );

    // Call requested incoming event
    const callRequestedEventName = `client-conferencing-call-requested-${user.id}`;
    this.logger.info(`Listening on ${callRequestedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callRequestedEventName,
      (callOptions: CallOptions) => {
        this.ngZone.run(() => {
          this.logger.info('called REQUESTED fired!');
          this.store$.dispatch(
            ConferencingActions.callRequested({ callOptions })
          );
        });
      }
    );

    // Call accepted incoming event
    const callAcceptedEventName = `client-conferencing-call-accepted-${user.id}`;
    this.logger.info(`Listening on ${callAcceptedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callAcceptedEventName,
      (callOptions: CallOptions) => {
        this.logger.info('called ACCEPTED fired!');
        this.store$.dispatch(ConferencingActions.callAccepted());
      }
    );

    // Call declined incoming event
    const callDeclinedEventName = `client-conferencing-call-declined-${user.id}`;
    this.logger.info(`Listening on ${callDeclinedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callDeclinedEventName,
      (callOptions: CallOptions) => {
        this.logger.info('call DECLINED fired!');
        this.store$.dispatch(ConferencingActions.callDeclined());
      }
    );

    // Call ended incoming event
    const callEndedEventName = `client-conferencing-call-ended-${user.id}`;
    this.logger.info(`Listening on ${callEndedEventName}`);
    this.pusher.bindChannelEvent(
      channel,
      callEndedEventName,
      (callOptions: CallOptions) => {
        this.logger.info('call ENDED fired!');
        this.store$.dispatch(ConferencingActions.callEnded());
      }
    );
  }

  unsubscribe(channelName: string): void {
    this.pusher.unsubscribe(channelName);
  }

  requestCall(channelName: string, callOptions: CallOptions): boolean {
    this.logger.info(`Requesting call with ${callOptions.target.username}`);
    const eventName = `client-conferencing-call-requested-${callOptions.target.id}`;
    this.logger.info(`eventName: ${eventName}`);
    const channel = this.pusher.channel(channelName);
    return channel.trigger(eventName, callOptions);
  }

  acceptCall(channelName: string, callOptions: CallOptions): boolean {
    this.logger.info(`Accepting call from ${callOptions.initiator.username}`);
    const eventName = `client-conferencing-call-accepted-${callOptions.initiator.id}`;
    this.logger.info(`eventName: ${eventName}`);
    const channel = this.pusher.channel(channelName);
    return channel.trigger(eventName, callOptions);
  }

  declineCall(
    channelName: string,
    inboundCallOptions: CallOptions,
    reason: string
  ): boolean {
    const callOptions: CallOptions = {
      initiator: inboundCallOptions.initiator,
      roomSid: inboundCallOptions.roomSid,
      declineReason: reason,
    };
    const channel = this.pusher.channel(channelName);
    return channel.trigger(
      'client-conferencing-call-declined-' + inboundCallOptions.initiator.id,
      callOptions
    );
  }

  endCall(channelName: string, callOptions: CallOptions): boolean {
    const channel = this.pusher.channel(channelName);
    return channel.trigger(
      'client-conferencing-call-ended-' + callOptions.initiator.id,
      callOptions
    );
  }
}
