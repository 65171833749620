import { LoggerService } from './logger.service';
import { Observable } from 'rxjs';
import { SetTopBox } from './../models/set-top-box.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/user.reducer';

@Injectable()
export class SetTopBoxService {
  private endpointUrl: string;

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private store$: Store<UserState>
  ) {
    this.endpointUrl = `${environment.apiUrl}/set-top-box`;
  }

  getById(id: number): Observable<SetTopBox> {
    return this.http.get<SetTopBox>(`${this.endpointUrl}/${id}`);
  }

  updateSetTopBox(setTopBox: SetTopBox) {
    return this.http
      .put<SetTopBox>(`${this.endpointUrl}/${setTopBox.id}`, setTopBox)
      .pipe(catchError(this.handleError));
  }

  patchSetTopBox(setTopBox: any): Observable<SetTopBox> {
    return this.http
      .patch<SetTopBox>(`${this.endpointUrl}/${setTopBox.id}`, setTopBox)
      .pipe(catchError(this.handleError));
  }

  // reportVersionNumbers() {
  //   this.getById(this.configuration.setTopBox.id).subscribe((setTopBox) => {
  //     this.logger.warn('reportVersionNumbers not implemented()');
  //       if (
  //         setTopBox.current_messenger_version !==
  //           this.configuration.messengerVersionNumber ||
  //         setTopBox.current_sunrise_version !==
  //           this.configuration.sunriseVersionNumber ||
  //         setTopBox.ip_address !== this.configuration.ipAddress ||
  //         setTopBox.default_av_input !== this.configuration.defaultAVInput
  //       ) {
  //         setTopBox.current_messenger_version = this.configuration.messengerVersionNumber;
  //         setTopBox.current_sunrise_version = this.configuration.sunriseVersionNumber;
  //         setTopBox.ip_address = this.configuration.ipAddress;
  //         setTopBox.default_av_input = this.configuration.defaultAVInput;
  //
  //         this.updateSetTopBox(setTopBox).subscribe(
  //           (stb: SetTopBox) => {
  //             this.logger.info(
  //               'version_numbers : successfully reported version numbers : ' +
  //                 this.configuration.messengerVersionNumber +
  //                 ' : ' +
  //                 this.configuration.sunriseVersionNumber
  //             );
  //           },
  //           (error) => {
  //             this.logger.error(
  //               'version_numbers : failed to report version numbers : ' +
  //                 error.status
  //             );
  //             this.logger.error('failure: ' + JSON.stringify(error));
  //           }
  //         );
  //       } else {
  //         this.logger.info(
  //           'version_numbers : server side version already matches'
  //         );
  //       }
  //     });
  //   });
  // }

  // reportCurrentMessengerVersion() {
  //   this.getById(this.configuration.setTopBox.id).subscribe((setTopBox) => {
  //     if (
  //       setTopBox.current_messenger_version !==
  //       this.configuration.messengerVersionNumber
  //     ) {
  //       setTopBox.current_messenger_version =
  //         this.configuration.messengerVersionNumber;
  //       this.updateSetTopBox(setTopBox).subscribe(
  //         (setTopBox) => {
  //           this.logger.info(
  //             'messenger_version_number : successfully reported messenger version : ' +
  //               this.configuration.messengerVersionNumber
  //           );
  //         },
  //         (error) => {
  //           this.logger.error(
  //             'messenger_version_number : failed to report messenger version : '
  //           );
  //         }
  //       );
  //     } else {
  //       this.logger.info(
  //         'messenger_version_number : server side version already matches'
  //       );
  //     }
  //   });
  // }

  // public reload() {
  //   window.location.replace(window.location.origin);
  // }

  private handleError(error) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = error.message
      ? error.message
      : error.status
      ? `${error.status} - ${error.statusText}`
      : 'Server error';
    console.error(errMsg); // log to console instead
    return Observable.throw(errMsg);
  }
}
