import { createAction, props } from '@ngrx/store';
import { BaseUser } from '../core/models/base-user.model';
import { SupporterResidentAssociation } from '../core/models/supporter-resident-association.model';
import { Supporter } from '../core/models/supporter.model';
import { ChannelSubscription } from './presence.reducer';

export const getSupporterResidentAssociations = createAction(
  '[Associations] Get Supporter Resident Associations'
);

export const getSupporterResidentAssociationsSuccess = createAction(
  '[Associations] Get Supporter Resident Associations Success',
  props<{ associations: SupporterResidentAssociation[] }>()
);

export const getSupporterResidentAssociationsFailure = createAction(
  '[Associations] Get Supporter Resident Associations Failure',
  props<{ error: any }>()
);

export const supporterResidentAssociationUpdated = createAction(
  '[Associations] Supporter Resident Association Updated',
  props<{ association: SupporterResidentAssociation }>()
);

export const supporterResidentAssociationDeleted = createAction(
  '[Associations] Supporter Resident Association Deleted',
  props<{ association: SupporterResidentAssociation }>()
);

// PUSHER Actions

export const connectToPusher = createAction('[Pusher] Connect');

export const connectToPusherSuccess = createAction('[Pusher] Connect Success');

export const connectToPusherFailure = createAction('[Pusher] Connect Failure');

export const updatePusherStatus = createAction(
  '[Pusher] Update Status',
  props<{ status: string }>()
);

// Global Channel
export const subscribeGlobalChannel = createAction(
  '[Global Channel] Subscribe'
);

export const subscribeGlobalChannelSuccess = createAction(
  '[Global Channel] Subscribe Success',
  props<{ channelName: string }>()
);

export const subscribeGlobalChannelFailure = createAction(
  '[Global Channel] Subscribe Failure'
);

export const setGlobalUsers = createAction(
  '[Global Presence] Set Users',
  props<{ users: BaseUser[] }>()
);

export const globalUserOnline = createAction(
  '[Global Presence] User Online',
  props<BaseUser>()
);

export const globalUserOffline = createAction(
  '[Global Presence] User Offline',
  props<BaseUser>()
);

// Customer Channel
export const subscribeCustomerChannel = createAction(
  '[Customer Channel] Subscribe'
);

export const subscribeCustomerChannelSuccess = createAction(
  '[Customer Channel] Subscribe Success',
  props<{ channelName: string }>()
);

export const subscribeCustomerChannelFailure = createAction(
  '[Customer Channel] Subscribe Failure'
);

export const getOnlineCustomerUsers = createAction(
  '[Customer Presence] Get Online Users'
);

export const getOnlineCustomerUsersSuccess = createAction(
  '[Customer Presence] Get Online Users Success',
  props<{ users: BaseUser[] }>()
);

export const getOnlineCustomerUsersFailure = createAction(
  '[Customer Presence] Get Online Users Failure'
);

export const customerUserPresenceUpdated = createAction(
  '[Customer Presence] User Presence Updated',
  props<{ user: BaseUser }>()
);

export const subscribePresenceUpdatesForSupporter = createAction(
  '[Customer Presence] Subscribe Presence Updates For Supporter',
  props<{ supporter: Supporter }>()
);

// User Channel
export const subscribeUserChannel = createAction('[User Channel] Subscribe');

export const subscribeUserChannelSuccess = createAction(
  '[User Channel] Subscribe Success',
  props<ChannelSubscription>()
);

export const subscribeUserChannelFailure = createAction(
  '[User Channel] Subscribe Failure'
);

export const userChannelCallRequest = createAction(
  '[User Channel] CallRequest',
  props<{ callRequest: any }>()
);

// STB Channel
export const subscribeStbChannel = createAction('[STB Channel] Subscribe');

export const subscribeStbChannelSuccess = createAction(
  '[STB Channel] Subscribe Success',
  props<ChannelSubscription>()
);

export const subscribeStbChannelFailure = createAction(
  '[STB Channel] Subscribe Failure'
);
