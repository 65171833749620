import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { SupervisorRoutingModule } from './supervisor-routing.module';
import { ListenerComponent } from './listener/listener.component';

@NgModule({
  imports: [
    SharedModule,
    SupervisorRoutingModule
  ],
  declarations: [ListenerComponent]
})
export class SupervisorModule { }
