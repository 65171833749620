import { Component, HostListener, OnInit } from '@angular/core';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';
import { Router } from '@angular/router';
import { IdcapService } from '../../core/services/idcap.service';

@Component({
  selector: 'cm-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  constructor(private router: Router, private idcapService: IdcapService) {}

  modelName: string;
  platformVersion: string;
  serialNumber: string;
  macAddress: string;
  ipAddress: string;
  procentricServer: string;

  ngOnInit(): void {
    this.idcapService.getModelName().then((modelName: any) => {
      console.info(`modelName: ${modelName}`);
      this.modelName = modelName.value;
    });

    this.idcapService.getFirmwareVersion().then((platformVersion: any) => {
      console.info(`platformVersion: ${platformVersion}`);
      this.platformVersion = platformVersion.value;
    });

    this.idcapService.getSerialNumber().then((serialNumber: any) => {
      console.info(`serialNumber: ${serialNumber}`);
      this.serialNumber = serialNumber.value;
    });

    this.idcapService.getMacAddress().then((macAddress: any) => {
      console.info(`macAddress: ${macAddress}`);
      this.macAddress = macAddress;
    });

    this.idcapService.getNetworkInformation().then((networkConfig: any) => {
      if (networkConfig.wired.state === 'connected') {
        console.info(`ipAddress: ${networkConfig.wired.ipAddress}`);
        this.ipAddress = networkConfig.wired.ipAddress;
      } else {
        console.info(`ipAddress: ${networkConfig.wifi.ipAddress}`);
        this.ipAddress = networkConfig.wifi.ipAddress;
      }
    });

    this.idcapService.getProcentricServer().then((procentricConfig: any) => {
      const procentricServer = `${procentricConfig.ipServer.ip}:${procentricConfig.ipServer.port}`;
      console.info(`procentricServer: ${procentricServer}`);
      this.procentricServer = procentricServer;
    });
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToUtilities();
    }
    return false;
  }

  goToUtilities(): void {
    this.router.navigate(['dashboard/utilities']);
  }
}
