import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AssistanceProviderService } from './services/assistance-provider.service';
import { AuthGuard } from './guards/auth-guard.service';
import { RemoteControlGuard } from './guards/remote-control-guard.service';
import { AssistanceRequestService } from './services/assistance-request.service';
import { AuthService } from './services/auth.service';
import { ConferencingService } from './services/conferencing.service';
import { ConfigurationService } from './services/configuration.service';
import { LoggerService } from './services/logger.service';
import { MediaLibraryService } from './services/media-library.service';
import { MessageRecipientService } from './services/message-recipient.service';
import { OrchestratorService } from './services/orchestrator.service';
import { CustomerPresenceService } from './services/customer-presence.service';
import { PusherService } from './services/pusher.service';
import { RemoteControlService } from './services/remote-control.service';
import { ResidentService } from './services/resident.service';
import { SetTopBoxService } from './services/set-top-box.service';
import { StbChannelService } from './services/stb-channel.service';
import { SupervisorService } from './services/supervisor.service';
import { SupporterResidentAssociationService } from './services/supporter-resident-association.service';
import { SupporterService } from './services/supporter.service';
import { UrlConfigurationService } from './services/url-configuration.service';
import { UserChannelService } from './services/user-channel.service';
import { NgxTranslateModule } from './ngx-translate.module';
import { HcapService } from './services/hcap.service';
import { ConferencingMultipleService } from './services/conferencing-multiple.service';
import { RouterExtensionService } from './services/router-extension.service';
import { TwilioVideoService } from './services/twilio-video.service';
import { GlobalPresenceService } from './services/global-presence.service';
import { CustomerService } from './services/customer.service';
import { PreviousRouteService } from './services/previous-route.service';
import { TwilioRtcDiagnosticsService } from './services/twilio-rtc-diagnostics.service';
import { IdcapService } from './services/idcap.service';
import { LgMiddlewareService } from './services/lg-middleware.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, NgxWebstorageModule.forRoot(), NgxTranslateModule],
  providers: [
    AuthGuard,
    RemoteControlGuard,
    AuthService,
    LoggerService,
    ConfigurationService,
    RemoteControlService,
    PusherService,
    UrlConfigurationService,
    OrchestratorService,
    SetTopBoxService,
    UserChannelService,
    StbChannelService,
    SupervisorService,
    MessageRecipientService,
    ConferencingService,
    ConferencingMultipleService,
    SupporterService,
    SupporterResidentAssociationService,
    CustomerPresenceService,
    AssistanceProviderService,
    AssistanceRequestService,
    ResidentService,
    MediaLibraryService,
    HcapService,
    RouterExtensionService,
    TwilioVideoService,
    GlobalPresenceService,
    CustomerService,
    PreviousRouteService,
    TwilioRtcDiagnosticsService,
    IdcapService,
    LgMiddlewareService,
  ],
})
export class CoreModule {}
