import { Component, OnInit, Input, ElementRef, DoCheck } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: '[cmChoiceSelectorChoice]',
  templateUrl: './choice-selector-choice.component.html',
  styleUrls: ['./choice-selector-choice.component.scss'],
  animations: [
    trigger('focusedTrigger', [
      state(
        'true',
        style({
          transform: 'scale(1.1)',
          transformOrigin: '50% 50%',
        })
      ),
      state(
        'false',
        style({
          transform: 'scale(1)',
          transformOrigin: '50% 50%',
        })
      ),
      transition('* <=> *', animate('300ms ease-out')),
    ]),
  ],
})
export class ChoiceSelectorChoiceComponent implements OnInit, DoCheck {
  @Input() choice: any;
  @Input() isCurrentChoice = false;
  @Input() choiceWidth: number;
  @Input() choiceHeight: number;
  @Input() iconWidth: number;
  @Input() iconHeight: number;
  @Input() focused = false;
  @Input() showAvatar = false;
  @Input() avatarSize: number;
  @Input() showIcon = false;
  @Input() showToggle = false;
  active = false;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  ngDoCheck(): void {
    this.active =
      !!this.el.nativeElement.children[0].classList.contains('active');
  }
}
