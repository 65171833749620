import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
    selector: 'cm-message-count',
    templateUrl: './message-count.component.html',
    styleUrls: ['./message-count.component.scss'],
    animations: [
        trigger('updatedTrigger', [
            state('true', style({
                transform: 'scale(1.2)',
                transformOrigin: '50% 50%'
            })),
            state('false', style({
                transform: 'scale(1)',
                transformOrigin: '50% 50%'
            })),
            transition('* <=> *', animate('300ms ease-in'))
        ])
    ]
})
export class MessageCountComponent implements OnInit, OnChanges {

    @Input() totalMessages: number;
    @Input() currentMessage: number;
    updated: boolean = false;

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updated = true;
        setTimeout(() => this.updated = false, 300);
    }
}
