import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state/user.reducer';
import * as UserSelectors from 'src/app/state/user.selectors';

@Component({
  selector: 'cm-network-indicator',
  templateUrl: './network-indicator.component.html',
  styleUrls: ['./network-indicator.component.scss'],
})
export class NetworkIndicatorComponent implements OnInit {
  pusherStatus$: Observable<string>;

  constructor(private store$: Store<UserState>) {
    this.pusherStatus$ = this.store$.select(UserSelectors.selectPusherStatus);
  }

  ngOnInit(): void {}
}
