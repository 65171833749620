<div class="accept-call-dialog-container">
  <div class="user-info">
    <cm-avatar-thumb
      [user]="callOptions.initiator"
      [size]="240"
      [hidePresence]="true"
    ></cm-avatar-thumb>
    <div>
      <h3>
        {{ callOptions.initiator.first_name }}
        {{ callOptions.initiator.last_name }}
      </h3>
    </div>
  </div>
  <cm-choice-selector
    [choices]="choices"
    (choiceSelected)="onChoiceSelected($event)"
    columns="2"
    [showIcon]="true"
    [iconWidth]="48"
    [iconHeight]="48"
  ></cm-choice-selector>
</div>
