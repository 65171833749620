<div
  class="theme"
  [ngClass]="
    {
      dark: 'mat-app-background cm-dark-theme',
      high: 'mat-app-background cm-high-contrast-theme'
    }['']
  "
>

<!--  <div class="debug-debris" *ngIf="showDebug$ | async as showDebug">-->
<!--    <div class="associations" *ngIf="associations$ | async as associations">-->
<!--      <cm-associations-debug-viewer *ngIf="currentUser$ | async as currentUser" [associations]="associations" [resident]="currentUser"></cm-associations-debug-viewer>-->
<!--    </div>-->
<!--    <div class="indicators">-->
<!--      <cm-camera-present-indicator></cm-camera-present-indicator>-->
<!--      <cm-conferencing-subscription-indicator></cm-conferencing-subscription-indicator>-->
<!--      <cm-network-indicator></cm-network-indicator>-->
<!--      <cm-av-input-indicator></cm-av-input-indicator>-->
<!--      <cm-memory-indicator></cm-memory-indicator>-->
<!--      <cm-cpu-indicator></cm-cpu-indicator>-->
<!--    </div>-->
<!--  </div>-->
  <router-outlet></router-outlet>
</div>
