import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { PusherService } from './pusher.service';

import { BaseUser } from '../models/base-user.model';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/user.reducer';
import { LoggerService } from './logger.service';

import * as RootActions from '../../state/root.actions';
import { SupporterResidentAssociation } from '../models/supporter-resident-association.model';

@Injectable()
export class UserChannelService {
  private callRequestedSource = new Subject();

  callRequested$ = this.callRequestedSource.asObservable();

  constructor(
    private logger: LoggerService,
    private pusher: PusherService,
    private configuration: ConfigurationService,
    private store$: Store<UserState>
  ) {}

  public subscribe(user: BaseUser): void {
    const channelName = `private-user-${user.id}`;
    const channel = this.pusher.subscribe(channelName);

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_succeeded',
      (data) => {
        this.logger.info('Private user channel subscription succeeded: ', data);
        this.store$.dispatch(
          RootActions.subscribeUserChannelSuccess({ channelName })
        );
      }
    );

    this.pusher.bindChannelEvent(
      channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'Private user channel subscription FAILED: ' + channelName
        );
        this.logger.error(status);
        this.store$.dispatch(RootActions.subscribeUserChannelFailure());
      }
    );

    this.pusher.bindChannelEvent(
      channel,
      'conferencing:call_request',
      this.handleCallRequest
    );

    this.pusher.bindChannelEvent(
      channel,
      'supporter_resident_association_updated',
      (association: SupporterResidentAssociation) => {
        console.log(`association updated: ${JSON.stringify(association)}`);
        this.store$.dispatch(
          RootActions.supporterResidentAssociationUpdated({ association })
        );
      }
    );

    this.pusher.bindChannelEvent(
      channel,
      'supporter_resident_association_deleted',
      (association: SupporterResidentAssociation) => {
        console.log(`association deleted: ${JSON.stringify(association)}`);
        this.store$.dispatch(
          RootActions.supporterResidentAssociationDeleted({ association })
        );
      }
    );
  }

  public unsubscribe(channelName: string): void {
    this.pusher.unsubscribe(channelName);
    this.logger.info('Unsubscribed private user channel');
  }

  public handleCallRequest(callRequest: any): void {
    this.logger.info(`Call request data received: ${callRequest}`);
    this.store$.dispatch(RootActions.userChannelCallRequest({ callRequest }));
    this.callRequestedSource.next(callRequest);
  }
}
