import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { asyncScheduler, EMPTY, of, timer } from 'rxjs';
import {
  exhaustMap,
  map,
  catchError,
  tap,
  withLatestFrom,
  concatMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from '../core/services/auth.service';
import { State } from './root.reducer';
import { LoginResponse } from './user.reducer';

import * as RootActions from './root.actions';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import {
  getProfile,
  selectCurrentUser,
  selectIsChurch,
  selectIsDoro,
  selectMacAddress,
  selectPinCode,
} from './user.selectors';
import { selectConferencingSubscription } from './conferencing.selectors';
import { CustomerService } from '../core/services/customer.service';
import { Customer } from '../core/models/customer.model';
import { TranslateService } from '@ngx-translate/core';
import { ResidentService } from '../core/services/resident.service';
import { Resident } from '../core/models/resident.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PusherService } from '../core/services/pusher.service';
import { TwilioRtcDiagnosticsService } from '../core/services/twilio-rtc-diagnostics.service';
import { IdcapService } from '../core/services/idcap.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private store$: Store<State>,
    private idcapService: IdcapService,
    private customerService: CustomerService,
    private translateService: TranslateService,
    private residentService: ResidentService,
    private jwtHelper: JwtHelperService,
    private pusher: PusherService,
    private rtcDiagnostics: TwilioRtcDiagnosticsService
  ) {}

  // init$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ROOT_EFFECTS_INIT),
  //     mergeMap((action) => [
  //       UserActions.refreshToken(),
  //     ])
  //   )
  // );

  getMacAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getMacAddress),
      exhaustMap((action) => {
        return this.idcapService
          .getMacAddress()
          .then((macAddress) =>
            UserActions.getMacAddressSuccess({ macAddress })
          )
          .catch((error: Error) => UserActions.getMacAddressFailure(error));
      })
    )
  );

  getMacAddressSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getMacAddressSuccess),
      withLatestFrom(this.store$.select(selectMacAddress)),
      map(([action, macAddress]) => UserActions.login({ macAddress }))
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      exhaustMap((action) =>
        this.authService.login(action.macAddress).pipe(
          map((loginResponse: LoginResponse) => {
            return UserActions.loginSuccess(loginResponse);
          }),
          catchError((error) => of(UserActions.loginFailure({ error })))
        )
      )
    )
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        tap((action) => {
          this.store$.dispatch(RootActions.connectToPusher());
          this.router.navigate(['setup/complete']);
        })
      ),
    { dispatch: false }
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginFailure),
        withLatestFrom(this.store$.select(selectMacAddress)),
        tap(([action, macAddress]) => {
          this.router.navigate(['setup/error', macAddress]);
        })
      ),
    { dispatch: false }
  );

  $logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.logout),
        withLatestFrom(this.store$.select(selectConferencingSubscription)),
        tap(([action, subscription]) => {
          // do logout stuff here
          // unsubscribe websocket channels?

          //   this.globalPresence.unsubscribe();
          //   this.customerPresence.unsubscribe();

          //   if (subscription)
          //     this.conferencing.unsubscribe(subscription.channelName);

          // TODO: Unsubscribe message box channel for all sites
          //this.messageboxService.unsubscribe();

          //   this.userChannel.unsubscribe();
          this.pusher.disconnect();

          // Re-run the camera detection
          this.rtcDiagnostics.testVideoInputDevices();

          // navigate to setup
          this.router.navigate(['/setup/welcome']);
        })
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginRedirect),
        tap((action) => this.router.navigate(['/setup/welcome']))
      ),
    { dispatch: false }
  );

  setLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loginSuccess),
        map((action) => {
          const locale = action.user.locale.replace('-', '_');
          this.translateService.setDefaultLang(action.user.locale);
        })
      ),
    { dispatch: false }
  );

  getCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      exhaustMap((action) =>
        this.customerService.getCustomer(action.user.customer.id).pipe(
          map((customer: Customer) => {
            return UserActions.getCustomerSuccess(customer);
          }),
          catchError((error) => of(UserActions.getCustomerFailure({ error })))
        )
      )
    )
  );

  getPinCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getPinCode),
      withLatestFrom(
        this.store$.select(selectPinCode),
        this.store$.select(selectCurrentUser)
      ),
      concatMap(([action, pinCode, currentUser]) => {
        if (pinCode === '') {
          return this.residentService.getPinCode(currentUser.id).pipe(
            map((response: any) => UserActions.getPinCodeSuccess({ response })),
            catchError((error) => of(UserActions.getPinCodeFailure(error)))
          );
        }
        return of(
          UserActions.getPinCodeSuccess({ response: { pin_code: pinCode } })
        );
      })
    )
  );

  setDeliverToInbox$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setDeliverToInbox),
      withLatestFrom(this.store$.select(selectCurrentUser)),
      concatMap(([action, currentUser]) => {
        return this.residentService
          .setDeliverToInbox(currentUser.id, !currentUser.deliver_to_inbox)
          .pipe(
            map((resident: Resident) =>
              UserActions.setDeliverToInboxSuccess(resident)
            ),
            catchError((error) =>
              of(UserActions.setDeliverToInboxFailure(error))
            )
          );
      })
    )
  );

  navigateToHome$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.navigateToHome),
        concatLatestFrom(() => [
          this.store$.select(selectIsChurch),
          this.store$.select(selectIsDoro),
        ]),
        map(([action, isChurch, isDoro]) => {
          if (isChurch) {
            this.router.navigate(['/church/home']);
          } else if (isDoro) {
            this.router.navigate(['/doro/home']);
          } else {
            this.router.navigate(['/supervisor/listener']);
          }
        })
      ),
    { dispatch: false }
  );

  checkTokenAfterLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginSuccess),
      map((action) => {
        if (this.jwtHelper.isTokenExpired(action.token)) {
          return UserActions.loginRedirect();
        } else {
          return UserActions.refreshToken();
        }
      })
    )
  );

  refreshToken$ = createEffect(
    () =>
      ({ scheduler = asyncScheduler, stopTimer = EMPTY } = {}) =>
        this.actions$.pipe(
          ofType(UserActions.refreshToken),
          withLatestFrom(
            this.store$.select(UserSelectors.selectTokenRefreshInterval),
            this.store$.select(UserSelectors.selectToken)
          ),
          switchMap(([action, pollingInterval, token]) =>
            timer(0, pollingInterval, scheduler).pipe(
              takeUntil(stopTimer),
              switchMap(() =>
                this.authService.getNewJwt(token).pipe(
                  map((response: LoginResponse) =>
                    UserActions.refreshTokenSuccess({
                      token: response.token,
                    })
                  ),
                  catchError((error: Error) =>
                    of(UserActions.refreshTokenFailure({ error }))
                  )
                )
              )
            )
          )
        )
  );
}
