<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'MENU_TOOLS_TITLE' | translate }}"
>
  <cm-menu-grid
    [items]="items"
    (itemSelected)="onItemSelected($event)"
    style="margin: 50px"
    [columns]="4"
  ></cm-menu-grid>
</cm-overlay>
