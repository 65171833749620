import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { StbState } from '../../../state/stb.reducer';
import { Observable } from 'rxjs';
import * as StbSelectors from '../../../state/stb.selectors';

@Component({
  selector: 'cm-av-input-indicator',
  templateUrl: './av-input-indicator.component.html',
  styleUrls: ['./av-input-indicator.component.scss'],
})
export class AvInputIndicatorComponent implements OnInit {
  stbState$: Observable<StbState>;
  constructor(private store$: Store) {}

  ngOnInit(): void {
    this.stbState$ = this.store$.select(StbSelectors.selectStbState);
  }
}
