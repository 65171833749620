import { AuthGuard } from './../core/guards/auth-guard.service';
import { RemoteControlGuard } from './../core/guards/remote-control-guard.service';
import { ListenerComponent } from './listener/listener.component';
import { REMOTE_KEY_SETS } from './../core/services/remote-control.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: 'listener',
        component: ListenerComponent,
        canActivate: [AuthGuard, RemoteControlGuard, ],
        data: { keySet: REMOTE_KEY_SETS.ok_arrows}
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SupervisorRoutingModule { }
