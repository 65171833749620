<div
  tabindex="0"
  [attr.data-item-id]="item.id"
  [@focusedTrigger]="active"
  [ngStyle]="{ 'width.px': tileWidth, 'height.px': tileHeight }"
  class="cm-menu-grid-cell"
>
  <!--<div class="image" [ngStyle]="{'background-image': 'url(' + item.large_url + ')'}"></div>-->

  <mat-icon
    [svgIcon]="item.icon"
    style="width: 96px; height: 96px; font-size: 96px"
  ></mat-icon>
  <h5 class="label">{{ item.label }}</h5>
</div>
