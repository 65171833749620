import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { getCurrentCustomer } from '../../state/user.selectors';
import { Observable } from 'rxjs';
import { Customer } from '../../core/models/customer.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LoggerService } from '../../core/services/logger.service';
import { SupporterResidentAssociation } from '../../core/models/supporter-resident-association.model';
import * as PresenceSelectors from '../../state/presence.selectors';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';

@Component({
  selector: 'cm-request-call-dialog',
  templateUrl: './request-call-dialog.component.html',
  styleUrls: ['./request-call-dialog.component.scss'],
})
export class RequestCallDialogComponent implements OnInit, OnDestroy {
  lock = true;
  choices: Array<any>;
  currentCustomer$: Observable<Customer>;
  associations$: Observable<SupporterResidentAssociation[]>;
  associations: SupporterResidentAssociation[];
  translateStrings: any;
  noSupportersMessage = '';
  header = 'Request a Call';

  constructor(
    private translate: TranslateService,
    private store$: Store,
    private logger: LoggerService
  ) {}

  ngOnInit(): void {
    this.currentCustomer$ = this.store$.select(getCurrentCustomer);
    this.associations$ = this.store$.select(
      PresenceSelectors.selectSupporterResidentAssociations
    );

    this.associations$.subscribe((associations) => {
      this.associations = associations;
      this.choices = associations.map((association) => {
        const name = `${association.supporter.first_name} ${association.supporter.last_name}`;
        return {
          label: name,
          name,
          user: association.supporter,
          value: association.supporter.id,
        };
      });
      this.choices = [...this.choices, { id: 0, label: 'Cancel' }];
      this.lock = false;
    });
  }

  ngOnDestroy(): void {}

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): boolean {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.backToListener();
    }
    return false;
  }

  backToListener(): void {}

  sendCallRequest(): void {
    this.logger.log('sendCallRequest');
  }
}
