import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MessageVideo } from 'src/app/core/models/message-video.model';
import { ASCII_REMOTE_KEYS } from 'src/app/core/services/remote-control.service';

declare var hcap: any;

@Component({
  selector: 'cm-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
})
export class VideoViewerComponent implements OnInit, OnDestroy {
  paused = false;
  stopped = false;
  playing = false;

  media = null;

  @Input() video: MessageVideo;
  @Output() closeVideoViewer = new EventEmitter();

  ngOnInit(): void {
    // bind to media events
    document.addEventListener(
      'media_event_received',
      this.onMediaEvent.bind(this)
    );

    const filePath =
      this.video.encodings.length > 0
        ? this.video.encodings[1].file
        : this.video.video;

    hcap.Media.startUp({
      onSuccess: () => {
        console.info(`video viewer : startup : success`);
        this.media = hcap.Media.createMedia({
          url: filePath,
          mimeType: 'video/mp4',
        });
        setTimeout(() => {
          console.info('video viewer : trying to play...');
          this.play();
        }, 500);
      },
      onFailure: (f) => {
        console.error(`video viewer : startup : failure : ${f.errorMessage}`);
      },
    });

    if (this.video.encodings[1].aspect_ratio < 1) {
      this.setPortraitVideoSize();
    } else {
      this.setLandscapeVideoSize();
    }
  }

  setPortraitVideoSize(): void {
    hcap.video.setVideoSize({
      x: 400,
      y: 0,
      width: 480,
      height: 720,
      onSuccess: () => {
        console.log('onSuccess');
      },
      onFailure: (f) => {
        console.log('onFailure : errorMessage = ' + f.errorMessage);
      },
    });
  }

  setLandscapeVideoSize(): void {
    hcap.video.setVideoSize({
      x: 0,
      y: 0,
      width: 1280,
      height: 720,
      onSuccess: () => {
        console.log('onSuccess');
      },
      onFailure: (f) => {
        console.log('onFailure : errorMessage = ' + f.errorMessage);
      },
    });
  }

  ngOnDestroy(): void {
    document.removeEventListener(
      'media_event_receiver',
      this.onMediaEvent.bind(this)
    );
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): boolean {
    switch ($event.keyCode) {
      case ASCII_REMOTE_KEYS.blue || ASCII_REMOTE_KEYS.ok:
        this.pause();
        break;
      case ASCII_REMOTE_KEYS.yellow || ASCII_REMOTE_KEYS.back:
        this.stop();
        break;
    }

    return false;
  }

  onMediaEvent($event): void {
    const eventType = $event.eventType;
    switch ($event.eventType) {
      case 'play_start':
        this.logMediaEvent(eventType);
        break;
      case 'play_end':
        this.logMediaEvent(eventType);
        this.stop();
        break;
      case 'error_in_playing':
        this.logMediaEvent(eventType);
        this.stop();
        break;
      case 'buffer_full':
        this.logMediaEvent(eventType);
        break;
      case 'file_not_found':
        this.logMediaEvent(eventType);
        this.stop();
        break;
      case 'network_disconnected':
        this.logMediaEvent(eventType);
        this.stop();
        break;
      case 'network_busy':
        this.logMediaEvent(eventType);
        this.stop();
        break;
      case 'network_cannot_process':
        this.logMediaEvent(eventType);
        this.stop();
        break;
      case 'seek_done':
        this.logMediaEvent(eventType);
        break;
      default:
        console.info('Unknown media event: ' + $event.eventType);
    }
  }

  logMediaEvent(eventType: string): void {
    console.info(`video viewer : media event : ${eventType}`);
  }

  play(): void {
    this.media.play({
      repeatCount: 1,
      onSuccess: () => {
        console.info(`video viewer : play : success`);
        this.playing = true;
      },
      onFailure: (f) => {
        console.error(`video viewer : play : fail : ${f.errorMessage}`);
      },
    });
  }

  pause(): void {
    if (!this.paused) {
      this.media.pause({
        onSuccess: () => {
          console.info(`video viewer : pause : success`);
          this.paused = true;
        },
        onFailure: (f) => {
          console.error(`video viewer : pause : fail : ${f.errorMessage}`);
        },
      });
    } else {
      this.media.resume({
        onSuccess: () => {
          console.info(`video viewer : resume : success`);
          this.paused = false;
        },
        onFailure: (f) => {
          console.error(`video viewer : resume : fail : ${f.errorMessage}`);
        },
      });
    }
  }

  stop(): void {
    this.media.stop({
      onSuccess: () => {
        console.info(`video viewer : stop : success`);
        this.stopped = true;
        this.media.destroy({
          onSuccess: () => {
            console.info(`video viewer : destroy : success`);
            hcap.Media.shutDown({
              onSuccess: () => {
                console.info(`video viewer : shutdown : success`);
                this.closePlayer();
              },
              onFailure: (f) => {
                console.error(
                  `video viewer : shutdown : fail : ${f.errorMessage}`
                );
                this.closePlayer();
              },
            });
          },
          onFailure: (f) => {
            console.error(`video viewer : destroy : fail : ${f.errorMessage}`);
            this.closePlayer();
          },
        });
      },
      onFailure: (f) => {
        console.error(`video viewer : stop : fail : ${f.errorMessage}`);
        this.closePlayer();
      },
    });
  }

  closePlayer(): void {
    console.info(`video viewer : resetting landscape video size...`);
    this.setLandscapeVideoSize();
    console.info(`video viewer : closing player...`);
    this.closeVideoViewer.emit();
  }
}
