import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BaseUser } from '../core/models/base-user.model';

import { PresenceState, presenceFeatureKey } from './presence.reducer';
import { AssistanceProvider } from '../core/models/assistance-provider.model';

const selectPresenceFeatureState =
  createFeatureSelector<PresenceState>(presenceFeatureKey);

// global
export const selectGlobalPresenceStaff = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'staff')
);

export const selectGlobalPresenceAdmin = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'customer_admin')
);

export const selectGlobalPresenceResident = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'resident')
);

export const selectGlobalPresenceSupporter = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'supporter')
);

export const selectGlobalPresence = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users
);

export const selectGlobalPresenceStaffCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'staff').length
);

export const selectGlobalPresenceAdminCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.global.users.filter((u) => u.user_type === 'customer_admin').length
);

export const selectGlobalPresenceResidentCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.filter((u) => u.user_type === 'resident').length
);

export const selectGlobalPresenceSupporterCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.global.users.filter((u) => u.user_type === 'supporter').length
);

export const selectGlobalPresenceCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.global.users.length
);

// customer

export const selectCustomerPresenceAdmin = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'customer_admin')
);

export const selectCustomerPresenceResident = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.filter((u) => u.user_type === 'resident')
);

export const selectCustomerPresenceSupporter = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.filter((u) => u.user_type === 'supporter')
);

export const selectCustomerPresence = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users
);

export const selectCustomerPresenceStaffCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.filter((u) => u.user_type === 'staff').length
);

export const selectCustomerPresenceAdminCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'customer_admin').length
);

export const selectCustomerPresenceResidentCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'resident').length
);

export const selectCustomerPresenceSupporterCount = createSelector(
  selectPresenceFeatureState,
  (state) =>
    state.customer.users.filter((u) => u.user_type === 'supporter').length
);

export const selectCustomerPresenceCount = createSelector(
  selectPresenceFeatureState,
  (state) => state.customer.users.length
);

export const isCustomerUserOnline = (user: BaseUser) =>
  createSelector(selectPresenceFeatureState, (state) => {
    const found = state.customer.users.find((u) => u.id === user.id);
    return found !== undefined;
  });

export const selectSupporterResidentAssociations = createSelector(
  selectPresenceFeatureState,
  (state) => state.associations
);

export const selectAssistanceProviders = createSelector(
  selectPresenceFeatureState,
  (state) => state.assistanceProviders
);

export const selectSupporterAssistanceProviders = createSelector(
  selectPresenceFeatureState,
  (state) => state.associations.filter((a) => a.is_assistance_provider === true)
);

export const selectAssistanceProviderChoices = createSelector(
  selectPresenceFeatureState,
  selectAssistanceProviders,
  selectSupporterAssistanceProviders,
  (
    state,
    assistanceProviders: AssistanceProvider[],
    supporterResidentAssociations
  ) => {
    const supporterChoices = supporterResidentAssociations.map(
      (association) => ({
        id: association.id,
        label: `${association.supporter.first_name} ${association.supporter.last_name}`,
        user: association.supporter,
        type: 'supporter',
        supporterResidentAssociation: association,
      })
    );
    const cancel = {
      id: 0,
      type: 'cancel',
      user: null,
      label: 'Cancel',
    };
    return [...assistanceProviders, ...supporterChoices, cancel];
  }
);
