import { MessageChoice } from './message-choice.model';
import { Message } from './message.model';
import { MessageRecipient } from './message-recipient.model';
import { Model } from './base.model';

export class MessageResponse {
  id?: number;
  message_recipient: any;
  message: any;
  choice: any;
}
