import { LoggerService } from './../../../core/services/logger.service';
import { ConfigurationService } from './../../../core/services/configuration.service';
import { Router } from '@angular/router';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../../core/services/remote-control.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/user.reducer';
import { Observable, Subscription } from 'rxjs';
import { selectDeliverToInbox } from 'src/app/state/user.selectors';
import * as UserActions from 'src/app/state/user.actions';

@Component({
  selector: 'cm-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  private lock: boolean = false;
  private deliverToInbox$: Observable<boolean>;
  private deliverToInbox: boolean;
  private deliverToInboxSubscription: Subscription;
  private translateStrings: any;
  public settings: any[];

  constructor(
    private router: Router,
    private logger: LoggerService,
    public configuration: ConfigurationService,
    private translate: TranslateService,
    private store$: Store<UserState>
  ) {}

  ngOnInit() {
    const tokens = [
      'PROFILE_SETTINGS_DELIVER_TO_INBOX',
      'PROFILE_SETTINGS_ENABLE_AV_KEY',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));

    this.deliverToInbox$ = this.store$.select(selectDeliverToInbox);
    this.deliverToInboxSubscription = this.deliverToInbox$.subscribe(
      (delToInbox: boolean) => (this.deliverToInbox = delToInbox)
    );

    this.settings = [
      {
        id: 1,
        tag: 'deliver_to_inbox',
        icon: 'cm:comment-bubble',
        label: this.translateStrings.PROFILE_SETTINGS_DELIVER_TO_INBOX,
        active: this.deliverToInbox,
      },
      // {
      //   id: 2,
      //   tag: 'enable_av_key',
      //   icon: 'cm:remote-control',
      //   label: this.translateStrings.PROFILE_SETTINGS_ENABLE_AV_KEY,
      //   active: false,
      // },
    ];
  }

  ngOnDestroy() {
    this.deliverToInboxSubscription.unsubscribe();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToProfile();
    }

    return false;
  }

  onSettingChanged(setting) {
    // toggle the setting
    setting.active = !setting.active;
    // persist the setting change
    switch (setting.tag) {
      case 'deliver_to_inbox':
        this.toggleDeliverToInbox(setting);
        break;
      case 'enable_av_key':
        this.toggleEnableAVKey(setting);
        break;
    }
  }

  toggleDeliverToInbox(setting) {
    if (!this.lock) {
      this.lock = true;
      this.store$.dispatch(UserActions.setDeliverToInbox());
      this.lock = false;

      this.configuration.resident.deliver_to_inbox = setting.active;
      this.configuration.save();
    }
  }

  toggleEnableAVKey(setting) {}

  goToProfile() {
    this.router.navigate(['dashboard/profile']);
  }
}
