import { LoggerService } from './logger.service';
import { Injectable, Optional } from '@angular/core';
import Pusher from 'pusher-js';

import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/user.reducer';
import * as RootActions from 'src/app/state/root.actions';

export class PusherServiceConfig {
  enabled = false;
}

@Injectable()
export class PusherService {
  _enabled = false;
  pusher: any;
  socketId: string;

  constructor(
    @Optional() config: PusherServiceConfig,
    private http: HttpClient,
    private authService: AuthService,
    private logger: LoggerService,
    private store$: Store<UserState>
  ) {
    if (config) {
      this._enabled = config.enabled;
    }
  }

  public init(token: string): void {
    const pusherOptions = {
      cluster: 'eu',
      encrypted: true,
      authEndpoint: environment.apiUrl.slice(0, -3) + '/pusher/auth/',
      auth: {
        headers: {
          AUTHORIZATION: `Bearer ${token}`,
        },
      },
    };

    this.pusher = new Pusher(environment.pusherKey, pusherOptions);

    // Setup pusher
    this.pusher.connection.bind('state_change', (states) => {
      this.logger.info('pusher state: ' + states.current);
      this.store$.dispatch(
        RootActions.updatePusherStatus({ status: states.current })
      );
    });

    this.pusher.connection.bind('connected', () => {
      this.socketId = this.pusher.connection.socket_id;
      this.logger.info(`pusher connected! (${this.socketId})`);
    });
  }

  public disconnect(): void {
    if (!this.pusher) {
      return;
    }
    this.pusher.disconnect();
    this.store$.dispatch(
      RootActions.updatePusherStatus({ status: 'disconnected' })
    );
  }

  public connectionBind(eventName, callback): void {
    this.pusher.connection.bind(eventName, function (): void {
      callback.apply(this.pusher, arguments);
    });
  }

  public subscribe(channelName) {
    return this.pusher.subscribe(channelName);
  }

  public unsubscribe(channelName) {
    return this.pusher.unsubscribe(channelName);
  }

  public unsubscribeAll() {
    this.pusher.channels.forEach((channel) =>
      this.pusher.unsubscribe(channel.name)
    );
  }

  public bindChannelEvent(channel, eventName, callback) {
    channel.bind(eventName, function () {
      const args = arguments;
      callback.apply(this.pusher, args);
    });
  }

  public bindAllChannelEvent(channel, callback) {
    channel.bind_global(function () {
      const args = arguments;
      callback.apply(this.pusher, args);
    });
  }

  public unbindChannelEvent(channel, eventName, callback) {
    channel.unbind(eventName, function () {
      const args = arguments;
      callback.apply(this.pusher, args);
    });
  }

  public channelUnbindAll(channel) {
    channel.unbind();
  }

  public channel(channelName) {
    return this.pusher.channel(channelName);
  }

  public allChannels() {
    return this.pusher.allChannels();
  }

  public isEncrypted() {
    return this.pusher.isEncrypted();
  }
}
