import { Component, HostListener, OnInit } from '@angular/core';
import * as ConferencingActions from '../../../state/conferencing.actions';
import { ASCII_REMOTE_KEYS } from '../../../core/services/remote-control.service';
import { MenuItem } from '../../../core/models/menu-item.model';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MediaConnectionBitrateDiagnostics } from '../../../state/conferencing.reducer';
import { selectMediaConnectionBitrateDiagnostics } from '../../../state/conferencing.selectors';

@Component({
  selector: 'cm-media-connection-bitrate-test',
  templateUrl: './media-connection-bitrate-test.component.html',
  styleUrls: ['./media-connection-bitrate-test.component.scss'],
})
export class MediaConnectionBitrateTestComponent implements OnInit {
  translateStrings: any;
  items: MenuItem[];

  bitrateDiagnostics$: Observable<MediaConnectionBitrateDiagnostics>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private store$: Store
  ) {}

  ngOnInit(): void {
    const tokens = [
      'DIAGNOSTICS_TITLE',
      'DIAGNOSTICS_CAMERA_TEST',
      'DIAGNOSTICS_START',
      'DIAGNOSTICS_EXIT',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));

    this.items = [
      {
        id: 1,
        icon: 'cm:network-antenna',
        tag: 'start',
        label: this.translateStrings.DIAGNOSTICS_START,
      },
      {
        id: 2,
        icon: 'cm:cross-circle',
        tag: 'exit',
        label: this.translateStrings.DIAGNOSTICS_EXIT,
      },
    ];

    this.bitrateDiagnostics$ = this.store$.select(
      selectMediaConnectionBitrateDiagnostics
    );
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToDiagnostics();
    }
  }

  onItemSelected(item: MenuItem): void {
    switch (item.tag) {
      case 'start':
        this.startTest();
        break;
      case 'exit':
        this.goToDiagnostics();
        break;
    }
  }

  startTest(): void {
    this.store$.dispatch(
      ConferencingActions.clearMediaConnectionBitrateTestResult()
    );
    this.store$.dispatch(ConferencingActions.testMediaConnectionBitrate({}));
  }

  goToDiagnostics(): void {
    this.router.navigate(['dashboard/diagnostics']);
  }
}
