import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as StbSelectors from '../../../state/stb.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'cm-memory-indicator',
  templateUrl: './memory-indicator.component.html',
  styleUrls: ['./memory-indicator.component.scss'],
})
export class MemoryIndicatorComponent implements OnInit {
  memoryUsage$: Observable<number>;
  constructor(private store$: Store) {
    this.memoryUsage$ = this.store$.select(StbSelectors.selectMemoryUsage);
  }

  ngOnInit(): void {}
}
