import { Component, OnInit, Input, ElementRef } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: '[cmMenuGridCell]',
  templateUrl: './menu-grid-cell.component.html',
  styleUrls: ['./menu-grid-cell.component.scss'],
  animations: [
    trigger('focusedTrigger', [
      state(
        'true',
        style({
          transform: 'scale(1.2)',
          transformOrigin: '25% 25%',
        })
      ),
      state(
        'false',
        style({
          transform: 'scale(1)',
          transformOrigin: '25% 25%',
        })
      ),
      transition('* <=> *', animate('300ms ease-out')),
    ]),
  ],
})
export class MenuGridCellComponent implements OnInit {
  @Input() item: any;
  @Input() tileWidth: number;
  @Input() tileHeight: number;
  active: boolean = false;

  constructor(private el: ElementRef) {}

  ngOnInit() {}

  ngDoCheck() {
    if (this.el.nativeElement.children[0].classList.contains('active')) {
      this.active = true;
    } else {
      this.active = false;
    }
  }
}
