import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cm-about-card',
  templateUrl: './about-card.component.html',
  styleUrls: ['./about-card.component.scss'],
})
export class AboutCardComponent implements OnInit {
  @Input() modelName: string;
  @Input() platformVersion: string;
  @Input() serialNumber: string;
  @Input() macAddress: string;
  @Input() ipAddress: string;
  @Input() procentricServer: string;

  constructor() {}

  ngOnInit(): void {}
}
