import { ReactiveFormsModule } from '@angular/forms';
import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

const mockImages: any[] = [
  { id: 1, large_url: 'http://lorempixel.com/960/540/sports' },
  { id: 2, large_url: 'http://lorempixel.com/960/540/food' },
  { id: 3, large_url: 'http://lorempixel.com/960/540/animals' },
  { id: 4, large_url: 'http://lorempixel.com/960/540/business' },
  { id: 5, large_url: 'http://lorempixel.com/960/540/sports' },
  { id: 6, large_url: 'http://lorempixel.com/960/540/nightlife' },
  { id: 7, large_url: 'http://lorempixel.com/960/540/nature' },
  { id: 8, large_url: 'http://lorempixel.com/960/540/fashion' },
  { id: 9, large_url: 'http://lorempixel.com/960/540/technics' },
  { id: 10, large_url: 'http://lorempixel.com/960/540/transport' },
  { id: 11, large_url: 'http://lorempixel.com/960/540/people' },
  { id: 12, large_url: 'http://lorempixel.com/960/540/abstract' },
];

@Component({
  selector: 'cm-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, AfterViewInit {
  @Input() images = mockImages;
  @Output() imageSelected = new EventEmitter();
  @ViewChild('table') gridTable: ElementRef;

  private imageSelectedEvent: EventEmitter<any> = new EventEmitter();

  table: HTMLTableElement;
  active: number = 0;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.table = this.gridTable.nativeElement as HTMLTableElement;
  }

  ngAfterViewInit() {
    let cell = this.table.getElementsByClassName(
      'cm-grid-cell'
    )[0] as HTMLTableCellElement;
    cell.focus();
    this.renderer.addClass(cell, 'active');
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === 13) {
      // enter/ok
      this.onSelected($event);
    } else {
      this.reCalculate($event);
      this.rePosition();
    }
    return false;
  }

  onSelected($event: any) {
    let imageId = this.table
      .getElementsByClassName('active')[0]
      .getAttribute('data-image-id');
    let image = this.images.find((img) => img.id === parseInt(imageId));
    if (image) {
      this.imageSelected.emit(image);
    }
  }

  reCalculate($event: KeyboardEvent) {
    let rows = this.table.getElementsByTagName('tr').length;
    let columns = this.table
      .getElementsByTagName('tr')[0]
      .getElementsByClassName('cm-grid-cell').length;

    if ($event.keyCode === 37) {
      //move left or wrap
      this.active = this.active > 0 ? this.active - 1 : this.active;
    }
    if ($event.keyCode === 38) {
      // move up
      this.active =
        this.active - columns >= 0 ? this.active - columns : this.active;
    }
    if ($event.keyCode === 39) {
      // move right or wrap
      this.active =
        this.active < columns * rows - 1 ? this.active + 1 : this.active;
    }
    if ($event.keyCode === 40) {
      // move down
      this.active =
        this.active + columns <= rows * columns - 1
          ? this.active + columns
          : this.active;
    }

    console.log('active: ' + this.active);
  }

  rePosition() {
    let tdCount = this.table.getElementsByClassName('cm-grid-cell').length - 1;
    if (this.active <= tdCount) {
      // Set focus on new element
      const cell = this.table.getElementsByClassName('cm-grid-cell')[
        this.active
      ] as HTMLTableCellElement;
      cell.focus();

      // remove active from all td
      let actives = this.table.getElementsByClassName('active');
      for (let i = 0; i < actives.length; i++) {
        this.renderer.removeClass(actives[i], 'active');
      }
      // Set active class on new element
      this.renderer.addClass(
        this.table.getElementsByClassName('cm-grid-cell')[this.active],
        'active'
      );
    } else {
      // set active to the last cell
      this.active = tdCount;
    }

    //scrollInView();
  }
}
