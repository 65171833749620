import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/state/user.reducer';
import { take } from 'rxjs/operators';
import { selectCurrentUser } from 'src/app/state/user.selectors';
import { BaseUser } from '../models/base-user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TwilioVideoService {
  ACCESS_TOKEN_URL = `${environment.apiUrl.substring(
    0,
    environment.apiUrl.length - 2
  )}conferencing/token/`;

  VIDEO_TOKEN_URL = `${environment.apiUrl.substring(
    0,
    environment.apiUrl.length - 2
  )}conferencing/video-token/`;

  constructor(private http: HttpClient, private store$: Store<UserState>) {}

  getAccessToken(): Observable<any> {
    return this.http.post(this.ACCESS_TOKEN_URL, {});
  }

  getVideoAccessToken(roomName: string) {
    let user: BaseUser;
    this.store$
      .select(selectCurrentUser)
      .pipe(take(1))
      .subscribe((u: BaseUser) => (user = u));
    const body = {
      username: user.username,
      room_name: roomName,
    };
    return this.http.post(this.VIDEO_TOKEN_URL, body).toPromise();
  }
}
