<cm-overlay
  *ngIf="messageRecipient$ | async as messageRecipient"
  [width]="1100"
  [height]="600"
  [header]="
    messageRecipient.message_set.sender.first_name +
    ' ' +
    messageRecipient.message_set.sender.last_name
  "
  [headerAvatar]="messageRecipient.message_set.sender"
  [headerCenter]="false"
  [showMessageCount]="messageRecipient.message_set.messages.length > 1"
  [totalMessages]="messageRecipient.message_set.messages.length"
  [currentMessage]="currentMessage"
>
  <cm-message-set-viewer
    [messageRecipient]="messageRecipient"
    (currentMessageChanged)="onCurrentMessageChanged($event)"
  ></cm-message-set-viewer>
</cm-overlay>
