import { ConfigurationService } from './../../core/services/configuration.service';
import { Router } from '@angular/router';
import { LoggerService } from './../../core/services/logger.service';
import { MenuItem } from './../../core/models/menu-item.model';
import { Component, OnInit, HostListener } from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../core/services/remote-control.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cm-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public items: MenuItem[];
  private translateStrings: any;

  constructor(
    private logger: LoggerService,
    private router: Router,
    public configuration: ConfigurationService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const tokens = [
      'PROFILE_ACCOUNT_DETAIL_TITLE',
      'PROFILE_PINCODE_TITLE',
      'PROFILE_SETTINGS_TITLE',
      'PROFILE_THEMES_TITLE',
      'PROFILE_BACK_TITLE',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));
    this.items = [
      {
        id: 1,
        tag: 'account_detail',
        icon: 'cm:no-avatar',
        label: this.translateStrings.PROFILE_ACCOUNT_DETAIL_TITLE,
      },
      {
        id: 2,
        tag: 'pin_code',
        icon: 'cm:grid-view',
        label: this.translateStrings.PROFILE_PINCODE_TITLE,
      },
      // {
      //   id: 3,
      //   tag: 'themes',
      //   icon: 'cm:paint-brush',
      //   label: this.translateStrings.PROFILE_THEMES_TITLE,
      // },
      {
        id: 4,
        tag: 'settings',
        icon: 'cm:gear',
        label: this.translateStrings.PROFILE_SETTINGS_TITLE,
      },
      {
        id: 5,
        tag: 'back',
        icon: 'cm:arrow-left',
        label: this.translateStrings.PROFILE_BACK_TITLE,
      },
    ];
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToMenu();
    }
    return false;
  }

  onItemSelected(item) {
    switch (item.tag) {
      case 'account_detail':
        this.router.navigate(['dashboard/profile/account-detail']);
        break;
      case 'pin_code':
        this.router.navigate(['dashboard/profile/pincode']);
        break;
      case 'themes':
        this.router.navigate(['dashboard/profile/themes']);
        break;
      case 'settings':
        this.router.navigate(['dashboard/profile/settings']);
        break;
      case 'back':
        this.goToMenu();
        break;
    }
  }

  goToMenu() {
    this.router.navigate(['dashboard/menu']);
  }
}
