<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'DIAGNOSTICS_BITRATE_TEST' | translate }}"
>
  <div id="bitrate-container">
    <ng-container *ngIf="bitrateDiagnostics$ | async as bitrateDiagnostics">
      <div *ngIf="bitrateDiagnostics.report; else noReport">
        Average Bitrate: {{ bitrateDiagnostics.report.averageBitrate | number: '1.2'}} kbps
      </div>
      <ng-template #noReport>
        {{ bitrateDiagnostics.bitrate | number: '1.2' }} kbps
      </ng-template>
      <div *ngIf="bitrateDiagnostics.warning" class="warning">{{ bitrateDiagnostics.warning}}</div>
      <div *ngIf="bitrateDiagnostics.error" class="error">{{ bitrateDiagnostics.error | json}}</div>
    </ng-container>
    <cm-menu-grid
      [items]="items"
      (itemSelected)="onItemSelected($event)"
      style="margin: 50px"
      [columns]="4"
    ></cm-menu-grid>
  </div>
</cm-overlay>

