import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent implements OnInit {
  public images: Array<string> = [];
  public imagePath = '';

  constructor() {
    let count = 1;
    const fileNameTemplate = `assets/images/demo_reel/demo_loop_%d.jpg`;

    for (let i = 1; i <= 10; i++) {
      this.images.push(fileNameTemplate.replace('%d', count.toString()));
      count++;
    }

    console.log(this.images);
  }

  ngOnInit(): void {
    let currentImageIndex = 0;
    this.imagePath = this.images[currentImageIndex];
    setInterval(() => {
      this.imagePath = this.images[currentImageIndex];
      currentImageIndex++;
      if (currentImageIndex >= this.images.length) {
        currentImageIndex = 0;
      }
    }, 3000);
  }
}
