<div class="video-container">
  <div class="video-player">
    <div class="controls">
      <!-- <mat-icon svgIcon="cm:play"></mat-icon> -->
      <mat-icon
        svgIcon="cm:pause"
        [ngStyle]="{ color: 'blue', width: '60px', height: '60px' }"
      ></mat-icon>
      <mat-icon
        svgIcon="cm:stop"
        [ngStyle]="{ color: 'yellow', width: '60px', height: '60px' }"
      ></mat-icon>
    </div>
  </div>
</div>
