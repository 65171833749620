import { Router } from '@angular/router';
import { ConfigurationService } from '../../core/services/configuration.service';
import { ConferencingService } from '../../core/services/conferencing.service';
import { SupervisorService } from '../../core/services/supervisor.service';
import { Component, OnInit } from '@angular/core';
import { MessagesState } from 'src/app/state/messages.reducer';
import { Store } from '@ngrx/store';
import { MessageRecipient } from 'src/app/core/models/message-recipient.model';
import { Observable } from 'rxjs';
import { selectUnreadMessageRecipients } from 'src/app/state/messages.selectors';
import { CallOptions } from 'src/app/state/conferencing.reducer';

@Component({
  selector: 'app-listener',
  templateUrl: './listener.component.html',
  styleUrls: ['./listener.component.scss'],
})
export class ListenerComponent implements OnInit {
  choices: Array<any> = [];
  unreadMessageRecipients$: Observable<MessageRecipient[]>;

  constructor(
    public supervisor: SupervisorService,
    public configuration: ConfigurationService,
    private conferencing: ConferencingService,
    private router: Router,
    private store$: Store<MessagesState>
  ) {}

  ngOnInit(): void {
    this.unreadMessageRecipients$ = this.store$.select(
      selectUnreadMessageRecipients
    );

    this.choices = [
      { id: 1, label: 'Call supporter', icon: 'home' },
      { id: 2, label: 'Decline', icon: 'home' },
    ];
  }

  onChoiceSelected(choice): void {
    console.info(`choice selected: ${JSON.stringify(choice)}`);
    if (choice.id === 1) {
      const callOptions: CallOptions = {};
      const association = this.configuration.associations.find(
        (a) => a.supporter.username === 'supporter'
      );
      callOptions.target = association.supporter;
      this.conferencing.callRequestedCallOptions = callOptions;
      this.router.navigate([
        'conferencing/call-viewer',
        { callType: 'outgoing' },
      ]);
    }
  }
}
