import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { MessageRecipient } from 'src/app/core/models/message-recipient.model';
import { Message } from 'src/app/core/models/message.model';
import { MessagesState } from 'src/app/state/messages.reducer';
import { selectMessageRecipientById } from 'src/app/state/messages.selectors';

@Component({
  selector: 'app-image-viewer-container',
  templateUrl: './image-viewer-container.component.html',
  styleUrls: ['./image-viewer-container.component.scss'],
})
export class ImageViewerContainerComponent implements OnInit {
  messageRecipientId: number;
  messageId: number;
  messageRecipient$: Observable<MessageRecipient>;
  messageRecipientSubscription: Subscription;
  message: Message;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store$: Store<MessagesState>
  ) {}

  ngOnInit() {
    this.route.params.forEach((params: Params) => {
      this.messageRecipientId = +params['messageRecipientId'];
      this.messageId = +params['messageId'];
    });

    this.messageRecipient$ = this.store$.select(
      selectMessageRecipientById(this.messageRecipientId)
    );

    this.messageRecipientSubscription = this.messageRecipient$.subscribe(
      (mr: MessageRecipient) => {
        this.message = mr.message_set.messages.find(
          (message: Message) => message.id === this.messageId
        );
      }
    );
  }

  ngOnDestroy() {
    this.messageRecipientSubscription.unsubscribe();
  }

  onBackPressed($event) {
    console.log('back pressed!');
    this.router.navigate([
      '/viewers/default/',
      this.messageRecipientId,
      this.messageId,
    ]);
  }
}
