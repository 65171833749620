import { AssistanceRequestStatus } from './assistance-request-status.model';
import { Resident } from './resident.model';
import { AssistanceProvider } from './assistance-provider.model';
import { Model } from './base.model';


export class AssistanceRequest extends Model {
    assistance_provider: AssistanceProvider | number;
    resident: Resident | number;
    statuses?: AssistanceRequestStatus[];
}
