import { Injectable } from '@angular/core';

import { PusherService } from './pusher.service';

import { Channel } from 'pusher-js';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { ConfigurationService } from './configuration.service';
import { LoggerService } from './logger.service';

export class CallOptions {
  initiator?: any;
  target?: any;
  audioOnly?: boolean;
  rtcIceCandidate?: RTCIceCandidate;
  rtcOffer?: any;
  sessionId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConferencingMultipleService {
  channelName: string;
  channel: Channel;

  callRequestedCallOptions: CallOptions; // Call options received via inbound call request

  private callRequestedSource = new Subject();
  private callAcceptedSource = new Subject();
  private callDeclinedSource = new Subject();
  private callEndedSource = new Subject();

  private rtcIceCandidateAudioSource = new Subject();
  private rtcOfferAudioSource = new Subject();
  private rtcAnswerAudioSource = new Subject();

  private rtcIceCandidateVideoSource = new Subject();
  private rtcOfferVideoSource = new Subject();
  private rtcAnswerVideoSource = new Subject();

  callRequested$ = this.callRequestedSource.asObservable();
  callAccepted$ = this.callAcceptedSource.asObservable();
  callDeclined$ = this.callDeclinedSource.asObservable();
  callEnded$ = this.callEndedSource.asObservable();

  rtcIceCandidateAudio$ = this.rtcIceCandidateAudioSource.asObservable();
  rtcOfferAudio$ = this.rtcOfferAudioSource.asObservable();
  rtcAnswerAudio$ = this.rtcAnswerAudioSource.asObservable();

  rtcIceCandidateVideo$ = this.rtcIceCandidateVideoSource.asObservable();
  rtcOfferVideo$ = this.rtcOfferVideoSource.asObservable();
  rtcAnswerVideo$ = this.rtcAnswerVideoSource.asObservable();

  constructor(
    private pusher: PusherService,
    private auth: AuthService,
    private configuration: ConfigurationService,
    private logger: LoggerService
  ) {}

  subscribe() {
    this.channelName = `private-call-${this.configuration.resident.customer.id}`;
    this.channel = this.pusher.subscribe(this.channelName);

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_succeeded',
      () => {
        this.logger.info(
          `Call channel subscription succeeded: ${this.channelName} (${this.configuration.resident.customer.name})`
        );
      }
    );

    this.pusher.bindChannelEvent(
      this.channel,
      'pusher:subscription_error',
      (status) => {
        this.logger.error(
          'Customer call channel subscription FAILED: ' + this.channel.name
        );
        this.logger.error(status);
      }
    );

    this.logger.info(
      `Binding to call events with ${this.configuration.resident.username} and id ${this.configuration.resident.id}`
    );

    // Call requested incoming event
    let callRequestedEventName = `client-conferencing-call-requested-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${callRequestedEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      callRequestedEventName,
      (callOptions: CallOptions) => {
        this.callRequestedSource.next(callOptions);
      }
    );

    // Call accepted incoming event
    let callAcceptedEventName = `client-conferencing-call-accepted-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${callAcceptedEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      callAcceptedEventName,
      (callOptions: CallOptions) => {
        this.logger.info('called ACCEPTED fired!');
        this.callAcceptedSource.next(callOptions);
      }
    );

    // Call declined incoming event
    let callDeclinedEventName = `client-conferencing-call-declined-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${callDeclinedEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      callDeclinedEventName,
      (callOptions: CallOptions) => this.callDeclinedSource.next(callOptions)
    );

    // Call ended incoming event
    let callEndedEventName = `client-conferencing-call-ended-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${callEndedEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      callEndedEventName,
      (callOptions: CallOptions) => {
        this.callEndedSource.next(callOptions);
      }
    );

    // RTC ICE Candidate Audio
    let iceCandidateAudioEventName = `client-conferencing-ice-candidate-audio-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${iceCandidateAudioEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      iceCandidateAudioEventName,
      (iceCandidate: RTCIceCandidate) => {
        this.logger.info('subject - RTC ICE Candidate Audio fired');
        this.rtcIceCandidateAudioSource.next(iceCandidate);
      }
    );

    // RTC ICE Candidate Video
    let iceCandidateVideoEventName = `client-conferencing-ice-candidate-video-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${iceCandidateVideoEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      iceCandidateVideoEventName,
      (iceCandidate: RTCIceCandidate) => {
        this.logger.info('subject - RTC ICE Candidate Video fired');
        this.rtcIceCandidateVideoSource.next(iceCandidate);
      }
    );

    // RTC Offer Audio
    let rtcOfferAudioEventName = `client-conferencing-rtc-offer-audio-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${rtcOfferAudioEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      rtcOfferAudioEventName,
      (rtcOffer: any) => {
        this.rtcOfferAudioSource.next(rtcOffer);
        this.logger.info('subject - RTC offer audio fired');
      }
    );

    // RTC Offer Video
    let rtcOfferVideoEventName = `client-conferencing-rtc-offer-video-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${rtcOfferVideoEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      rtcOfferVideoEventName,
      (rtcOffer: any) => {
        this.rtcOfferVideoSource.next(rtcOffer);
        this.logger.info('subject - RTC offer video fired');
      }
    );

    // RTC Answer Audio
    let rtcAnswerAudioEventName = `client-conferencing-rtc-answer-audio-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${rtcAnswerAudioEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      rtcAnswerAudioEventName,
      (rtcAnswer: any) => {
        this.logger.info('subject - RTC answer audio fired');
        this.rtcAnswerAudioSource.next(rtcAnswer);
      }
    );

    // RTC Answer Video
    let rtcAnswerVideoEventName = `client-conferencing-rtc-answer-video-${this.configuration.resident.id}`;
    this.logger.info(`Listening on ${rtcAnswerVideoEventName}`);
    this.pusher.bindChannelEvent(
      this.channel,
      rtcAnswerVideoEventName,
      (rtcAnswer: any) => {
        this.logger.info('subject - RTC answer video fired');
        this.rtcAnswerVideoSource.next(rtcAnswer);
      }
    );
  }

  unsubscribe() {
    this.pusher.unsubscribe(this.channelName);
  }

  requestCall(callOptions: CallOptions): boolean {
    this.logger.info(`Requesting call with ${callOptions.target.username}`);
    let eventName = `client-conferencing-call-requested-${callOptions.target.id}`;
    this.logger.info(`eventName: ${eventName}`);
    return this.channel.trigger(eventName, callOptions);
  }

  acceptCall(callOptions: CallOptions): boolean {
    this.logger.info(`Accepting call from ${callOptions.initiator.username}`);
    let eventName = `client-conferencing-call-accepted-${callOptions.initiator.id}`;
    this.logger.info(`eventName: ${eventName}`);
    return this.channel.trigger(eventName, callOptions);
  }

  declineCall(initiator: any): boolean {
    let callOptions = {
      initiator: initiator,
    };
    return this.channel.trigger(
      'client-conferencing-call-declined-' + initiator.id,
      callOptions
    );
  }

  endCall(initiator: any): boolean {
    if (initiator) {
      let callOptions = {
        initiator: initiator,
      };
      return this.channel.trigger(
        'client-conferencing-call-ended-' + initiator.id,
        callOptions
      );
    }
  }

  rtcIceCandidateAudio(iceCandidate: RTCIceCandidate, auth: any): boolean {
    return this.channel.trigger(
      `client-conferencing-ice-candidate-audio-${auth.id}`,
      iceCandidate
    );
  }

  rtcOfferAudio(description: RTCSessionDescription, auth: any): boolean {
    return this.channel.trigger(
      `client-conferencing-rtc-offer-audio-${auth.id}`,
      description
    );
  }

  rtcAnswerAudio(rtcAnswer: any, auth: any): boolean {
    return this.channel.trigger(
      `client-conferencing-rtc-answer-audio-${auth.id}`,
      rtcAnswer
    );
  }

  rtcIceCandidateVideo(iceCandidate: RTCIceCandidate, auth: any): boolean {
    return this.channel.trigger(
      `client-conferencing-ice-candidate-video-${auth.id}`,
      iceCandidate
    );
  }

  rtcOfferVideo(description: RTCSessionDescription, auth: any): boolean {
    return this.channel.trigger(
      `client-conferencing-rtc-offer-video-${auth.id}`,
      description
    );
  }

  rtcAnswerVideo(rtcAnswer: any, auth: any): boolean {
    return this.channel.trigger(
      `client-conferencing-rtc-answer-video-${auth.id}`,
      rtcAnswer
    );
  }
}
