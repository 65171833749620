import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StbState } from './stb.reducer';

const getStbFeatureState = createFeatureSelector<StbState>('stb');

export const selectStbState = createSelector(
  getStbFeatureState,
  (state) => state
);

export const selectCpuUsage = createSelector(
  getStbFeatureState,
  (state) => state.cpuUsage
);

export const selectMemoryUsage = createSelector(
  getStbFeatureState,
  (state) => state.memoryUsage
);
