import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as StbSelectors from '../../../state/stb.selectors';
import { Observable } from 'rxjs';

@Component({
  selector: 'cm-cpu-indicator',
  templateUrl: './cpu-indicator.component.html',
  styleUrls: ['./cpu-indicator.component.scss'],
})
export class CpuIndicatorComponent implements OnInit {
  cpuUsage$: Observable<number>;
  constructor(private store$: Store) {
    this.cpuUsage$ = this.store$.select(StbSelectors.selectCpuUsage);
  }

  ngOnInit(): void {}
}
