import { Router } from '@angular/router';
import {
  Component,
  OnInit,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { ASCII_REMOTE_KEYS } from './../../../core/services/remote-control.service';
import { Observable } from 'rxjs';
import { UserState } from 'src/app/state/user.reducer';
import { Store } from '@ngrx/store';
import { selectPinCode } from 'src/app/state/user.selectors';
import * as UserActions from 'src/app/state/user.actions';

@Component({
  selector: 'cm-pincode',
  templateUrl: './pincode.component.html',
  styleUrls: ['./pincode.component.scss'],
})
export class PincodeComponent implements OnInit {
  public pinCode$: Observable<string>;

  constructor(private router: Router, private store$: Store<UserState>) {}

  ngOnInit() {
    this.store$.dispatch(UserActions.getPinCode());
    this.pinCode$ = this.store$.select(selectPinCode);
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent) {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToProfile();
    }
    return false;
  }

  goToProfile() {
    this.router.navigate(['dashboard/profile']);
  }
}
