import {
  Directive,
  Input,
  Inject,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[cmFocus]',
})
export class FocusDirective implements OnChanges {
  @Input() cmFocus: boolean;

  constructor(
    @Inject(ElementRef) private element: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.element.nativeElement.focus();
    }
  }
}
