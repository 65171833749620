<div id="container">
  <div class="wrapper">
    <span class="label">{{ "ABOUT_MODEL_NAME" | translate }}</span>
    <span class="value">{{ modelName }}</span
    >
  </div>

  <div class="wrapper">
    <span class="label">{{ "ABOUT_SERIAL_NUMBER" | translate }}</span>
    <span class="value">{{ serialNumber }}</span>
  </div>

  <div class="wrapper">
    <span class="label">{{ "ABOUT_PLATFORM_VERSION" | translate }}</span>
    <span class="value">{{ platformVersion }}</span>
  </div>

  <div class="wrapper">
    <span class="label">{{ "ABOUT_MAC_ADDRESS" | translate }}</span>
    <span class="value">{{ macAddress }}</span>
  </div>

  <div class="wrapper">
    <span class="label">{{ "ABOUT_IP_ADDRESS" | translate }}</span>
    <span class="value">{{ ipAddress }}</span>
  </div>

  <div class="wrapper">
    <span class="label">{{ "ABOUT_PROCENTRIC_SERVER" | translate }}</span>
    <span class="value">{{ procentricServer }}</span>
  </div>
</div>

