<cm-overlay
  [width]="1150"
  [height]="640"
  header="{{ 'PROFILE_THEMES_TITLE' | translate }}"
>
  <h1>{{ "PROFILE_THEMES_MESSAGE" | translate }}</h1>
  <cm-choice-selector
    [choices]="choices"
    [currentChoice]="currentChoice"
    [columns]="1"
    (choiceSelected)="onChoiceSelected($event)"
  ></cm-choice-selector>
</cm-overlay>
