import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ASCII_REMOTE_KEYS } from '../../core/services/remote-control.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MenuItem } from '../../core/models/menu-item.model';

@Component({
  selector: 'cm-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss'],
})
export class DiagnosticsComponent implements OnInit {
  translateStrings: any;
  diagnosticItems: MenuItem[];

  constructor(
    private translate: TranslateService,
    private store$: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    const tokens = [
      'DIAGNOSTICS_TITLE',
      'DIAGNOSTICS_CAMERA_TEST',
      'DIAGNOSTICS_BITRATE_TEST',
      'DIAGNOSTICS_AUDIO_INPUT_TEST',
      'DIAGNOSTICS_AUDIO_OUTPUT_TEST',
      'DIAGNOSTICS_EXIT',
    ];
    this.translate
      .get(tokens)
      .subscribe((result) => (this.translateStrings = result));

    this.diagnosticItems = [
      {
        id: 1,
        tag: 'camera_test',
        icon: 'cm:video-camera',
        label: this.translateStrings.DIAGNOSTICS_CAMERA_TEST,
      },
      {
        id: 2,
        tag: 'bitrate_test',
        icon: 'cm:network-antenna',
        label: this.translateStrings.DIAGNOSTICS_BITRATE_TEST,
      },
      {
        id: 3,
        tag: 'audio_input_test',
        icon: 'cm:mic-recording',
        label: this.translateStrings.DIAGNOSTICS_AUDIO_INPUT_TEST,
      },
      {
        id: 4,
        tag: 'audio_output_test',
        icon: 'cm:volume-loud',
        label: this.translateStrings.DIAGNOSTICS_AUDIO_OUTPUT_TEST,
      },
      {
        id: 5,
        tag: 'exit',
        icon: 'cm:cross-circle',
        label: this.translateStrings.DIAGNOSTICS_EXIT,
      },
    ];
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown($event: KeyboardEvent): void {
    if ($event.keyCode === ASCII_REMOTE_KEYS.back) {
      this.goToUtilities();
    }
  }

  onItemSelected(item: MenuItem): void {
    switch (item.tag) {
      case 'camera_test':
        this.router.navigate(['dashboard/diagnostics/camera-test']);
        break;
      case 'bitrate_test':
        this.router.navigate(['dashboard/diagnostics/bitrate-test']);
        break;
      case 'exit':
        this.goToUtilities();
        break;
    }
  }

  goToUtilities(): void {
    this.router.navigate(['dashboard/utilities']);
  }
}
